import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import { Breakpoint } from "@mui/material";

export type ModalProps = {
  content: React.ReactNode | JSX.Element;
  isOpen: boolean;
  width?: false | Breakpoint | undefined;
  closeModal: () => void;
  navigationAwayLocked?: boolean;
};

export default function Modal({
  width,
  content,
  isOpen,
  closeModal,
  navigationAwayLocked = false,
}: ModalProps) {
  return (
    <Box>
      <Dialog
        open={isOpen}
        onClose={navigationAwayLocked ? undefined : closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={width ? width : "sm"}
        sx={{ ".MuiPaper-root": { maxHeight: "98vh", height: "auto" } }}
        data-testid="modal-dialog"
      >
        {content}
      </Dialog>
    </Box>
  );
}
