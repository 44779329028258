import { AuthSessionStorageKeys } from "../../../features/auth/navigation/config";
import { AuthenticationProviders } from "../../../constants/AuthenticationProviders";
import {
  clearAuthDataFromSessionStorage,
  isTokenExpired,
} from "../../../features/auth/utils/verifyPwCAccess";
import { AppConstant } from "../../../constants";

export const useIsUserAuthenticated = () => {
  const appAuthProvider: any = AppConstant.authentication;

  switch (appAuthProvider?.provider) {
    case AuthenticationProviders.IDAM:
      const accessToken = sessionStorage.getItem(
        AuthSessionStorageKeys.accessToken,
      );

      if (accessToken && isTokenExpired(accessToken)) {
        clearAuthDataFromSessionStorage();
        return false;
      }

      return !!accessToken;

    default:
      return false;
  }
};
