import {
  EmissionPerPillar,
  MacroResults,
  MekkoBaseGlideScenario,
} from "../../../../../orval/generated/models";
import {
  TableResultsFilterDataType,
  TableResultsType,
} from "../../ScenarioResults/types";
import { timeChartData } from "../../../types";
import { MicroAdjustmentConfigurations } from "../../../../micro-tool/components/MicroEmissionsChart/MicroAdjustmentConfigurations";
import getStackColor from "./getStackColor";
import formatLabel from "./formatLabel";

function getTimeEvolutionData(
  result: MacroResults,
  type: TableResultsFilterDataType,
  sectionType: TableResultsType.BY_CATEGORY | TableResultsType.BY_PACK_TYPE,
): timeChartData {
  if (!result) {
    return [];
  }

  const chartData: timeChartData = [];
  const stacks: (keyof MekkoBaseGlideScenario)[] = [
    "base",
    "glide",
    "scenario",
  ];
  const pillars: string[] = Object.values(MicroAdjustmentConfigurations);

  stacks.forEach((stack) => {
    pillars.forEach((pillar) => {
      chartData.push({
        label: formatLabel(pillar),
        data: Object.keys(result[sectionType]).map((category) => {
          return result[sectionType][category][type][
            pillar as keyof EmissionPerPillar
          ][stack];
        }),
        stack: stack,
        backgroundColor: getStackColor(pillar).backgroundColor,
      });
    });
  });
  return chartData;
}

export default getTimeEvolutionData;
