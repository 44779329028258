import {
  Box,
  Button,
  Drawer,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { ExportOption } from "./ExportOption";

type Props = {
  options: string[];
  open: boolean;
  translationKey: string;
  handleCsv: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
};
export function ManageExportPanel({
  selectedOptions,
  setSelectedOptions,
  options,
  handleCsv,
  translationKey,
  open,
  setOpen,
}: Props) {
  const { t } = useTranslation("micro");

  function handleSelectAll() {
    setSelectedOptions([...options]);
  }
  function onChangeSelectOption(item: string, checked: boolean): void {
    if (checked) {
      setSelectedOptions([...selectedOptions, item]);
    } else {
      setSelectedOptions(
        selectedOptions.filter((selectedItem) => selectedItem !== item),
      );
    }
  }

  const optionsList = options.map((data, index) => (
    <ExportOption
      selectedOptions={selectedOptions}
      onChangeSelectOption={onChangeSelectOption}
      optionName={data}
      key={index}
      translationKey={translationKey}
    />
  ));

  return (
    <Drawer
      sx={{
        "& .MuiDrawer-paper": {
          flexDirection: "row",
        },
      }}
      anchor={"right"}
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        sx={{
          padding: "16px 16px 16px 32px",
          width: "455px",
        }}
      >
        <Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ borderBottom: "1px solid #E1E1E1" }}
          >
            <Typography className="header-H3">
              {t("manageExport.title")}
            </Typography>

            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography sx={{ padding: "10px 0" }}>
            {t("manageExport.subtitle")}
          </Typography>
          <FormGroup>{optionsList}</FormGroup>
          <Box textAlign="end" mt={1}>
            <Button variant="text" color="primary" onClick={handleSelectAll}>
              {t("manageExport.selectAll")}
            </Button>
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end" pb={2}>
          <Button
            sx={{ marginTop: 1 }}
            onClick={handleCsv}
            disabled={!selectedOptions.length && translationKey === "macro"}
            variant="contained"
          >
            {t("manageExport.export")}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
