import { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { TextEdit } from "components/common";
import { Button, TextField } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { ViewMacroScenarioViewModel, ViewMicroScenario } from "orval/generated/models";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";

export type ScenarioHeroSectionProps = {
	onRenameScenario: (value: string) => void;
	scenario: ViewMacroScenarioViewModel | ViewMicroScenario | null;
	handleRenameDescription: Function;
	headersIndent: string;
	actionsSection: React.ReactNode
	backBtnHandler: () => void
	backBtnText: string
};

export function ScenarioHeroSection({
	onRenameScenario,
	scenario,
	handleRenameDescription,
	headersIndent,
	actionsSection,
	backBtnHandler,
	backBtnText
}: ScenarioHeroSectionProps) {
	const { t } = useTranslation("macro");
	const [editName, setEditName] = useState<boolean>(false);
	const [editDescription, setEditDescription] = useState<boolean>(false);
	const descriptionValidationSchema = yup.object({
		description: yup
			.string()
			.max(255, "Description must be at most 255 characters"),
	});

	const formikDesc = useFormik<{ description: string }>({
		initialValues: {
			description: scenario?.description ?? "",
		},
		validationSchema: descriptionValidationSchema,
		enableReinitialize: true,
		onSubmit: (values) => {
			if (scenario?.description !== values.description) {
				handleRenameDescription(values.description);
			}
			setEditDescription(false);
		},
	});

	const descriptionPlaceholder =
		formikDesc.values.description && formikDesc.values.description.length > 0
			? formikDesc.values.description
			: t("noDescription");

	const handleRenameScenario = () => {
		setEditName(true);
	};

	const handleInputSave = (value: string) => {
		if (onRenameScenario) {
			onRenameScenario(value);
			setEditName(false);
		}
	};

	return (
		<Box>
			<Button
				variant="text"
				onClick={backBtnHandler}
				startIcon={<ArrowBackIosSharpIcon />}
			>
				{backBtnText}
			</Button>
			<Stack
				direction={{ xs: "column-reverse", md: "row" }}
				justifyContent={{ md: "space-between" }}
				alignItems={{ xs: "flex-start", md: "center" }}
			>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					maxWidth={"80%"}
				>
					{!editName ? (
						<Box onClick={handleRenameScenario}>
							<Typography
								className="header-H2 text-ellipsis"
								sx={{
									padding: headersIndent,
									transition: "padding 0.3s ease-in-out",
								}}
							>
								{scenario?.name || t("defaultTitle")}
							</Typography>
						</Box>
					) : (
						<>
							<TextEdit
								value={scenario?.name || ""}
								onSaveInput={handleInputSave}
							></TextEdit>
						</>
					)}
				</Box>

				{actionsSection}
			</Stack>

			<Box width={editDescription ? "100%" : "90%"} minHeight="45px" mt={1}>
				{editDescription ? (
					<TextField
						sx={{ width: "100%" }}
						placeholder={t("noDescription")}
						variant="outlined"
						value={formikDesc.values.description}
						multiline
						hiddenLabel
						name="description"
						onChange={formikDesc.handleChange}
						onBlur={() => formikDesc.handleSubmit()}
						autoFocus
						focused
						onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
							if (e.key === "Enter") {
								e.preventDefault();
								formikDesc.handleSubmit();
							}
						}}
						data-testid="text-edit-scenario-description"
						error={
							formikDesc.touched.description &&
							Boolean(formikDesc.errors.description)
						}
						helperText={
							formikDesc.touched.description &&
							(formikDesc.errors.description as string)
						}
					/>
				) : (
					<Typography
						sx={{
							cursor: "pointer",
							padding: headersIndent,
							transition: "padding 0.3s ease-in-out",
						}}
						overflow="hidden"
						className="description-hover"
						onClick={() => setEditDescription(true)}
					>
						{descriptionPlaceholder}
					</Typography>
				)}
			</Box>
		</Box>
	);
}
