import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MicroSingleChartSection from "./MicroSingleChartSection/MicroSingleChartSection";
import { MicroAdjustmentConfigurations } from "./MicroAdjustmentConfigurations";
import "chart.js/auto";
import {
  capitalizeFirstLetter,
  capitalizeText,
  deepClone,
} from "../../../../utils";
import { revertTextFormat } from "../../../../utils/formatText";
import {
  EmissionDataPerPillar,
  Results,
  EmissionData,
  ViewMicroScenario,
  Product,
} from "../../../../orval/generated/models";
import { orderWithCDE, orderWithoutCDE } from "./ChartOrder";
import { ChartValue, DataSet, DisplayOption } from "../types";

type Props = {
  chartsData: ViewMicroScenario;
  hideCDE: boolean;
  unit: string;
  displayOption: DisplayOption;
};

export default function MicroEmissionsChart({
  chartsData,
  hideCDE,
  unit,
  displayOption,
}: Props) {
  const [adjustedValues, setAdjustedValues] = useState<ChartValue>({
    labels: [],
    datasets: [],
  });
  const [unAdjustedValues, setUnAdjustedValues] = useState<ChartValue>({
    labels: [],
    datasets: [],
  });
  const selectedProduct: Product | null = chartsData.products[0] || null;
  const { t } = useTranslation("micro");

  const sortChartData = (chartData: ChartValue, order: string[]) => {
    const originalLabels: any[] = [...chartData!.labels];
    const originalDatasets = chartData!.datasets.map((dataset: any) => ({
      ...dataset,
      data: [...dataset.data],
    }));

    chartData!.labels.sort(
      (a: string, b: string) => order.indexOf(a) - order.indexOf(b),
    );

    chartData!.datasets.forEach((dataset: any, i: number) => {
      dataset.data = chartData!.labels.map((label: string) => {
        const originalIndex: number = originalLabels.indexOf(label);
        return originalDatasets[i].data[originalIndex];
      });
    });

    return chartData;
  };

  const getBarData = useCallback(
    (product: Product, type: string, hideCDE: boolean) => {
      let barUnit = revertTextFormat(unit);
      let formattedChartData;
      const baseDataResults = deepClone(product?.results?.base || []);
      const userDataResults = deepClone(product?.results?.adjusted || {});

      if (hideCDE) {
        delete baseDataResults[barUnit]["cde"];
        if (Object.keys(userDataResults).length > 0) {
          delete userDataResults[barUnit]["cde"];
        }
      }
      let nonAdjustedData = Object.values(baseDataResults[barUnit]).map(
        (value: any) => Number(value),
      );

      let formattedNonAdjustedData = {
        label: "Original",
        data: nonAdjustedData as number[],
        backgroundColor: getStackColor(type),
        barPercentage: 0.6,
      } as DataSet;

      let adjustedData = nonAdjustedData;

      if (Object.keys(userDataResults).length > 0) {
        adjustedData = Object.values(userDataResults[barUnit]).map(
          (value: any) => Number(value),
        );
      }

      let formattedAdjustedData = {
        label: "Adjusted",
        data: adjustedData as number[],
        backgroundColor: getStackColor(type),
        barPercentage: 0.6,
      } as DataSet;

      formattedChartData = {
        labels: Object.keys(baseDataResults[barUnit]).map(function (label) {
          if (label === "cde") return capitalizeText(label);
          else return capitalizeFirstLetter(label);
        }),
        datasets: [formattedAdjustedData, formattedNonAdjustedData],
      };

      formattedChartData = sortChartData(
        formattedChartData,
        hideCDE ? orderWithoutCDE : orderWithCDE,
      );
      return formattedChartData;

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [unit],
  );

  const setStackedBarData = useCallback(
    (chartsData: ViewMicroScenario, unit: string, hideCDE: boolean) => {
      setUnAdjustedValues(
        formatWithoutAdjustments(chartsData, "stacked", hideCDE, unit),
      );
      setAdjustedValues(
        formatWithAdjustments(chartsData, "stacked", hideCDE, unit),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUnAdjustedValues, setAdjustedValues],
  );

  useEffect(() => {
    if (chartsData) setStackedBarData(chartsData, unit, hideCDE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit, chartsData]);

  useEffect(() => {
    setStackedBarData(chartsData, unit, hideCDE);
  }, [hideCDE, setStackedBarData, chartsData, unit]);

  const sortArray = (arr: DataSet[]) => {
    return arr.sort((a, b) => {
      const keyA = a.label;
      const keyB = b.label;

      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  };

  const getStackColor = (type: string, key?: string) => {
    let stackColor;

    if (type === "stacked") {
      switch (key) {
        case MicroAdjustmentConfigurations.LOGISTICS:
          stackColor = "#FDAB8D";
          break;
        case MicroAdjustmentConfigurations.PACKAGING:
          stackColor = "#A7452C";
          break;
        case MicroAdjustmentConfigurations.INGREDIENTS:
          stackColor = "#773829";
          break;
        case MicroAdjustmentConfigurations.MANUFACTURING:
          stackColor = "#E45C2B";
          break;

        default:
          stackColor = "#FEDACC";
          break;
      }
    } else {
      stackColor = "#D04A02";
    }

    return stackColor;
  };

  const getStackedData = (
    data: ViewMicroScenario,
    type: string,
    adjusted: boolean,
    unit: string,
    hideCDE: boolean,
  ) => {
    let formattedChartData;
    let dataSets: DataSet[] = [];

    const productsList: string[] = [];
    if (data.products) {
      data.products.forEach((product) => {
        const productLabel =
          product.custom_display_name ?? product.product_name;
        productsList.push(productLabel);
      });
    }

    dataSets = data.products.reduce<DataSet[]>((accu: DataSet[], product) => {
      const productResults: EmissionData =
        adjusted && product?.results
          ? deepClone(product?.results.adjusted)[
              revertTextFormat(unit) as keyof Results
            ]
          : deepClone(product?.results?.base)[
              revertTextFormat(unit) as keyof Results
            ];

      if (hideCDE) {
        delete productResults["cde" as keyof EmissionData];
      }

      const stack = adjusted ? "Adjusted" : "Original";

      return Object.entries(productResults)
        ?.filter(([resultKey]) => resultKey !== "total")
        ?.map(([resultKey, resultValue]: [string, EmissionDataPerPillar]) => {
          const newObj = accu.find(
            (item) => item.label === `${stack} : ${resultKey}`,
          );

          if (newObj) {
            newObj.data.push(Number(resultValue["total"]));
          } else {
            return {
              label: `${stack} : ${resultKey}`,
              data: [Number(resultValue["total"])],
              backgroundColor: getStackColor(type, resultKey),
              barPercentage: 0.6,
              stack: stack,
            };
          }
          return newObj;
        });
    }, []);

    const formattedDataSets = sortArray(dataSets);

    formattedChartData = {
      labels: productsList,
      datasets: formattedDataSets,
    };

    const order = hideCDE ? orderWithoutCDE : orderWithCDE;

    formattedChartData.datasets = formattedChartData.datasets.sort((a, b) => {
      return (
        order.findIndex((x) =>
          a.label.toLowerCase().includes(x.toLowerCase()),
        ) -
        order.findIndex((x) => b.label.toLowerCase().includes(x.toLowerCase()))
      );
    });

    return formattedChartData;
  };

  const formatWithoutAdjustments = (
    data: ViewMicroScenario,
    type: string,
    hideCDE: boolean,
    unit?: string,
  ) => {
    let formattedChartsData;

    if (type === "stacked") {
      formattedChartsData = getStackedData(
        data,
        type,
        false,
        unit || "",
        hideCDE,
      );
    } else {
      if (selectedProduct) {
        formattedChartsData = getBarData(selectedProduct, "bar", hideCDE);
      }
    }

    return formattedChartsData;
  };

  const formatWithAdjustments = (
    data: ViewMicroScenario,
    type: string,
    hideCDE: boolean,
    unit?: string,
  ) => {
    return getStackedData(data, type, true, unit || "", hideCDE);
  };

  return (
    <MicroSingleChartSection
      label={t("resultsSection.labels.chart1Title")}
      stackedUnit={unit}
      chartType="stacked"
      nonAdjustedChartValues={unAdjustedValues}
      adjustedChartValues={adjustedValues}
      displayOption={displayOption}
    />
  );
}
