import { Box, Tooltip } from "@mui/material";
import { CustomIcon } from "../../../common";
import { SideBarOption } from "../types";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import CustomSidebarLink from "../CustomSidebarLink/CustomSidebarLink";
import { usePermissions } from "../../../../core/navigation/usePermissions";

export type SidebarOptionProps = {
  option: SideBarOption;
};

const SidebarOption = forwardRef<HTMLDivElement, SidebarOptionProps>(
  ({ option }, ref) => {
    const { t } = useTranslation();
    const { useHasPermission } = usePermissions();

    const hasPermission = useHasPermission(option.permission);

    return (
      <>
        {hasPermission || !option.permission ? (
          <Tooltip
            key={option.title}
            data-testid={`${option.title.replace("sidePanel.options.", "")}-sidebar-item`}
            title={t(option.title)}
            placement="right"
          >
            <Box key={option.title}>
              <CustomSidebarLink to={option.redirectURL}>
                <Box
                  key={option.title}
                  sx={{ display: "flex", alignItems: "center" }}
                  ref={ref}
                >
                  <CustomIcon
                    name={option.icon}
                    width={"32px"}
                    height={"28px"}
                  />
                </Box>
              </CustomSidebarLink>
            </Box>
          </Tooltip>
        ) : null}
      </>
    );
  },
);

export default SidebarOption;
