import ListItemText from "@mui/material/ListItemText";
import { Checkbox, MenuItem } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export type SelectAllRowProps<T extends string | number> = {
  addAllItems: () => void;
  allItemsSelected: boolean;
};

const SelectAllRow = <T extends string | number>({
  addAllItems,
  allItemsSelected,
}: SelectAllRowProps<T>) => {
  const { t } = useTranslation("common");
  return (
    <MenuItem
      key={t("actions.selectAll")}
      value={t("actions.selectAll")}
      sx={{
        height: "2.5em",
        maxWidth: "100%",
        overflow: "hidden",
      }}
      onClick={() => {
        addAllItems();
      }}
    >
      <ListItemText primary={t("actions.selectAll")} />
      <Checkbox
        checked={allItemsSelected}
        onClick={() => {
          addAllItems();
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            addAllItems();
          }
        }}
      />
    </MenuItem>
  );
};

export default SelectAllRow;
