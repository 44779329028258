import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { TableWrapper } from "../../../../../components/common";
import { Box, MenuItem, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProductColumnData } from "../../../types";
import { ViewMicroScenario } from "../../../../../orval/generated/models";
import { COLUMN_WIDTH, TITLE_COLUMN_WIDTH } from "../../../constants";
import { ProductTableData } from "../MicroProductsTable/types";
import getProductsTableData from "../MicroProductsTable/getProductsTableData";
import getTableColumns from "../../HelperFunctions/getTableColumns";

export type MicroProductsHeaderTableProps = {
  openCopyToAllChangesModal: Function;
  handleDuplicateProduct: Function;
  openEditProductModal: Function;
  openConfirmDeleteProductModal: (productGuid: string) => void;
  scenario: ViewMicroScenario | undefined;
  products: ProductColumnData[];
};

export const MicroProductsHeaderTable = ({
  scenario,
  openCopyToAllChangesModal,
  handleDuplicateProduct,
  openEditProductModal,
  openConfirmDeleteProductModal,
  products,
}: MicroProductsHeaderTableProps) => {
  const { t } = useTranslation("micro");
  const [data, setData] = useState<ProductTableData>([]);
  const [columns, setColumns] = useState<MRT_ColumnDef<any>[]>([]);

  useEffect(() => {
    if (products?.length && scenario && scenario.products) {
      setData(getProductsTableData(products, scenario.products));
      setColumns(
        getTableColumns(products, undefined, TITLE_COLUMN_WIDTH, true),
      );
    } else {
      setData([]);
      setColumns([]);
    }
  }, [products, scenario]);

  // Memoize columns to ensure updates
  const memoizedColumns = useMemo(() => {
    return columns.map((col) => {
      const newCol = { ...col };
      if (newCol.id === "metric") {
        newCol.header = "";
        newCol.enableColumnActions = false;
      } else {
        newCol.Header = () => {
          if (scenario?.products) {
            return (
              <Box
                display="flex"
                flexDirection="column"
                lineHeight={"1.5em"}
                height={"3em"}
                justifyContent={"center"}
                marginBottom={"5px"}
                px={1}
              >
                <Tooltip title={newCol.header} placement="top">
                  <Typography
                    fontWeight={700}
                    className={"two-line-text-ellipsis"}
                  >
                    {newCol.header}
                  </Typography>
                </Tooltip>
              </Box>
            );
          }
          return null;
        };
      }
      return newCol;
    });
  }, [columns, scenario?.products]);

  // Create a unique key for the MaterialReactTable to force re-render
  const tableKey = useMemo(
    () => `products-header-table-${data.length}-${columns.length}`,
    [data, columns],
  );

  const table = useMaterialReactTable({
    layoutMode: "grid",
    columns: memoizedColumns,
    data,
    enableTopToolbar: false,
    enableSorting: false,
    enableColumnActions: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onColumnOrderChange: () => {},
    defaultColumn: {
      size: COLUMN_WIDTH,
    },
    renderColumnActionsMenuItems: ({ closeMenu, column }) => {
      const columnMetaData = column.columnDef.meta as any;
      const guid = columnMetaData.guid;
      return [
        <MenuItem
          key="copy-to-all-product-menu-item"
          onClick={() => {
            guid && openCopyToAllChangesModal(guid);
            closeMenu();
          }}
          disabled={!columnMetaData.hasAdjustments}
          data-testid="copy-to-all-product-menu-item"
        >
          {t("common:actions.copyAll")}
        </MenuItem>,
        <MenuItem
          key="duplicate-product-menu-item"
          onClick={() => {
            guid && handleDuplicateProduct(guid);
            closeMenu();
          }}
          data-testid="duplicate-product-menu-item"
        >
          {t("common:actions.duplicate")}
        </MenuItem>,
        <MenuItem
          key="edit-menu-item"
          onClick={() => {
            guid && openEditProductModal(guid);
            closeMenu();
          }}
          data-testid="edit-menu-item"
        >
          {t("common:actions.rename")}
        </MenuItem>,
        <MenuItem
          key="delete-menu-item"
          onClick={() => {
            guid && openConfirmDeleteProductModal(guid);
            closeMenu();
          }}
          data-testid="delete-menu-item"
        >
          {t("common:actions.remove")}
        </MenuItem>,
      ];
    },
    muiTableHeadCellProps: () => {
      return {
        sx: {
          borderBottom: "2px solid #d04a02",
          zIndex: 1,
          padding: "0",
          paddingBottom: "5px",
          paddingLeft: "5px",
          "& .Mui-TableHeadCell-Content": {
            alignItems: "start",
          },
          "& .Mui-TableHeadCell-Content-Labels": {
            margin: "0 auto",
          },
        },
      };
    },
    muiTableBodyCellProps: () => ({
      sx: {
        display: "none",
      },
    }),
  });

  return (
    <>
      {data.length && columns.length ? (
        <TableWrapper>
          <MaterialReactTable key={tableKey} table={table} />
        </TableWrapper>
      ) : null}
    </>
  );
};
