import { useTranslation } from "react-i18next";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import laptop_alert from "../../../assets/images/laptop_alert.png";
import desk_work from "../../../assets/images/desk_work.png";
import "./ToolConfigurationPage.scss";
import { useNavigate } from "react-router-dom";
import { ToolConfigurationRoutesConfig } from "../navigation/config";
import { usePermissions } from "../../../core/navigation/usePermissions";

export function ToolConfigurationPage() {
  const { t } = useTranslation("toolConfiguration");
  const { hasUserManagementPermission, hasAlertsPermission } = usePermissions();
  const navigate = useNavigate();

  const handleCardClick = (route: string) => {
    navigate(route);
  };

  return (
    <>
      <Box>
        <Box>
          <Typography className={"header-H2"} fontWeight="500">
            {t("toolConfigurationPage.title")}
          </Typography>
        </Box>
        <Box my={4}>
          <Typography variant="body1">
            {t("toolConfigurationPage.description")}
          </Typography>
        </Box>
      </Box>
      <Box display={"flex"} gap={5}>
        {hasAlertsPermission && (
          <Card sx={{ maxWidth: "388px", border: "1px solid #cbcbcb" }}>
            <CardActionArea
              onClick={() =>
                handleCardClick(
                  ToolConfigurationRoutesConfig.alertConfigurationPage,
                )
              }
            >
              <CardMedia
                component="img"
                height="300"
                image={laptop_alert}
                alt={t("toolConfigurationPage.systemAlertCard.imageAlt")}
                className="laptop-alert-image"
              />
              <CardContent
                sx={{
                  maxHeight: "20%",
                  borderTop: "1px solid #cbcbcb",
                }}
              >
                <Typography className={"header-H3"} fontWeight="500" mb={1}>
                  {t("toolConfigurationPage.systemAlertCard.title")}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t("toolConfigurationPage.systemAlertCard.description")}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        )}
        {hasUserManagementPermission && (
          <Card sx={{ maxWidth: "388px", border: "1px solid #cbcbcb" }}>
            <CardActionArea
              onClick={() =>
                handleCardClick(
                  ToolConfigurationRoutesConfig.userManagementPage,
                )
              }
            >
              <CardMedia
                component="img"
                height="300"
                image={desk_work}
                alt={t("toolConfigurationPage.userManagementCard.imageAlt")}
                className="laptop-alert-image"
              />
              <CardContent
                sx={{
                  maxHeight: "20%",
                  borderTop: "1px solid #cbcbcb",
                }}
              >
                <Typography className={"header-H3"} fontWeight="500" mb={1}>
                  {t("toolConfigurationPage.userManagementCard.title")}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t("toolConfigurationPage.userManagementCard.description")}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        )}
      </Box>
    </>
  );
}
