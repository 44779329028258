import {
  Box,
  Button,
  Chip,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  downloadFileBlobStorageVersionHistoryDownloadDatasetIdGet,
  useGetVersionHistoryVersionHistoryGet,
} from "../../../../orval/generated/endpoint";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useSnackbar } from "../../../../components/common/Notification/showSnackbar";
import { VersionHistoryViewModel } from "../../../../orval/generated/models";
import getFormattedDateForDownloads from "../../../../utils/getFormattedDateForDownloads";
import { useGlobalLoader } from "components/common/GlobalLoader/GlobalLoader";
import { CustomIcon } from "components/common/CustomIcon/CustomIcon";
import { Icons } from "components/common/CustomIcon/types";
import downloadBlob from "../../../../utils/downloadBlob/downloadBlob";

function VersionHistory() {
  const { t } = useTranslation("productConfiguration");
  const { palette } = useTheme();
  const { showGlobalLoader } = useGlobalLoader();
  const showSnackbar = useSnackbar();

  const { data: versionHistoryData, isLoading: isVersionHistoryDataLoading } =
    useGetVersionHistoryVersionHistoryGet();

  const rowRefs = useRef<HTMLDivElement[]>([]);
  const chipRefs = useRef<HTMLDivElement[]>([]);
  const [rowHeights, setRowHeights] = useState<number[]>([]);
  const [chipBoxRequiredWidth, setChipBoxRequiredWidth] = useState<number>(0);

  const updateRowHeights = () => {
    setRowHeights(
      // calculate the heights of each row in the version history
      // this will be used to accurately draw the lines between the respective chips
      // the height between chips is equal to the height of the div including the margin minus the chip height
      rowRefs.current.map((ref, index) => {
        const style = window.getComputedStyle(ref);

        const topMarginHeight = style.getPropertyValue("margin-top");
        const divHeight: number | undefined = ref?.clientHeight;
        const chipHeight = chipRefs.current[index]?.clientHeight;

        const rowHeightDiff: number =
          divHeight + parseInt(topMarginHeight, 10) - chipHeight;

        return rowHeightDiff ?? 0;
      }),
    );
  };

  const handleDownload = async (version: VersionHistoryViewModel) => {
    try {
      const response: any =
        await downloadFileBlobStorageVersionHistoryDownloadDatasetIdGet(
          version.id,
          {
            responseType: "blob",
          },
        );

      const fileName = `PEF-${version.version}-${getFormattedDateForDownloads()}.xlsx`;

      downloadBlob(response as unknown as BlobPart, fileName);

      showSnackbar(
        t("dataManagementPage.versionHistory.downloadSuccess"),
        "success",
      );
    } catch (error) {
      showSnackbar(
        t("dataManagementPage.versionHistory.downloadError"),
        "error",
      );
    }
  };

  // recalculate the row heights when window resizes
  useEffect(() => {
    window.addEventListener("resize", updateRowHeights);

    return () => {
      window.removeEventListener("resize", updateRowHeights);
    };
  }, []);

  useEffect(() => {
    updateRowHeights();
  }, [versionHistoryData]);

  useEffect(() => {
    showGlobalLoader(isVersionHistoryDataLoading);
  }, [isVersionHistoryDataLoading]);

  useEffect(() => {
    if (chipRefs.current.length > 0) {
      const maxWidth = Math.max(
        ...chipRefs.current.map((chip) => chip?.clientWidth || 0),
      );
      setChipBoxRequiredWidth(maxWidth);
    }
  }, [versionHistoryData]);

  return (
    <Box pb={8}>
      <Typography mt={4} className="header-H3">
        {t("dataManagementPage.tabLabels.versionHistory")}
      </Typography>
      {!isVersionHistoryDataLoading &&
        versionHistoryData?.map((version, index) => {
          let chipColour: string;
          if (index === 0) {
            chipColour = "#d04a02";
          } else if (version.version.endsWith(".0")) {
            chipColour = "";
          } else {
            chipColour = "transparent";
          }

          return (
            <Box
              mt={4}
              key={version.id}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              ref={(el: HTMLDivElement) => (rowRefs.current[index] = el)}
              data-testid={`version-history-row`}
            >
              <Box
                alignSelf="flex-start"
                position="relative"
                display="flex"
                justifyContent="center"
                minWidth={chipBoxRequiredWidth}
              >
                {index !== 0 && (
                  // Creates a line above each chip component that is not the first index
                  // that uses the height difference between the two chips
                  <Box
                    sx={{
                      position: "absolute",
                      top: `-${rowHeights[index - 1] || 0}px`,
                      left: "50%",
                      width: "1px",
                      height: `${rowHeights[index - 1] || 0}px`,
                      backgroundColor: palette.textColor.light,
                    }}
                  />
                )}
                <Chip
                  label={version.version}
                  sx={{
                    borderRadius: "25px !important",
                    backgroundColor: `${chipColour} !important`,
                    color: index === 0 ? "white" : "",
                  }}
                  ref={(el: HTMLDivElement) => (chipRefs.current[index] = el)}
                  data-testid={`version-history-chip-${version.version.replace(/\./g, "")}`}
                />
              </Box>
              <Box mx={2} flexGrow={1}>
                <Typography mb={1} className="header-H4" fontWeight="bold">
                  {version.title}
                </Typography>
                <Typography
                  fontSize="0.8rem"
                  color={(theme) => theme.palette.textColor.dark}
                >
                  {version.uploaded_by} &bull; {version.uploaded_on}
                </Typography>
                <Typography>{version.description}</Typography>
              </Box>
              <Box>
                <Tooltip
                  title={t("dataManagementPage.versionHistory.downloadTooltip")}
                  placement="top"
                >
                  <Button
                    onClick={() => {
                      handleDownload(version);
                    }}
                    data-testid={`download-button-${version.version.replace(/\./g, "")}`}
                  >
                    <CustomIcon
                      name={Icons.DOWNLOAD_SIMPLE}
                      fill={"#d04a02"}
                      width={"2em"}
                      height={"2em"}
                    />
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          );
        })}
    </Box>
  );
}

export default VersionHistory;
