import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
} from "@mui/material";
import { CountryViewModel } from "../../../../orval/generated/models";
import SelectDropdown from "../../../../components/common/SelectDropdown/SelectDropdown";

export type DateAndCountryInputSectionProps = {
  years: number[];
  availableCountries: CountryViewModel[];
  baseYear: number | null;
  targetYear: number | null;
  countries: CountryViewModel[] | undefined;
  disabled?: boolean;
  handleUpdateScenario: Function;
};

const validationSchema = Yup.object({
  baseYear: Yup.number().required("Base year is required"),
  targetYear: Yup.number()
    .required("Target year is required")
    .moreThan(
      Yup.ref("baseYear"),
      "Target year must be greater than base year",
    ),
  countries: Yup.array()
    .of(
      Yup.object().shape({
        display_name: Yup.string().required(),
        country: Yup.string().required(),
      }),
    )
    .min(1, "At least one country is required"),
});

export function DateAndCountryInputSection({
  years,
  availableCountries,
  baseYear,
  targetYear,
  countries,
  disabled,
  handleUpdateScenario,
}: DateAndCountryInputSectionProps) {
  const { t } = useTranslation("macro");

  const formik = useFormik({
    initialValues: {
      baseYear: baseYear || "",
      targetYear: targetYear || "",
      countries: countries?.map((c) => c) || [],
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      handleUpdateScenario({
        inputs: {
          years: {
            base_year: values.baseYear,
            target_year: values.targetYear,
          },
          countries: values.countries,
        },
      });
    },
  });

  useEffect(() => {
    formik.setValues({
      baseYear: baseYear || "",
      targetYear: targetYear || "",
      countries: countries?.map((c) => c) || [],
    });
  }, [baseYear, targetYear, countries]);

  useEffect(() => {
    const initialValues = {
      baseYear: baseYear || "",
      targetYear: targetYear || "",
      countries: countries?.map((c) => c) || [],
    };

    if (
      JSON.stringify(formik.values) !== JSON.stringify(initialValues) &&
      formik.isValid
    ) {
      formik.submitForm();
    }
  }, [formik.values, formik.isValid]);

  return (
    <Box pt={2}>
      <Typography pb={1}>
        {t("createScenarioSection.dateInput.dateHeading")}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} marginTop={"5px"}>
          <Grid item lg={3} xs={12}>
            <FormControl variant="outlined" fullWidth size={"small"}>
              <InputLabel htmlFor="base-year" id="base-year">
                {t("createScenarioSection.dateInput.baseYearLabel")}
              </InputLabel>
              <Select
                id="base-year"
                name="baseYear"
                labelId="base-year"
                label={t("createScenarioSection.dateInput.baseYearLabel")}
                value={formik.values.baseYear}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={disabled}
                error={
                  formik.touched.baseYear && Boolean(formik.errors.baseYear)
                }
                data-testid="base-year-select"
              >
                {years.map((year) => (
                  <MenuItem
                    key={year}
                    value={year}
                    data-testid={`base-year-item-${year}`}
                  >
                    {year}
                  </MenuItem>
                ))}
              </Select>
              <Box sx={{ height: "12px" }}>
                {formik.touched.baseYear && formik.errors.baseYear && (
                  <Typography color="error" variant="caption">
                    {formik.errors.baseYear}
                  </Typography>
                )}
              </Box>
            </FormControl>
          </Grid>
          <Grid item lg={3} xs={12}>
            <FormControl variant="outlined" fullWidth size={"small"}>
              <InputLabel htmlFor="target-year" id="target-year">
                {t("createScenarioSection.dateInput.targetYearLabel")}
              </InputLabel>
              <Select
                id="target-year"
                name="targetYear"
                labelId="target-year"
                label={t("createScenarioSection.dateInput.targetYearLabel")}
                value={formik.values.targetYear}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={disabled}
                error={
                  formik.touched.targetYear && Boolean(formik.errors.targetYear)
                }
                data-testid="target-year-select"
              >
                {years.map((year) => (
                  <MenuItem
                    key={year}
                    value={year}
                    data-testid={`target-year-item-${year}`}
                  >
                    {year}
                  </MenuItem>
                ))}
              </Select>
              <Box sx={{ height: "12px" }} data-testid="target-year-error-box">
                {formik.touched.targetYear && formik.errors.targetYear && (
                  <Typography color="error" variant="caption">
                    {formik.errors.targetYear}
                  </Typography>
                )}
              </Box>
            </FormControl>
          </Grid>
          <Grid item lg={3} xs={12}>
            <SelectDropdown
              listItems={availableCountries.map(
                (country) => country.display_name,
              )}
              savedSelectedItems={formik.values.countries.map(
                (c) => c.display_name,
              )}
              onSave={(selectedItems) => {
                const selectedCountries = selectedItems.map((item) =>
                  availableCountries.find((c) => c.display_name === item),
                );
                formik.setFieldValue("countries", selectedCountries);
              }}
              title={"Country"}
              compact
            />
            <Box sx={{ height: "12px" }}>
              {formik.touched.countries &&
                formik.errors.countries &&
                typeof formik.errors.countries === "string" && (
                  <Typography color="error" variant="caption">
                    {formik.errors.countries}
                  </Typography>
                )}
            </Box>
          </Grid>
        </Grid>
        <button type="submit" style={{ display: "none" }} />
      </form>
    </Box>
  );
}
