import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, CircularProgress } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MicroProductsTableSection } from "../../components";
import { useModal } from "../../../../components/common/Modal";
import { ProductColumnData } from "../../types";
import { ProductSelectionModal } from "../../../../components/common/ProductSelectionModal";
import MicroEditProductModal from "../../components/MicroEditProductModal/MicroEditProductModal";
import MicroAdjustmentsSection from "../../components/MicroAdjustmentsSection/MicroAdjustmentsSection";
import {
  CustomIcon,
  Icons,
  useGlobalLoader,
} from "../../../../components/common";
import {
  ViewMicroScenario,
  UpdateScenarioDetails,
  Product,
  EditScenarioSkuModel,
  SKUMinimalViewModel,
} from "../../../../orval/generated/models";
import { MicroProductsHeaderTable } from "../../components/MicroProductsTableSection/MicroProductsHeaderTable/MicroProductsHeaderTable";
import { COLUMN_WIDTH } from "../../constants";
import { useSnackbar } from "../../../../components/common/Notification/showSnackbar";
import { useQueryClient } from "@tanstack/react-query";
import {
  useGetMicroScenarioByIdScenariosMicroScenarioIdGet,
  useGetSkusSkusGet,
  useUpdateMicroScenarioDetailsScenariosMicroScenarioIdPatch,
  getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey,
  useAddProductsToMicroScenarioScenariosMicroScenarioIdProductsPost,
  useCopyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPost,
  useUpdateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatch,
  useDuplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePost,
  useDeleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDelete,
  useDeleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete,
  useReorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPut,
  exportMicroScenarioScenariosMicroScenarioIdExportGet,
  useDeleteAllProductsFromMicroScenarioScenariosMicroScenarioIdProductsDelete,
} from "../../../../orval/generated/endpoint";
import ConfirmationModal from "../../../../components/common/ConfirmationModal/ConfirmationModal";
import { withCustomAxios } from "../../../../orval/mutator/custom-instance";
import getFormattedDateForDownloads from "utils/getFormattedDateForDownloads";
import { ScenarioHeroSection } from "components/common/ScenarioHeroSection/ScenarioHeroSection";
import { MicroToolRoutesConfig } from "features/micro-tool/navigation";
import { MicroResultsSection } from "../../components/MicroResultsSection/MicroResultsSection";
import ThreeDotMenu from "components/common/ThreeDotMenu/ThreeDotMenu";
import getTableColumns from "../../components/HelperFunctions/getTableColumns";
import downloadBlob from "../../../../utils/downloadBlob/downloadBlob";
import { deepClone } from "utils";

function MicroToolScenarioPage() {
  const { t } = useTranslation("micro");
  const { openModal, closeModal } = useModal();
  const [productColumns, setProductColumns] = useState<ProductColumnData[]>([]);
  const [productsData, setProductsData] = useState<Array<any>>([]);
  const [columns, setColumns] = useState<MRT_ColumnDef<any>[]>([]);
  const { showGlobalLoader } = useGlobalLoader();
  const location = useLocation();
  const [firstVisit, setFirstVisit] = useState<boolean>(
    location.state?.firstVisit ?? false,
  );
  const { id: urlScenarioId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const redirectURL = MicroToolRoutesConfig.productLevelAnalytics;
  const getInitialScenarioId = useCallback(() => {
    return urlScenarioId || location.state?.id;
  }, [location.state?.id, urlScenarioId]);
  const showSnackbar = useSnackbar();
  const queryClient = useQueryClient();
  const [pageSetupIsPending, setPageSetupIsPending] = useState(true);
  const {
    data: scenarioDetails,
    isPending: scenarioIsPending,
    error: errorFetchingScenarioDetails,
  } = useGetMicroScenarioByIdScenariosMicroScenarioIdGet(
    parseInt(getInitialScenarioId()),
    {
      query: {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    },
  );

  const {
    mutateAsync: updateMicroScenario,
    isPending: updateMicroScenarioIsPending,
  } = useUpdateMicroScenarioDetailsScenariosMicroScenarioIdPatch({
    mutation: {
      onSuccess: (data: ViewMicroScenario) => {
        queryClient.invalidateQueries({
          queryKey: getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
            parseInt(getInitialScenarioId()),
          ),
        });
        setupPageData(data);
        showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
      },
      onError: (error: any) => {
        showSnackbar(t("errorMessages.errorUpdating"), "error");
        console.warn(error);
        return error;
      },
    },
  });

  const {
    mutateAsync: addProductsToMicroScenario,
    isPending: addProductsToMicroScenarioIsPending,
    isSuccess: addProductsToMicroScenarioIsSuccess,
  } = useAddProductsToMicroScenarioScenariosMicroScenarioIdProductsPost({
    mutation: {
      onSuccess: (productList: Product[]) => {
        const updatedScenario = {
          ...scenarioDetails,
          products: productList,
        };
        const queryKey =
          getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
            parseInt(getInitialScenarioId()),
          );
        queryClient.setQueryData(queryKey, updatedScenario);

        showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
      },
      onError: (error: any) => {
        showSnackbar(t("errorMessages.errorUpdating"), "error");
        console.warn(error);
        return error;
      },
    },
  });

  const {
    mutateAsync: removeAllProducts,
    isPending: removeAllProductsIsPending,
  } =
    useDeleteAllProductsFromMicroScenarioScenariosMicroScenarioIdProductsDelete(
      {
        mutation: {
          onSuccess: () => {
            const updatedScenario = {
              ...scenarioDetails,
              products: [],
            };
            const queryKey =
              getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
                parseInt(getInitialScenarioId()),
              );
            queryClient.setQueryData(queryKey, updatedScenario);

            showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
          },
          onError: (error: any) => {
            showSnackbar(t("errorMessages.errorUpdating"), "error");
            console.warn(error);
            return error;
          },
        },
      },
    );

  const { mutateAsync: reOrderProducts, isPending: reOrderProductsIsPending } =
    useReorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPut({
      mutation: {
        onSuccess: (reorderedProducts: Product[]) => {
          const queryKey =
            getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
              parseInt(getInitialScenarioId()),
            );
          queryClient.setQueryData(queryKey, {
            ...scenarioDetails,
            products: reorderedProducts,
          });

          showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
        },
        onError: (error: any) => {
          showSnackbar(t("errorMessages.errorUpdating"), "error");
          console.warn(error);
          return error;
        },
      },
    });

  const {
    mutateAsync: deleteAdjustments,
    isPending: deleteAdjustmentsIsPending,
  } = useDeleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
            parseInt(getInitialScenarioId()),
          ),
        });
        showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
      },
      onError: (error: any) => {
        showSnackbar(t("errorMessages.errorUpdating"), "error");
        console.warn(error);
        return error;
      },
    },
  });

  const {
    data: skusData,
    isPending: skusIsPending,
    isError: errorFetchingSkus,
  } = useGetSkusSkusGet();

  const handleAddProducts = useCallback(
    async (selectedProducts: Product[]) => {
      if (skusData) {
        try {
          const newSkusIds = selectedProducts
            .filter((product) => !product.guid)
            .map((product) => (product as unknown as SKUMinimalViewModel).id);

          const productsHashmap: { [key: string]: string } = {};
          const addResult = newSkusIds.length
            ? await addProductsToMicroScenario({
                scenarioId: getInitialScenarioId(),
                data: newSkusIds,
              })
            : null;

          const isOrderChanged = (
            oldProducts: Product[],
            newProducts: Product[],
          ) =>
            deepClone(oldProducts)
              .sort((a: Product, b: Product) => a.position - b.position)
              .some(
                (product: Product, i: number) =>
                  (product.custom_display_name || product.product_name) !==
                  (newProducts[i].custom_display_name ||
                    newProducts[i].product_name),
              );

          const updateProductHashMap = (
            products: Product[],
            productsHashmap: { [key: string]: string },
          ) => {
            products.forEach((product) => {
              const mapKey =
                product.custom_display_name || product.product_name;
              if (!productsHashmap[mapKey])
                productsHashmap[mapKey] = product.guid;
            });
          };

          const requireReorder = addResult
            ? isOrderChanged(addResult, selectedProducts)
            : isOrderChanged(
                scenarioDetails?.products as Product[],
                selectedProducts,
              );

          if (requireReorder) {
            updateProductHashMap(
              addResult || (selectedProducts as Product[]),
              productsHashmap,
            );

            const data = selectedProducts.map((product, index) => ({
              product_guid:
                productsHashmap[
                  product.custom_display_name || product.product_name
                ],
              position: index + 1,
            }));

            if (data.length > 0) {
              await reOrderProducts({
                scenarioId: getInitialScenarioId(),
                data: data,
              });
            }
          }
          closeModal();
        } catch (e) {
          showSnackbar(t("errorMessages.errorUpdating"), "error");
          console.warn(e);
        }
      }
    },
    [
      addProductsToMicroScenario,
      closeModal,
      getInitialScenarioId,
      reOrderProducts,
      scenarioDetails?.products,
      showSnackbar,
      skusData,
      t,
    ],
  );

  useEffect(() => {
    scenarioIsPending ||
    skusIsPending ||
    pageSetupIsPending ||
    deleteAdjustmentsIsPending
      ? showGlobalLoader(true)
      : showGlobalLoader(false);
  }, [
    scenarioIsPending,
    skusIsPending,
    pageSetupIsPending,
    showGlobalLoader,
    deleteAdjustmentsIsPending,
  ]);

  const setupPageData = useCallback(
    (scenarioDetails: ViewMicroScenario) => {
      const _productColumns: ProductColumnData[] = [];
      const sortedProducts: Product[] = deepClone(
        scenarioDetails.products,
      ).sort((a: Product, b: Product) => a.position - b.position);

      const productsHashMap: { [key: string]: Product } = {};
      sortedProducts.forEach((product) => {
        productsHashMap[product.custom_display_name || product.product_name] =
          product;
      });

      sortedProducts.forEach((product: Product) => {
        const productData =
          productsHashMap[product.custom_display_name || product.product_name];
        _productColumns.push({
          name: productData.product_name ?? "",
          guid: productData.guid,
          customProductName: productData.custom_display_name ?? null,
          hasAdjustments: productData.has_adjustments,
        });
      });

      setProductColumns(_productColumns);
      setProductsData(
        sortedProducts.map((product) => ({
          ...product,
          id: skusData?.find(
            (sku: { product_name: string }) =>
              sku.product_name === product.product_name,
          )?.id,
        })),
      );
      setColumns(
        getTableColumns(_productColumns, undefined, COLUMN_WIDTH, false),
      );
      setPageSetupIsPending(false);
    },
    [skusData],
  );

  useEffect(() => {
    scenarioDetails && setupPageData(scenarioDetails);
    if (errorFetchingScenarioDetails) {
      showSnackbar(t("errorMessages.errorFetchingScenarioDetails"), "error");
    }
  }, [
    scenarioDetails,
    errorFetchingScenarioDetails,
    skusIsPending,
    setupPageData,
    showSnackbar,
    t,
  ]);

  useEffect(() => {
    if (errorFetchingSkus) {
      showSnackbar(t("errorMessages.errorNoProducts"), "error");
    }
  }, [skusIsPending, showGlobalLoader, errorFetchingSkus, showSnackbar, t]);

  const handleExportResults = async () => {
    showGlobalLoader(true);
    try {
      const response: typeof exportMicroScenarioScenariosMicroScenarioIdExportGet =
        await withCustomAxios({
          url: `/scenarios/micro/${getInitialScenarioId()}/export`,
          method: "GET",
          responseType: "blob",
        });

      const fileName = `PEF-${scenarioDetails?.name}-${getFormattedDateForDownloads()}.xlsx`;

      downloadBlob(response as unknown as BlobPart, fileName);

      showSnackbar(t("exportSuccess"), "success");
    } catch (error) {
      console.warn(error);
      showSnackbar(t("exportFailed"), "error");
    } finally {
      showGlobalLoader(false);
    }
  };

  const handleUpdateScenarioDetails = async (data: {
    id: number;
    payload: UpdateScenarioDetails;
  }) => {
    try {
      await updateMicroScenario({
        scenarioId: data.id,
        data: data.payload,
      });
    } catch (error) {
      showSnackbar(
        t("microViewAllScenariosPage.updateDetailsModal.duplicateError"),
        "error",
      );
      queryClient.resetQueries({
        queryKey: getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
          parseInt(getInitialScenarioId()),
        ),
      });
    }
  };

  const handleRenameDescription = async (value: string) => {
    if (
      scenarioDetails &&
      scenarioDetails.description !== value &&
      scenarioDetails.id
    ) {
      if (value.length > 255) {
        showSnackbar(t("errorMessages.shorterDescription"), "error");
        return;
      }

      const _updateScenarioPayload: UpdateScenarioDetails = {
        description: value,
      };
      await handleUpdateScenarioDetails({
        id: scenarioDetails.id,
        payload: _updateScenarioPayload,
      });
    }
  };

  const handleSelectProduct = () => {
    if (
      skusData &&
      !pageSetupIsPending &&
      !addProductsToMicroScenarioIsPending
    ) {
      openModal(
        <ProductSelectionModal
          closeModal={closeModal}
          products={productsData}
          skus={skusData}
          scenarioDetails={scenarioDetails as ViewMicroScenario}
          handleAddProducts={handleAddProducts}
        />,
        true,
        "lg",
      );
    }
  };

  const handleClearAllProducts = async () => {
    try {
      showGlobalLoader(true);
      await removeAllProducts({ scenarioId: getInitialScenarioId() });
    } catch (e) {
      showSnackbar(t("errorMessages.errorUpdating"), "error");
    } finally {
      showGlobalLoader(false);
      closeModal();
    }
  };

  const onHandleClearProducts = () => {
    if (productColumns) {
      openModal(
        <ConfirmationModal
          title={t("micro:productsSection.clearProductsModal.title")}
          description={t(
            "micro:productsSection.clearProductsModal.description",
          )}
          actionTitle={t("common:actions.clear")}
          confirmAction={handleClearAllProducts}
          cancelAction={closeModal}
        />,
      );
    }
  };

  const onHandleClearAdjustments = () => {
    if (productColumns) {
      openModal(
        <ConfirmationModal
          title={t("micro:productsSection.clearAdjustmentsModal.title")}
          description={t(
            "micro:productsSection.clearAdjustmentsModal.description",
          )}
          actionTitle={t("common:actions.clear")}
          confirmAction={() => {
            handleClearAdjustments();
            closeModal();
          }}
          cancelAction={closeModal}
        />,
      );
    }
  };

  const getAdjustmentsIds = () => {
    let adjustmentsIds: number[] = [];

    scenarioDetails?.products.forEach((product) => {
      product?.adjustments.forEach((adjustment) => {
        if (adjustment.sub_accordions?.length) {
          adjustment.sub_accordions.forEach((sub_accordion) => {
            sub_accordion.fields?.forEach((field) => {
              if (field.adjustment_id) adjustmentsIds.push(field.adjustment_id);
            });
          });
        } else {
          adjustment.fields?.forEach((field) => {
            if (field.adjustment_id) adjustmentsIds.push(field.adjustment_id);
          });
        }
      });
    });

    return adjustmentsIds;
  };

  const handleClearAdjustments = async () => {
    const adjustmentsIds = getAdjustmentsIds();

    if (adjustmentsIds.length) {
      try {
        await deleteAdjustments({
          scenarioId: getInitialScenarioId(),
          data: adjustmentsIds,
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const openCopyToAllChangesModal = (productGuid: string) => {
    openModal(
      <ConfirmationModal
        title={t("micro:productsSection.copyAdjustmentsToAllModal.title")}
        description={t(
          "micro:productsSection.copyAdjustmentsToAllModal.description",
        )}
        actionTitle={t("common:actions.change")}
        confirmAction={() => {
          handleCopyAdjustmentsToAllProducts(productGuid);
          closeModal();
        }}
        cancelAction={closeModal}
      />,
    );
  };

  const handleCopyAdjustmentsToAllProducts = async (productGuid: string) => {
    if (scenarioDetails) {
      try {
        showGlobalLoader(true);
        await copyAdjustmentsToAllProducts({
          scenarioId: scenarioDetails.id,
          productGuid: productGuid,
        });
      } catch (error) {
        showSnackbar(
          t("errorMessages.errorCopyingChangesToAllProducts"),
          "error",
        );
        console.warn(error);
      } finally {
        showGlobalLoader(false);
      }
    }
  };

  const {
    mutateAsync: copyAdjustmentsToAllProducts,
    isPending: copyAdjustmentsToAllProductsIsPending,
  } =
    useCopyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPost(
      {
        mutation: {
          onSuccess: () => {
            if (scenarioDetails) {
              queryClient.invalidateQueries({
                queryKey:
                  getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
                    scenarioDetails.id,
                  ),
              });
            }
          },
          onError: (error: any) => {
            showSnackbar(t("errorMessages.errorCopyingAdjustments"), "error");
            console.warn(error);
            return error;
          },
        },
      },
    );

  const {
    mutateAsync: duplicateProductOnScenario,
    isPending: duplicateProductOnScenarioIsPending,
  } =
    useDuplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePost(
      {
        mutation: {
          onSuccess: () => {
            if (scenarioDetails) {
              queryClient.invalidateQueries({
                queryKey:
                  getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
                    scenarioDetails.id,
                  ),
              });
            }
          },
          onError: (error) => {
            showSnackbar(t("errorMessages.errorDuplicatingProduct"), "error");
            console.warn(error);
            return error;
          },
        },
      },
    );

  const handleDuplicateProduct = async (productGuid: string) => {
    if (scenarioDetails) {
      try {
        showGlobalLoader(true);
        await duplicateProductOnScenario({
          scenarioId: scenarioDetails.id,
          productGuid,
        });
      } catch (error) {
        showSnackbar(t("errorMessages.errorDuplicatingProduct"), "error");
        console.warn(error);
      } finally {
        showGlobalLoader(false);
      }
    }
  };

  const {
    mutateAsync: deleteProductOnScenario,
    isPending: deleteProductsOnSkuIsPending,
  } =
    useDeleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDelete({
      mutation: {
        onSuccess: () => {
          if (scenarioDetails) {
            queryClient.invalidateQueries({
              queryKey:
                getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
                  scenarioDetails.id,
                ),
            });
          }
        },
        onError: (error) => {
          showSnackbar(t("errorMessages.errorDeletingProduct"), "error");
          console.warn(error);
          return error;
        },
      },
    });

  const handleDeleteProducts = async (productGuid: string) => {
    if (scenarioDetails) {
      try {
        showGlobalLoader(true);
        await deleteProductOnScenario({
          scenarioId: scenarioDetails.id,
          productGuid,
        });
      } catch (error) {
        showSnackbar(t("errorMessages.errorDeletingProduct"), "error");
        console.warn(error);
      } finally {
        showGlobalLoader(false);
      }
    }
  };

  const openConfirmDeleteProductModal = (productGuid: string) => {
    openModal(
      <ConfirmationModal
        title={t("micro:productsSection.deleteProductModal.title")}
        description={t("micro:productsSection.deleteProductModal.description")}
        actionTitle={t("common:actions.remove")}
        confirmAction={() => {
          handleDeleteProducts(productGuid);
          closeModal();
        }}
        cancelAction={closeModal}
      />,
    );
  };

  const openEditProductModal = (productGuid: string) => {
    if (scenarioDetails) {
      openModal(
        <MicroEditProductModal
          productGuid={productGuid}
          closeModal={closeModal}
          scenarioDetails={scenarioDetails}
          handleUpdateProductDisplayName={handleUpdateProductDisplayName}
        />,
      );
    }
    return;
  };

  const {
    mutateAsync: updateProductDisplayName,
    isPending: updateProductDisplayNameIsPending,
  } = useUpdateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatch(
    {
      mutation: {
        onSuccess: () => {
          if (scenarioDetails) {
            queryClient.invalidateQueries({
              queryKey:
                getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
                  scenarioDetails.id,
                ),
            });
          }
        },
        onError: (error) => {
          showSnackbar(t("errorMessages.errorUpdatingProductName"), "error");
          console.warn(error);
          return error;
        },
      },
    },
  );

  const handleUpdateProductDisplayName = async (data: {
    scenarioId: number;
    productId: string;
    data: EditScenarioSkuModel;
  }) => {
    try {
      showGlobalLoader(true);
      await updateProductDisplayName({
        scenarioId: data.scenarioId,
        productGuid: data.productId,
        data: data.data,
      });
    } catch (error) {
      showSnackbar(t("errorMessages.errorUpdatingProductName"), "error");
      console.warn(error);
    } finally {
      showGlobalLoader(false);
    }
  };

  const handleRenameScenario = async (value: string) => {
    if (
      scenarioDetails &&
      scenarioDetails.name !== value &&
      scenarioDetails.id
    ) {
      const _updateScenarioPayload: UpdateScenarioDetails = {
        name: value,
      };
      await handleUpdateScenarioDetails({
        id: scenarioDetails.id,
        payload: _updateScenarioPayload,
      });
    }
  };

  useEffect(() => {
    updateMicroScenarioIsPending ||
    addProductsToMicroScenarioIsPending ||
    scenarioIsPending ||
    skusIsPending ||
    pageSetupIsPending ||
    deleteProductsOnSkuIsPending ||
    duplicateProductOnScenarioIsPending ||
    updateProductDisplayNameIsPending ||
    copyAdjustmentsToAllProductsIsPending ||
    reOrderProductsIsPending ||
    removeAllProductsIsPending
      ? showGlobalLoader(true)
      : showGlobalLoader(false);
  }, [
    updateMicroScenarioIsPending,
    scenarioIsPending,
    skusIsPending,
    pageSetupIsPending,
    showGlobalLoader,
    addProductsToMicroScenarioIsPending,
    deleteProductsOnSkuIsPending,
    duplicateProductOnScenarioIsPending,
    updateProductDisplayNameIsPending,
    copyAdjustmentsToAllProductsIsPending,
    reOrderProductsIsPending,
    removeAllProductsIsPending,
  ]);

  useEffect(() => {
    if (
      !scenarioIsPending &&
      !pageSetupIsPending &&
      !addProductsToMicroScenarioIsPending &&
      addProductsToMicroScenarioIsSuccess &&
      scenarioDetails?.products &&
      scenarioDetails?.products?.length < 1
    ) {
      handleSelectProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageSetupIsPending,
    scenarioDetails,
    addProductsToMicroScenarioIsPending,
    addProductsToMicroScenarioIsSuccess,
    scenarioIsPending,
  ]);

  useEffect(() => {
    if (firstVisit) {
      if (skusData) {
        openModal(
          <ProductSelectionModal
            closeModal={closeModal}
            products={productsData}
            skus={skusData}
            scenarioDetails={scenarioDetails as ViewMicroScenario}
            handleAddProducts={handleAddProducts}
          />,
          true,
          "lg",
        );

        setFirstVisit(false);
      }
    }
  }, [
    closeModal,
    firstVisit,
    handleAddProducts,
    openModal,
    productsData,
    scenarioDetails,
    skusData,
  ]);

  return (
    <Box height={"100%"} minWidth={"91vw"}>
      <Box sx={{ backgroundColor: "white" }}>
        <ScenarioHeroSection
          scenario={scenarioDetails as ViewMicroScenario}
          onRenameScenario={handleRenameScenario}
          handleRenameDescription={handleRenameDescription}
          backBtnText={t("backToMicroHome")}
          backBtnHandler={() => {
            navigate(`${redirectURL}`);
          }}
          headersIndent="14px"
          actionsSection={
            <Box
              display={"flex"}
              alignItems={"center"}
              maxWidth={"20%"}
              marginLeft={"auto"}
              minWidth="14em"
            >
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                onClick={handleSelectProduct}
                startIcon={
                  <CustomIcon
                    name={Icons.ADD}
                    width={"24px"}
                    height={"24px"}
                    fill="white"
                  />
                }
                disabled={skusIsPending}
              >
                {t("micro:heroSectionActions.selectProduct")}
                {skusIsPending && (
                  <CircularProgress sx={{ color: "white", ml: 1 }} size={16} />
                )}
              </Button>
              <ThreeDotMenu
                menuItems={[
                  {
                    label: t("heroSectionActions.exportResults"),
                    onClick: handleExportResults,
                    disabled: !productColumns?.length,
                  },
                  {
                    label: t("heroSectionActions.clearSelection"),
                    onClick: () => onHandleClearProducts(),
                    disabled: !productColumns?.length,
                  },
                  {
                    label: t("heroSectionActions.clearAdjustments"),
                    onClick: onHandleClearAdjustments,
                    disabled: !Boolean(scenarioDetails?.has_adjustments),
                  },
                ]}
              ></ThreeDotMenu>
            </Box>
          }
        />
      </Box>
      <Box sx={{ overflowX: "auto" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "fit-content",
          }}
        >
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 5,
              backgroundColor: "white",
            }}
          >
            <MicroProductsHeaderTable
              openCopyToAllChangesModal={openCopyToAllChangesModal}
              handleDuplicateProduct={handleDuplicateProduct}
              openEditProductModal={openEditProductModal}
              openConfirmDeleteProductModal={openConfirmDeleteProductModal}
              scenario={scenarioDetails as ViewMicroScenario}
              products={productColumns}
            />
          </Box>
          <Box
            sx={{
              height: "calc(100vh - 423px)",
              overflowY: "auto",
              minWidth: "calc(100vw - 120px)",
              paddingBottom: "40px",
            }}
          >
            <MicroProductsTableSection
              disabled={!scenarioDetails}
              products={productColumns}
              scenario={scenarioDetails}
            />
            {productColumns && productColumns.length > 0 && (
              <Box>
                <MicroAdjustmentsSection
                  disabled={!scenarioDetails}
                  productColumnData={productColumns}
                  scenario={scenarioDetails}
                />
                <MicroResultsSection
                  disabled={!scenarioDetails}
                  columns={columns}
                  scenario={scenarioDetails}
                  products={productColumns}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default MicroToolScenarioPage;
