import ListItemText from "@mui/material/ListItemText";
import { MenuItem, Tooltip } from "@mui/material";
import React from "react";

const NoItemsRow = <T extends string | number>() => {
  return (
    <MenuItem
      sx={{
        height: "2.5em",
        maxWidth: "100%",
        overflow: "hidden",
      }}
    >
      <Tooltip title={"No items found"} placement="right">
        <ListItemText primary={"No items found"} />
      </Tooltip>
    </MenuItem>
  );
};

export default NoItemsRow;
