import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    textColor: Palette["primary"];
    activeColor: Palette["primary"];
  }

  interface PaletteOptions {
    textColor: PaletteOptions["primary"];
    activeColor: PaletteOptions["primary"];
  }
}

const appTheme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: ["Helvetica Neue", "Arial", "sans-serif"].join(","),
    allVariants: {
      color: "#333333",
    },
    h1: {
      fontSize: "2rem",
    },
    h2: {
      fontSize: "1.75rem",
    },
    h3: {
      fontSize: "1.5rem",
    },
    h4: {
      fontSize: "1.25rem",
    },
    button: {
      textTransform: "none" as const,
    },
    body1: {
      fontSize: "16px",
      letterSpacing: "-.025rem",
      fontStretch: "normal",
      fontWeight: 400,
      lineHeight: "22px",
    },
  },
  palette: {
    primary: {
      main: "#d04a02",
      light: "#eb8c00",
    },
    secondary: {
      main: "#4577c9",
      light: "#00c6f7",
    },
    background: {
      default: "#ffffff",
    },
    success: {
      main: "#D3EBD5",
      light: "#D3EBD5",
      dark: "#32613D",
      contrastText: "#FDAB8D",
    },
    error: {
      main: "#E8AAA3",
      light: "#E8AAA3",
      dark: "#773829",
    },
    warning: {
      main: "#FFE5A5",
      light: "#FFE5A5",
      dark: "#786124",
    },
    info: {
      main: "#C3D3E2",
      light: "#C3D3E2",
      dark: "#40576D",
    },
    textColor: {
      main: "#333333",
      light: "#CBCBCB",
      dark: "#6A6A6A",
    },
    activeColor: {
      main: "#FEDACC",
      dark: "#773829",
    },
  },
});

export { appTheme };
