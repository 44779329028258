import { Box, Typography } from "@mui/material";
import { DataSet } from "../../types";
import MicroBarChart from "../MicroBarChart/MicroBarChart";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DisplayOption } from "../../types";

export type MicroSingleChartSectionChartValuesProps = {
  labels: string[];
  datasets: DataSet[];
};

export type MicroSingleChartSectionProps = {
  label: string;
  stackedUnit: string;
  chartType: string;
  nonAdjustedChartValues?: MicroSingleChartSectionChartValuesProps;
  adjustedChartValues?: MicroSingleChartSectionChartValuesProps;
  displayOption: DisplayOption;
};

export default function MicroSingleChartSection({
  label,
  stackedUnit,
  chartType,
  nonAdjustedChartValues,
  adjustedChartValues,
  displayOption,
}: MicroSingleChartSectionProps) {
  const [emissionOption, setEmissionOption] = useState<string>("");
  const [combinedData, setCombinedData] =
    useState<MicroSingleChartSectionChartValuesProps>();
  const { t } = useTranslation("common");
  useEffect(() => {
    if (stackedUnit !== "") {
      setEmissionOption(stackedUnit);
    }
  }, [stackedUnit]);

  useEffect(() => {
    const combinedData = {
      labels: adjustedChartValues?.labels ||
        nonAdjustedChartValues?.labels || [""],
      datasets: [
        ...(displayOption === "Both" || displayOption === "Adjusted Only"
          ? adjustedChartValues?.datasets || []
          : []),
        ...(displayOption === "Both" || displayOption === "Original Only"
          ? nonAdjustedChartValues?.datasets || []
          : []),
      ].reverse(), // Reverse the datasets array
    };
    setCombinedData(combinedData);
  }, [adjustedChartValues, nonAdjustedChartValues, displayOption]);

  const graphMaxTotal = findHighestValue(combinedData);
  const legendWidth = calculateLegendWidth(graphMaxTotal);
  return (
    <Box display={"flex"} flexDirection={"row"} gap={"24px"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"4px"}
        width={legendWidth}
        minWidth={legendWidth}
        alignItems={"flex-start"}
      >
        <Box>
          <Typography className="header-H4-bold" marginBottom={0}>
            {label}
          </Typography>
          <Typography className="sub-heading" marginBottom={"24px"}>
            {t("units.gc02e")}/{stackedUnit}
          </Typography>
        </Box>
        <Box>
          {adjustedChartValues &&
            [...adjustedChartValues.datasets].map((item, index) => (
              <Box key={index} display="flex" alignItems="center" gap={1}>
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "15px",
                    backgroundColor: item.backgroundColor,
                  }}
                  data-testid={`${
                    item.label.split(":")[1].trim().charAt(0).toUpperCase() +
                    item.label.split(":")[1].trim().slice(1)
                  }-color-box`}
                />
                <Typography>
                  {item.label === "Adjusted : cde"
                    ? "CDE"
                    : item.label.split(":")[1].trim().charAt(0).toUpperCase() +
                      item.label.split(":")[1].trim().slice(1)}
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>
      <MicroBarChart
        values={combinedData}
        chartType={chartType}
        legendWidth={legendWidth}
      />
    </Box>
  );
}

const findHighestValue = (data?: MicroSingleChartSectionChartValuesProps) => {
  let totalOriginal = 0;
  let totalAdjusted = 0;

  data?.datasets?.forEach((dataset) => {
    const maxValueInDataset = dataset.data.reduce((a, b) => Math.max(a, b));

    dataset.stack === "Adjusted"
      ? (totalAdjusted += maxValueInDataset)
      : (totalOriginal += maxValueInDataset);
  });

  const maxNum = Math.max(totalOriginal, totalAdjusted);

  // efficient way of calculating the length of the integer part of a number without toString().length
  // https://stackoverflow.com/a/14879700
  const lengthOfIntegerPart = (Math.log(maxNum) * Math.LOG10E + 1) | 0;
  return lengthOfIntegerPart;
};

const calculateLegendWidth = (maxValueDigits: number) => {
  if (maxValueDigits === 1) return 345;

  const numCommas = Math.floor((maxValueDigits - 1) / 3);
  const answer = 361 - numCommas * 4 - maxValueDigits * 6;

  return answer;
};
