import { createContext, useContext, useEffect } from "react";
import { useSnackbar } from "../../components/common/Notification/showSnackbar";
import { useGetMemberInfoMembersMemberInfoGet } from "../../orval/generated/endpoint";
import { MemberDetails } from "../../orval/generated/models";
import { AxiosError } from "axios";
import { useTermsContext } from "../../features/terms-and-conditions/components/TermsContext";

interface MemberInfoContextType {
  memberInfo: MemberDetails | null;
  memberInfoDataIsPending: boolean;
  memberInfoIsFetched: boolean;
  hasPermission: (requiredPermission: string) => boolean;
}

export const MemberInfoContext = createContext<MemberInfoContextType | null>(
  null,
);

export const MemberInfoProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const showSnackbar = useSnackbar();
  const { isTermsAccepted } = useTermsContext();

  const {
    data,
    isPending: memberInfoDataIsPending,
    isFetched: memberInfoIsFetched,
    error: memberInfoError,
  } = useGetMemberInfoMembersMemberInfoGet({
    query: { enabled: !!isTermsAccepted },
  });
  const memberInfo: MemberDetails | null = data ?? null;

  const hasPermission = (requiredPermission: string): boolean =>
    memberInfo?.permissions.functional_access.some(
      (access) => access.name === requiredPermission,
    ) ?? false;

  useEffect(() => {
    if (memberInfoError)
      showSnackbar(memberInfoError as AxiosError<unknown, any>, "error");
  }, [memberInfoError, showSnackbar])

  return (
    <MemberInfoContext.Provider
      value={{
        memberInfo,
        memberInfoDataIsPending,
        memberInfoIsFetched,
        hasPermission,
      }}
    >
      {children}
    </MemberInfoContext.Provider>
  );
};

export const useMemberInfo = () => {
  const context = useContext(MemberInfoContext);
  if (!context) {
    throw new Error("useMemberInfo must be used within a MemberInfoProvider");
  }
  return context;
};
