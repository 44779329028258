import { numberParser } from "../../../../../utils/numberParser";
import { AppConstant } from "../../../../../constants";
import { rowValidationType } from "../types";
import { MicroValidationErrors } from "./MicroValidationErrors";

function validateOverride(
  num: number,
  rowValidationType: rowValidationType,
  key: string,
  acc: Record<string, string | undefined>,
) {
  const isUnit = rowValidationType?.type === "unit";
  const isNonZero = rowValidationType.isNonZero;

  if (isUnit) {
    const errorMessage = isNonZero
      ? MicroValidationErrors.GreaterThanZero
      : MicroValidationErrors.GreaterThanOrEqualToZero;
    if ((isNonZero && num <= 0) || (!isNonZero && num < 0)) {
      return { ...acc, [key]: errorMessage };
    }
  } else {
    const errorMessage = isNonZero
      ? rowValidationType.field_key === "net_loss_rate"
        ? MicroValidationErrors.GreaterThanZeroOrLessThanOrEqualToHundred
        : MicroValidationErrors.BetweenZeroAndHundred
      : MicroValidationErrors.BetweenZeroAndHundredInclusive;
    if (
      (isNonZero &&
        rowValidationType.field_key !== "net_loss_rate" &&
        (num <= 0 || num >= 100)) ||
      (rowValidationType.field_key === "net_loss_rate" &&
        (num <= 0 || num > 100)) ||
      (!isNonZero && (num < 0 || num > 100))
    ) {
      return { ...acc, [key]: errorMessage };
    }
  }
  return acc;
}

function validatePercentageChange(
  num: number,
  rowValidationType: rowValidationType,
  key: string,
  acc: Record<string, string | undefined>,
) {
  const isNonZero = rowValidationType.isNonZero;

  if (isNonZero && num <= -100) {
    return { ...acc, [key]: MicroValidationErrors.GreaterThanNegativeHundred };
  } else if (!isNonZero && num < -100) {
    return {
      ...acc,
      [key]: MicroValidationErrors.GreaterThanOrEqualToNegativeHundred,
    };
  }
  return acc;
}

function validateData(
  data: Record<string, string>,
  rowType: any,
  rowValidationType: rowValidationType,
): Record<string, string | undefined> {
  const dataObj = Object.entries(data);

  return dataObj.reduce((acc, data) => {
    const [key, value] = data;

    if (key === "metric") {
      return acc;
    }

    if (!value) {
      return acc;
    }

    const num = numberParser.parse(value);
    if (value !== AppConstant.emptyCell) {
      if (isNaN(num) || !isFinite(num)) {
        return {
          ...acc,
          [key]: MicroValidationErrors.InvalidNumber,
        };
      }
    }

    const validateFunction =
      rowType === "new_value" ? validateOverride : validatePercentageChange;
    return validateFunction(num, rowValidationType, key, acc);
  }, {});
}

export default validateData;
