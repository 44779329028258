import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Button from "@mui/material/Button";
import { useModal } from "../../../../../components/common/Modal";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { withCustomAxios } from "../../../../../orval/mutator/custom-instance";
import { useSnackbar } from "../../../../../components/common/Notification/showSnackbar";
import { useGlobalLoader } from "../../../../../components/common";
import downloadBlob from "../../../../../utils/downloadBlob/downloadBlob";
import UploadFiles from "../../../../../components/common/UploadFiles/UploadFiles";
import {
  getGetAllMembersMembersGetQueryKey,
  useUploadBulkUserMembersUploadPost,
} from "../../../../../orval/generated/endpoint";
import { BodyUploadBulkUserMembersUploadPost } from "../../../../../orval/generated/models";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { AppConstant } from "constants/AppConstant";
import { useQueryClient } from "@tanstack/react-query";

const AddMultipleUsersModal = () => {
  const { closeModal } = useModal();
  const { t } = useTranslation(["toolConfiguration, common"]);
  const { showGlobalLoader } = useGlobalLoader();
  const showSnackbar = useSnackbar();
  const queryClient = useQueryClient();

  const handleDownloadTemplate = async () => {
    showGlobalLoader(true);
    try {
      const response = await withCustomAxios({
        url: `/files/user-upload-template`,
        method: "GET",
        responseType: "blob",
      });

      const fileName = `PEF-UserUploadTemplate.xlsx`;

      downloadBlob(response as unknown as BlobPart, fileName);

      showSnackbar(
        t(
          "toolConfiguration:userManagementPage.addMultipleUsersModal.successfullyDownloadedTemplate",
        ),
        "success",
      );
    } catch (error) {
      console.warn(error);
      showSnackbar(
        t(
          "toolConfiguration:userManagementPage.addMultipleUsersModal.errorDownloadingTemplate",
        ),
        "error",
      );
    } finally {
      showGlobalLoader(false);
    }
  };

  const handleFormSubmit = async (
    values: BodyUploadBulkUserMembersUploadPost,
  ) => {
    try {
      await bulkUploadUsers({ data: values });
    } catch (error) {
      console.error(error);
    } finally {
      // DO NOT REMOVE FROM FINALLY
      // this the only place the refetch works
      await queryClient.refetchQueries({
        queryKey: getGetAllMembersMembersGetQueryKey(),
      });
      closeModal();
    }
  };

  const { mutateAsync: bulkUploadUsers, isPending: bulkUploadUsersIsPending } =
    useUploadBulkUserMembersUploadPost({
      mutation: {
        onSuccess: () => {
          showSnackbar(
            "toolConfiguration:userManagementPage.addMultipleUsersModal.uploadSuccessMessage",
            "success",
          );
        },
        onError: (error: AxiosError<unknown, any>) => {
          showSnackbar(error, "error");
        },
      },
    });

  const formik = useFormik<{ file: File | null }>({
    initialValues: {
      file: null,
    },
    validationSchema: yup.object({
      file: yup
        .mixed()
        .test(
          "fileFormat",
          t("common:validation.invalidFileType"),
          (value) =>
            value instanceof File &&
            [AppConstant.fileMIMETypes.xlsx].includes(value.type),
        ),
    }),
    onSubmit: (values) => {
      if (values.file) {
        handleFormSubmit(values as BodyUploadBulkUserMembersUploadPost);
      } else {
        showSnackbar(t("common:validation.invalidFileType"), "error");
      }
    },
  });

  useEffect(() => {
    bulkUploadUsersIsPending
      ? showGlobalLoader(true, true)
      : showGlobalLoader(false);
  }, [bulkUploadUsersIsPending, showGlobalLoader]);

  return (
    <Box pb={2}>
      <Box display="flex" justifyContent="flex-end" p={1}>
        <IconButton
          aria-label="delete"
          sx={{ aspectRatio: 1 }}
          onClick={closeModal}
          disabled={bulkUploadUsersIsPending}
        >
          <CloseSharpIcon />
        </IconButton>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mx={8}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography textAlign="center" variant="h3" fontWeight="bold" mb={2}>
            {t(
              "toolConfiguration:userManagementPage.addMultipleUsersModal.title",
            )}
          </Typography>
          <Typography textAlign="center" variant="body2" mb={2}>
            {t(
              "toolConfiguration:userManagementPage.addMultipleUsersModal.description",
            )}
          </Typography>
          <Button
            variant="text"
            onClick={handleDownloadTemplate}
            startIcon={<FileDownloadOutlinedIcon />}
          >
            {t(
              "toolConfiguration:userManagementPage.addMultipleUsersModal.downloadTemplateText",
            )}
          </Button>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          width="100%"
          mb={3}
          component="form"
          onSubmit={formik.handleSubmit}
        >
          <UploadFiles
            files={formik.values.file ? [formik.values.file as File] : []}
            setFiles={(value: File[]) => {
              if (Array.isArray(value)) {
                formik.setFieldValue("file", value[0]);
              }
            }}
            validMIMEFileTypes={[AppConstant.fileMIMETypes.xlsx]}
            multiple={false}
            maxFilesNumber={1}
          />

          <Stack direction="row" spacing={2} margin="auto" pt={4}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => closeModal()}
              disabled={bulkUploadUsersIsPending}
            >
              {t("common:actions.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                !formik.dirty || !formik.isValid || bulkUploadUsersIsPending
              }
            >
              {t("common:actions.save")}
              {bulkUploadUsersIsPending && (
                <CircularProgress sx={{ color: "white", ml: 1 }} size={16} />
              )}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
export default AddMultipleUsersModal;
