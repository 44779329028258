import { useState } from "react";
import { getExportData } from "./HelperService";
import { ManageExportPanel } from "../../../../components/common/ManageExportPanel/ManageExportPanel";
import xlsx from "json-as-xlsx";
import { ViewMacroScenarioViewModel } from "../../../../orval/generated/models";

type Props = {
  scenario: ViewMacroScenarioViewModel | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export function MacroManageExport({ scenario, open, setOpen }: Props) {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [optionValues, setOptionsValues] = useState<
    [] | { label: string; keys: string[]; value: object[] }[]
  >([]);

  const options = [
    "overview",
    "absoulteEmission",
    "perLitreEmission",
    "movementEmission",
    "timeEvolution",
    "kpis",
    "mekkoUnderlying",
  ];

  // useEffect(() => {
  //   if (scenario?.results) {
  //     setOptionsValues([
  //       {
  //         label: "overview",
  //         keys: ["base", "glide", "scenario"],
  //         value: [
  //           {
  //             sheet: "overview_categ_per_litre",
  //             tableData: scenario?.results.category.overview.per_litre,
  //           },
  //           {
  //             sheet: "overview_categ_absol",
  //             tableData: scenario?.results.category.overview.absolute,
  //           },
  //           {
  //             sheet: "overview_byPack_per_litre",
  //             tableData: scenario?.results.by_pack.overview.per_litre,
  //           },
  //           {
  //             sheet: "overview_byPack_absol",
  //             tableData: scenario?.results.by_pack.overview.absolute,
  //           },
  //         ],
  //       },
  //       {
  //         keys: [],
  //         label: "absoulteEmission",
  //         value: [
  //           {
  //             sheet: "absol_by_pillar_categ_",
  //             tableData: scenario?.results.category.absolute_by_pillar,
  //           },
  //           {
  //             sheet: "absol_by_pillar_byPack_",
  //             tableData: scenario?.results.by_pack.absolute_by_pillar,
  //           },
  //         ],
  //       },
  //       {
  //         keys: [],
  //         label: "perLitreEmission",
  //         value: [
  //           {
  //             sheet: "pl_by_pillar_categ_",
  //             tableData: scenario?.results.category.per_litre_by_pillar,
  //           },
  //           {
  //             sheet: "pl_by_pillar_byPack_",
  //             tableData: scenario?.results.by_pack.per_litre_by_pillar,
  //           },
  //         ],
  //       },
  //       {
  //         label: "movementEmission",
  //         keys: [],
  //         value: [
  //           {
  //             sheet: "movement_base_glide_categ",
  //             tableData: scenario?.results.movement.by_category,
  //             keyName: "base_to_glide",
  //           },
  //           {
  //             sheet: "movement_base_glide_byPack",
  //             tableData: scenario?.results.movement.by_pack_type,
  //             keyName: "base_to_glide",
  //           },
  //         ],
  //       },
  //       {
  //         label: "timeEvolution",
  //         keys: ["base_to_glide_change"],
  //         value: [
  //           {
  //             sheet: "time_categ_per_litre",
  //             tableData: scenario?.results.category.overview.per_litre,
  //           },
  //           {
  //             sheet: "time_categ_absol",
  //             tableData: scenario?.results.category.overview.absolute,
  //           },
  //           {
  //             sheet: "time_byPack_per_litre",
  //             tableData: scenario?.results.by_pack.overview.per_litre,
  //           },
  //           {
  //             sheet: "time_byPack_absol",
  //             tableData: scenario?.results.by_pack.overview.absolute,
  //           },
  //         ],
  //       },
  //       {
  //         label: "kpis",
  //         keys: [],
  //         value: [
  //           {
  //             sheet: "kpis_proportion_of_volume",
  //             tableData: scenario?.results.kpi.proportion_of_volume,
  //           },
  //           {
  //             sheet: "kpis_reuse_rate",
  //             tableData: scenario?.results.kpi.reuse_rate,
  //           },
  //           {
  //             sheet: "kpis_total_weight_packaging",
  //             tableData: scenario?.results.kpi.total_weight_packaging,
  //           },
  //           {
  //             sheet: "kpis_total_share_dlz",
  //             tableData: scenario?.results.kpi.total_share_dlz,
  //           },
  //           {
  //             sheet: "kpis_total_weight_sugar",
  //             tableData: scenario?.results.kpi.total_weight_sugar,
  //           },
  //           {
  //             sheet: "kpis_average_sugar_per_litre",
  //             tableData: scenario?.results.kpi.average_sugar_content_per_litre,
  //           },
  //           {
  //             sheet: "kpis_total_energy_usage_ratio",
  //             tableData: scenario?.results.kpi.total_energy_usage_ratio,
  //           },
  //           {
  //             sheet: "kpis_specific_eur",
  //             tableData: scenario?.results.kpi.specific_eur,
  //           },
  //           {
  //             sheet: "kpis_energy_source",
  //             tableData: scenario?.results.kpi.electricity_source,
  //           },
  //           {
  //             sheet: "kpis_heat_source",
  //             tableData: scenario?.results.kpi.heat_source,
  //           },
  //           {
  //             sheet: "kpis_fuel_type_distribution",
  //             tableData: scenario?.results.kpi.fuel_type_distribution,
  //           },
  //         ],
  //       },
  //       {
  //         label: "mekkoUnderlying",
  //         keys: [],
  //         value: [
  //           {
  //             sheet: "mekko_absolute_carbon_emissions",
  //             tableData: scenario?.results.mekko,
  //             keyName: "absolute",
  //           },
  //           {
  //             sheet: "mekko_perLitre_carbon_emissions",
  //             tableData: scenario?.results.mekko,
  //             keyName: "per_litre",
  //           },
  //           {
  //             sheet: "mekko_percent_change_perLitre",
  //             tableData: scenario?.results.mekko,
  //             keyName: "percentage_change_per_litre",
  //           },
  //         ],
  //       },
  //     ]);
  //   }
  // }, [scenario]);

  function handleCsv() {
    let settings = {
      fileName: scenario?.name,
      extraLength: 3,
    };
    xlsx(getExportData(selectedOptions, optionValues), settings);
    setOpen(false);
    setSelectedOptions([]);
  }

  return (
    <>
      <ManageExportPanel
        setSelectedOptions={setSelectedOptions}
        selectedOptions={selectedOptions}
        options={options}
        open={open}
        translationKey="macro"
        setOpen={setOpen}
        handleCsv={handleCsv}
      />
    </>
  );
}
