import "../Scenario.scss";
import { useTranslation } from "react-i18next";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import React, { SyntheticEvent, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  UpdateMacroScenarioViewModel,
  ViewMacroScenarioViewModel,
} from "../../../../../orval/generated/models";
import UpdateDetailsModal from "../../../../../components/common/UpdateDetailsModal/UpdateDetailsModal";
import { useModal } from "../../../../../components/common/Modal";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useSnackbar } from "../../../../../components/common/Notification/showSnackbar";
import { useGlobalLoader } from "../../../../../components/common";
import ThreeDotMenu from "../../../../../components/common/ThreeDotMenu/ThreeDotMenu";
import ConfirmationModal from "../../../../../components/common/ConfirmationModal/ConfirmationModal";
import {
  getGetAllMacroScenariosScenariosMacroGetQueryKey,
  useDeleteScenarioByIdScenariosScenarioIdDelete,
  useDuplicateMacroScenarioScenariosMacroScenarioIdDuplicatePost,
  useUpdateMacroScenarioScenariosMacroScenarioIdPatch,
} from "../../../../../orval/generated/endpoint";

export type MacroSingleScenarioInScenarioListProps = {
  scenario: ViewMacroScenarioViewModel;
};

function MacroSingleScenarioInScenarioList({
  scenario,
}: MacroSingleScenarioInScenarioListProps) {
  const navigate = useNavigate();
  const { t } = useTranslation(["macro", "common"]);
  const { openModal, closeModal } = useModal();
  const showSnackbar = useSnackbar();
  const queryClient = useQueryClient();

  const { showGlobalLoader } = useGlobalLoader();

  const handleViewScenario = (scenarioId: string): void => {
    navigate(`/country-level-analytics/scenario/${scenarioId}`);
  };

  const { mutateAsync: updateMacroScenario } =
    useUpdateMacroScenarioScenariosMacroScenarioIdPatch({
      mutation: {
        onSuccess: () => {
          navigate(`/country-level-analytics/`);
          showSnackbar("Macro scenario updated successfully", "success");
          queryClient.invalidateQueries({
            queryKey: getGetAllMacroScenariosScenariosMacroGetQueryKey(),
          });
          closeModal();
        },
        onError: (error: string | Error | string[] | AxiosError) => {
          showSnackbar(error, "error");
          return false;
        },
      },
    });

  const handleUpdateDetails = async (
    scenarioId: number,
    data: UpdateMacroScenarioViewModel,
  ): Promise<boolean> => {
    try {
      await updateMacroScenario({
        scenarioId,
        data,
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const onHandleUpdateDetails = useCallback(
    (event: Event | SyntheticEvent<Element, Event>) => {
      if (scenario) {
        openModal(
          <UpdateDetailsModal
            open={true}
            scenario={scenario}
            closeModal={closeModal}
            updateScenarioFunction={handleUpdateDetails}
          ></UpdateDetailsModal>,
        );
      }
    },
    [scenario, openModal, closeModal],
  );

  const { mutate: duplicateScenario, isPending: duplicateScenarioIsPending } =
    useDuplicateMacroScenarioScenariosMacroScenarioIdDuplicatePost({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: getGetAllMacroScenariosScenariosMacroGetQueryKey(),
          });
          showSnackbar(t("micro:notifications.scenarioDuplicated"), "success");
          showGlobalLoader(false);
        },
        onError: (error: string | Error | string[] | AxiosError) => {
          showSnackbar(t("micro:errorMessages.errorDuplicating"), "error");
        },
      },
    });

  const onHandleDuplicateScenario = useCallback(
    (event: Event | SyntheticEvent<Element, Event>) => {
      const scenarioId: number = parseInt((event.target as Element).id);
      duplicateScenario({ scenarioId });
    },
    [duplicateScenario],
  );

  const {
    mutate: deleteMacroScenario,
    isPending: deleteMacroScenarioIsPending,
  } = useDeleteScenarioByIdScenariosScenarioIdDelete({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getGetAllMacroScenariosScenariosMacroGetQueryKey(),
        });
        showSnackbar(
          t(
            "micro:microViewAllScenariosPage.deleteScenarioModal.scenarioDeleted",
          ),
          "success",
        );
        showGlobalLoader(false);
        closeModal();
      },
      onError: (error: string | Error | string[] | AxiosError) => {
        showSnackbar(error, "error");
      },
    },
  });

  const onHandleDeleteScenario = useCallback(
    (event: Event | SyntheticEvent<Element, Event>) => {
      openModal(
        <ConfirmationModal
          title={`${t("micro:microViewAllScenariosPage.deleteScenarioModal.title")} ${scenario.name}${t(
            "micro:microViewAllScenariosPage.deleteScenarioModal.questionMark",
          )}`}
          description={t(
            "micro:microViewAllScenariosPage.deleteScenarioModal.description",
          )}
          actionTitle={t("common:actions.delete")}
          confirmAction={() => {
            scenario.id && deleteMacroScenario({ scenarioId: scenario.id });
            closeModal();
          }}
          cancelAction={closeModal}
        />,
      );
    },
    [scenario],
  );

  const macroThreeDotsMenuItems = {
    menuItems: [
      {
        label: t("common:actions.updateDetails"),
        onClick: (event: Event | React.SyntheticEvent<Element, Event>) =>
          onHandleUpdateDetails(event),
        id: scenario.id,
      },
      {
        label: t("common:actions.duplicate"),
        onClick: (event: Event | React.SyntheticEvent<Element, Event>) => {
          onHandleDuplicateScenario(event);
        },
        id: scenario.id,
      },
      {
        label: t("common:actions.delete"),
        onClick: (event: Event | React.SyntheticEvent<Element, Event>) => {
          onHandleDeleteScenario(event);
        },
        id: scenario.id,
      },
    ],
  };

  useEffect(() => {
    deleteMacroScenarioIsPending ||
    duplicateScenarioIsPending ||
    deleteMacroScenarioIsPending
      ? showGlobalLoader(true)
      : showGlobalLoader(false);
  }, [
    deleteMacroScenarioIsPending,
    duplicateScenarioIsPending,
    deleteMacroScenarioIsPending,
  ]);

  return (
    <Box key={scenario.id} data-testid="single-scenario-box">
      <Box
        className="scenario-tile"
        key={scenario.id}
        sx={{ display: "flex", justifyContent: "space-between" }}
        mb={2}
      >
        <Grid item width="100%">
          <Box display={"flex"} alignItems={"center"}>
            <Typography
              className={"header-H3 scenario-title"}
              onClick={() => {
                handleViewScenario(scenario.id!.toString());
              }}
              role="button"
              tabIndex={0}
            >
              {scenario.name}
            </Typography>
            <Box
              tabIndex={0}
              marginLeft="auto"
              display="flex"
              alignItems="center"
            >
              <Typography>
                {scenario?.inputs?.years?.base_year !== null &&
                scenario?.inputs?.years?.target_year !== null
                  ? `${scenario?.inputs?.years?.base_year} - ${scenario?.inputs?.years?.target_year}`
                  : ""}
              </Typography>
              {scenario?.inputs?.years?.base_year !== null &&
                scenario?.inputs?.years?.target_year !== null && (
                  <FiberManualRecordIcon
                    sx={{
                      m: 1,
                      fontSize: "5px",
                      color: "black",
                    }}
                  />
                )}
              <Tooltip title={t("common:labels.updatedDate")}>
                <Box display="flex" alignItems="center">
                  <UpdateIcon sx={{ fontSize: "1em", margin: "0 0.2em 0 0" }} />
                  <Typography>
                    {new Date(scenario.updated_at || "").toLocaleDateString()}
                  </Typography>
                </Box>
              </Tooltip>
              <Box>
                <ThreeDotMenu
                  menuItems={macroThreeDotsMenuItems.menuItems}
                ></ThreeDotMenu>
              </Box>
            </Box>
          </Box>
          {scenario.description ? (
            <Box mt={2} pr={2} overflow="hidden">
              <Typography variant={"body1"}>{scenario?.description}</Typography>
            </Box>
          ) : null}
        </Grid>
      </Box>
    </Box>
  );
}

export default MacroSingleScenarioInScenarioList;
