import { Navigate, RouteObject } from "react-router-dom";
import { HomeRoutes } from "../../features/home";
import { MicroToolRoutes } from "../../features/micro-tool";
import { MacroToolRoutes } from "../../features/macro-tool";
import { ToolConfigurationRoutes } from "../../features/tool-configuration";
import { PolicyPageRoutes } from "../../features/policy-pages";
import { SupportingPagesRoutes } from "../../features/supporting-pages";
import { DataManagementRoutes } from "../../features/data-management/navigation";
import { AuthNavigationRoutes } from "../../features/auth/navigation/routes";
import ProtectedRoutes from "./protectedRoutes";
import { RootPage } from "components/sections/RootPage/RootPage";

const AppRoutes: RouteObject[] = [
  {
    path: "/",
    children: [
      { index: true, element: <Navigate to={"/auth"} /> },
      { path: "*", element: <Navigate to={"/auth"} /> },
    ],
  },
  {
    path: "/",
    element: (
      <ProtectedRoutes>
        <RootPage />
      </ProtectedRoutes>
    ),
    children: [
      HomeRoutes,
      MicroToolRoutes,
      DataManagementRoutes,
      ToolConfigurationRoutes,
      PolicyPageRoutes,
    ],
  },
  MacroToolRoutes,
  AuthNavigationRoutes,
  SupportingPagesRoutes,
];

export default AppRoutes;
