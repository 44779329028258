import { RouteObject } from "react-router-dom";
import HomePage from "../routes/HomePage";
import { HomeRoutesConfig } from "./config";
import ProtectedRoutes from "../../../core/navigation/protectedRoutes";
import { RootPage } from "components/sections/RootPage/RootPage";

export const HomeRoutes: RouteObject = {
  path: HomeRoutesConfig.homePage,
  index: true,
  element: <HomePage />,
};
