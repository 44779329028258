import { MRT_ColumnDef } from "material-react-table";
import i18next from "i18next";
import { BaseInputConfigs } from "../../../../constants";
import { formatNumber } from "../../../../utils/formatNumber/formatNumber";
import {
  MekkoBaseGlideScenario,
  MekkoResult,
} from "../../../../orval/generated/models";
import { OptionalMetricsTableResultsFilterDataType } from "../MacroOptionalMetricsTable/Types";
import { ProductConfigurationsMacro } from "../../../micro-tool/components/MicroAdjustmentsTable/types";

export type TableData = {
  columns: MRT_ColumnDef<any>[];
  data: RowData[];
};

export type RowData = {
  category: string;
  base_input?: string;
  absolute: string;
  per_litre: string;
};

const pillarOrder: { [key: string]: number } = {
  Ingredients: 1,
  Packaging: 2,
  Manufacturing: 3,
  Logistics: 4,
  CDE: 5,
  Total: 6,
};

function sortByPillarThenBySubPillar(a: RowData, b: RowData): number {
  if (a.base_input === b.base_input) {
    return a.category.localeCompare(b.category);
  }
  return pillarOrder[a.base_input!] - pillarOrder[b.base_input!];
}

export function generateTableData(
  data: MekkoResult,
  selectedYear: keyof MekkoBaseGlideScenario,
): TableData {
  const columns = getTableColumns();
  const categoryData = getTableData(data, selectedYear);
  const tableData: TableData = {
    columns: columns,
    data: categoryData,
  };
  return tableData;
}

function getTableColumns() {
  return [
    {
      accessorKey: "base_input",
      header: "",
      id: "base_input",
      size: 50,
      maxSize: 100,
      grow: false,
    },
    {
      accessorKey: "category",
      header: "",
      id: "category",
      size: 200,
      maxSize: 200,
      grow: false,
    },
    {
      accessorKey: "absolute",
      header: i18next.t(`macro:mekkoAnalysis.tableTitle.absolute`),
      id: "absolute",
      size: 200,
      maxSize: 200,
      grow: false,
    },
    {
      accessorKey: "per_litre",
      header: i18next.t(`macro:mekkoAnalysis.tableTitle.perLitre`),
      id: "per_litre",
      size: 200,
      maxSize: 200,
      grow: false,
    },
  ];
}

function getTableData(
  data: MekkoResult,
  selectedYear: keyof MekkoBaseGlideScenario,
): RowData[] {
  const tableDataArray: RowData[] = [];
  Object.entries(data.by_pillar).forEach(([pillar, pillarData]) => {
    Object.entries(pillarData).forEach(([category, subPillarYearValue]) => {
      const baseInput = BaseInputConfigs.find(
        (config) => config.key === pillar.toLowerCase(),
      );
      if (baseInput) {
        tableDataArray.push({
          category: category,
          base_input: i18next.t(baseInput?.label),
          absolute: formatNumber(
            subPillarYearValue[selectedYear]?.absolute,
            OptionalMetricsTableResultsFilterDataType.ABSOLUTE,
          ),
          per_litre: formatNumber(
            subPillarYearValue[selectedYear]?.per_litre,
            ProductConfigurationsMacro.MEKKO,
          ),
        });
      }
    });
  });
  const totalYearValue = data.total[selectedYear];
  tableDataArray.push({
    category: "",
    base_input: i18next.t("micro:resultsSection.table.total"),
    absolute: formatNumber(
      totalYearValue?.absolute,
      OptionalMetricsTableResultsFilterDataType.ABSOLUTE,
    ),
    per_litre: formatNumber(
      totalYearValue?.per_litre,
      ProductConfigurationsMacro.MEKKO,
    ),
  });

  return tableDataArray.sort(sortByPillarThenBySubPillar);
}
