import { useCallback, useContext } from "react";
import { NotificationsContext, SnackbarStates } from "./";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

export function useSnackbar() {
  const { setNotificationState } = useContext(NotificationsContext);
  const { t } = useTranslation();

  const showSnackbar = useCallback(
    (
      message: string | Error | string[] | AxiosError,
      state: SnackbarStates,
    ) => {
      let finalMessage: string;

      if (Array.isArray(message)) {
        finalMessage = "common:messages.unexpectedError";
      } else if (message instanceof AxiosError) {
        finalMessage = message.response?.data?.detail || message.message;
      } else if (message instanceof Error) {
        finalMessage = message.message;
      } else {
        finalMessage = message;
      }

      if (finalMessage === "Network Error") {
        finalMessage =
          "toolConfiguration:userManagementPage.upsertUser.networkError";
      }
      setNotificationState({
        open: true,
        message: t(finalMessage),
        state: state,
      });
    },
    [setNotificationState, t],
  );

  return showSnackbar;
}
