import { ProductConfigurationsMicro } from "../MicroAdjustmentsTable/types";
import i18next from "i18next";
import { revertTextFormat } from "../../../../utils/formatText";

function getTooltipTitle(dataStructureKey: string, value: string) {
  switch (dataStructureKey) {
    case ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS:
      return i18next.t(`micro:toolTip.ef_for_selected_ingredient`);

    case ProductConfigurationsMicro.INGREDIENTS:
      return i18next.t(`micro:toolTip.weight_for_selected_ingredient`);
    case ProductConfigurationsMicro.OTHER_PACKAGING_PRIMARY:
    case ProductConfigurationsMicro.OTHER_PACKAGING_SECONDARY:
    case ProductConfigurationsMicro.OTHER_PACKAGING_TERTIARY:
      if (value.includes("recycle"))
        return i18next.t(
          `micro:toolTip.${value.includes("EF") ? "ef_" : ""}other_packaging_recycled`,
        );

      if (value.includes("weight"))
        return i18next.t(`micro:toolTip.other_packaging_weight`);

      return i18next.t(
        `micro:toolTip.${value.includes("EF") ? "ef_" : ""}other_packaging_virgin`,
      );

    default:
      return i18next.t(`micro:toolTip.${revertTextFormat(value as string)}`);
  }
}

export default getTooltipTitle;
