import { BannerConfigActionType, BannerConfigState } from "./types";

export default function BannerReducerHelper(
  state: BannerConfigState,
  action: BannerConfigActionType,
): BannerConfigState {
  switch (action.type) {
    case "SET_BANNER_TYPE":
      return { ...state, selectedBannerType: action.payload };
    case "SET_SELECTED_COUNTRIES":
      return { ...state, selectedCountries: action.payload };
    case "SET_START_DATE":
      return { ...state, startDate: action.payload };
    case "SET_END_DATE":
      return { ...state, endDate: action.payload };
    case "SET_PREVIEW_BANNER":
      return { ...state, previewBanner: action.payload };
    case "SET_SELECTED_COUNTRIES_DISPLAY_NAMES":
      return { ...state, selectedCountriesDisplayNames: action.payload };
    case "SET_ALIGNMENT":
      return { ...state, alignment: action.payload };
    case "SET_FORM_CHANGES_MADE":
      return { ...state, formChangesMade: action.payload };
    case "SET_IS_ALERT_AVAILABLE_TO_TURN_ON":
      return { ...state, isAlertAvailableToTurnOn: action.payload };
    case "SET_HAS_FETCHED_ALERT_DETAILS":
      return { ...state, hasFetchedAlertDetails: action.payload };
    case "SET_ALL_FIELDS_DISABLED":
      return { ...state, allFieldsDisabled: action.payload };
    case "SET_ALERT_REQUEST":
      return { ...state, alertRequest: action.payload };
    case "SET_INITIAL_ALERT_REQUEST":
      return { ...state, initialAlertRequest: action.payload };
    default:
      return state;
  }
}
