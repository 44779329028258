import { MacroResults } from "../../../../../orval/generated/models";
import {
  TableResultsFilterDataType,
  TableResultsType,
} from "../../ScenarioResults/types";
import { timeEvolutionTableData } from "../../../types";
import { formatText } from "../../../../../utils/formatText";
import { formatNumberOrEmpty } from "../../../../../utils/formatNumber/formatNumber";
import getSortedCategoryKeys from "./getSortedCategoryKeys";

function getTableData(
  result: MacroResults,
  type: TableResultsFilterDataType,
  sectionType: TableResultsType.BY_CATEGORY | TableResultsType.BY_PACK_TYPE,
): timeEvolutionTableData {
  if (!result) {
    return [];
  }

  const categoryKeys = getSortedCategoryKeys(result[sectionType]);

  const data = categoryKeys.map((category: string) => {
    const overview_category = result[sectionType][category][type].total;
    return {
      category: formatText(category),
      base_to_glide_change: `${formatNumberOrEmpty(overview_category.base_to_glide_pct_chg, "percentage")} % `,
    };
  });

  data.push({
    category: formatText("Total"),
    base_to_glide_change: `${formatNumberOrEmpty(result.by_pillar.total[type].base_to_glide_pct_chg, "percentage")} % `,
  });

  return data;
}

export default getTableData;
