import { HomePageOption } from "../models";
import { MicroToolRoutesConfig } from "../../micro-tool";
import { MacroToolRoutesConfig } from "../../macro-tool";
import { ToolConfigurationRoutesConfig } from "../../tool-configuration";
import { Icons } from "../../../components/common/CustomIcon";
import { DataManagementRoutesConfig } from "../../data-management/navigation";
import { Permission } from "../../../core/navigation/permission";

export const HomePageOptions: HomePageOption[] = [
  {
    icon: Icons.TOGGLE,
    title: "options.microTool.title",
    actionButtonLabel: "options.microTool.actionButtonLabel",
    description: "options.microTool.description",
    redirectURL: MicroToolRoutesConfig.productLevelAnalytics,
    permission: Permission.ProductLevelAnalytics,
  },
  {
    icon: Icons.GROW_RESPONSE,
    title: "options.macroTool.title",
    actionButtonLabel: "options.macroTool.actionButtonLabel",
    description: "options.macroTool.description",
    redirectURL: MacroToolRoutesConfig.countryLevelAnalytics,
    permission: Permission.CountryLevelAnalytics,
  },
  {
    icon: Icons.DATABASE,
    title: "options.dataManagement.title",
    actionButtonLabel: "options.dataManagement.actionButtonLabel",
    description: "options.dataManagement.description",
    redirectURL: DataManagementRoutesConfig.dataManagementPage,
    permission: Permission.DataManagement,
  },
  {
    icon: Icons.SETTINGS,
    title: "options.toolConfiguration.title",
    actionButtonLabel: "options.toolConfiguration.actionButtonLabel",
    description: "options.toolConfiguration.description",
    redirectURL: ToolConfigurationRoutesConfig.toolConfigurationPage,
    permission: Permission.ToolConfiguration,
  },
];
