import {
  SKUAdjustmentAccordionDetails,
  SKUField,
} from "../../../../orval/generated/models";
import {
  MicroUnits,
  ProductConfigurationsMicro,
  ProductsEditableColumnConfigItem,
} from "../MicroAdjustmentsTable/types";

export function generateTableMetric(
  accordion: SKUAdjustmentAccordionDetails,
  filteredMetricsData: string[] | undefined,
  t: (key: string) => string,
): ProductsEditableColumnConfigItem[] {
  switch (accordion.accordion) {
    case ProductConfigurationsMicro.SERVING_SIZE:
      return (
        accordion.fields?.map((field) => ({
          field_id: field.field_id,
          field_key: field.field,
          label: t("micro:servingSizeSection.table.servingSize"),
          defaultUnit: MicroUnits.OVERRIDE,
        })) || []
      );

    case ProductConfigurationsMicro.MANUFACTURING:
      return (
        accordion.fields?.map((field) => ({
          field_id: field.field_id,
          field_key: field.field,
          label: t(`micro:manufacturingSection.table.${field.field}Eur`),
          defaultUnit: MicroUnits.PERCENTAGE,
        })) || []
      );

    case ProductConfigurationsMicro.LOGISTICS:
      return (
        accordion.fields?.map((field) => ({
          field_id: field.field_id,
          field_key: field.field,
          label: t(`micro:logisticsSection.table.${field.field}`),
          defaultUnit: MicroUnits.OVERRIDE,
        })) || []
      );

    case ProductConfigurationsMicro.BODY_AND_LID_PACKAGING:
      return (
        accordion.fields?.map((field) => ({
          field_id: field.field_id,
          field_key: field.field,
          label: t(`micro:packagingSection.table.${field.field}`),
          defaultUnit: MicroUnits.OVERRIDE,
        })) || []
      );

    case ProductConfigurationsMicro.BODY_AND_LID_EMISSION_FACTORS:
      return (
        accordion.fields?.map((field) => ({
          field_id: field.field_id,
          field_key: field.field,
          label: t(
            `micro:bodyAndLidEmissionFactorsSection.table.${field.field}`,
          ),
          defaultUnit: MicroUnits.OVERRIDE,
        })) || []
      );

    default:
      if (filteredMetricsData) {
        return (
          filteredMetricsData.map((metric) => {
            let metricObject = (accordion.fields as SKUField[]).find(
              (field) => field.field === metric,
            ) as { field_id: number; field: string };
            return {
              field_id: metricObject.field_id,
              field_key: metricObject.field,
              label: `${t(`common:${metricObject.field}`)}${
                accordion.accordion === ProductConfigurationsMicro.INGREDIENTS
                  ? " per litre (g / litre)"
                  : accordion.accordion ===
                      ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS
                    ? " EF (g CO₂e / g ingredient)"
                    : ""
              }`,
              defaultUnit: MicroUnits.PERCENTAGE,
            };
          }) || []
        );
      } else {
        return [];
      }
  }
}
