import {
  CreateMicroAdjustment,
  EditMicroAdjustment,
  Product,
  SKUAdjustmentAccordionDetails,
  SKUField,
  UserDataTypes,
} from "../../../../../orval/generated/models";
import { ProductColumnData } from "../../../types";
import { useTranslation } from "react-i18next";
import {
  useCreateMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPost,
  getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey,
  useEditMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatch,
  useDeleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete,
} from "../../../../../orval/generated/endpoint";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { ProductConfigurationsMicro } from "../../MicroAdjustmentsTable/types";
import { generateTableMetric } from "../generateMetrics";
import generateConfig from "../generateConfig";
import { Box, Typography } from "@mui/material";
import MicroCustomAccordion from "../MicroCustomAccordion/MicroCustomAccordion";
import SelectDropdown from "../../../../../components/common/SelectDropdown/SelectDropdown";
import { useSnackbar } from "../../../../../components/common/Notification/showSnackbar";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";
import { useGlobalLoader } from "../../../../../components/common";
import { deepClone } from "../../../../../utils";
import { AppConstant } from "../../../../../constants";
import getTableColumns from "../../HelperFunctions/getTableColumns";
import getProductsEditableTableData from "../../MicroAdjustmentsTable/HelperFunctions/getProductsEditableTableData";
import { MicroAdjustmentsTable } from "../../MicroAdjustmentsTable/MicroAdjustmentsTable";

const RenderAccordions = (
  accordionDetails: SKUAdjustmentAccordionDetails,
  productColumnData: ProductColumnData[] | undefined,
  products: Product[],
  isOtherPackagingEmissionFactors?: Boolean,
  parentAccordion?: string,
  isSubAccordion?: boolean,
) => {
  const { t } = useTranslation(["micro", "common"]);
  const showSnackbar = useSnackbar();
  const { showGlobalLoader } = useGlobalLoader();
  const queryClient = useQueryClient();
  const location = useLocation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { id: urlScenarioId } = useParams<{ id: string }>();
  const getInitialScenarioId = () => {
    return urlScenarioId || location.state?.id;
  };

  const {
    mutateAsync: createMicroAdjustments,
    isPending: createMicroAdjustmentsIsPending,
  } = useCreateMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPost({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
            getInitialScenarioId(),
          ),
        });
        showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
      },
      onError: (error: any) => {
        showSnackbar(t("errorMessages.errorUpdating"), "error");
        console.warn(error);
        return error;
      },
    },
  });

  const {
    mutateAsync: editMicroAdjustments,
    isPending: editMicroAdjustmentsIsPending,
  } = useEditMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatch({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
            getInitialScenarioId(),
          ),
        });
        showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
      },
      onError: (error: any) => {
        showSnackbar(t("errorMessages.errorUpdating"), "error");
        console.warn(error);
        return error;
      },
    },
  });

  const {
    mutateAsync: deleteMicroAdjustments,
    isPending: deleteMicroAdjustmentIsPendings,
  } = useDeleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete({
    mutation: {
      onSuccess: (data: unknown) => {
        queryClient.invalidateQueries({
          queryKey: getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
            getInitialScenarioId(),
          ),
        });
        showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
      },
      onError: (error: any) => {
        showSnackbar(t("errorMessages.errorUpdating"), "error");
        console.warn(error);
        return error;
      },
    },
  });

  const getFieldsList = useCallback(
    () => accordionDetails.fields?.map((field) => field.field),
    [accordionDetails.fields],
  );

  //This function returns all adjustments for a single product, including the ones inside subAccordions
  const getFlattenedAdjustments = useCallback(
    (product_guid: string): SKUAdjustmentAccordionDetails[] => {
      const adjustments = products.find(
        (pro) => pro.guid === product_guid,
      )?.adjustments;
      let flattenedAdjustments = adjustments ? [...adjustments] : [];
      adjustments?.forEach((adj) => {
        if (adj.sub_accordions?.length) {
          flattenedAdjustments = [
            ...flattenedAdjustments,
            ...adj.sub_accordions,
          ];
        }
      });
      return flattenedAdjustments;
    },
    [products],
  );

  const adjustedFields = useMemo(() => {
    const itemsList = getFieldsList();

    return itemsList?.filter((item: string) =>
      accordionDetails.fields?.find(
        (field) => field.adjustment_id && field.field === item,
      )
        ? true
        : false,
    );
  }, [accordionDetails, getFieldsList]);

  const [selected, setSelected] = useState<string[] | undefined>(
    adjustedFields,
  );

  //Initially setting 'selected' state to adjustedFields which are the fileds that's already adjusted (from BE)
  useEffect(() => {
    if (adjustedFields?.length) {
      setSelected(adjustedFields);
    }
  }, [adjustedFields]);

  const selectedMetrics = useMemo(
    () => generateTableMetric(accordionDetails, selected, t),
    [accordionDetails, selected, t],
  );

  const columns = getTableColumns(
    productColumnData as ProductColumnData[],
    selectedMetrics,
    340,
    true,
    accordionDetails.accordion !== ProductConfigurationsMicro.SERVING_SIZE,
    isEditing,
  );

  const tableData = useMemo(
    () =>
      getProductsEditableTableData(
        accordionDetails.accordion,
        selectedMetrics,
        productColumnData,
        products,
        generateConfig(accordionDetails, t),
        false,
        parentAccordion,
      ),
    [
      accordionDetails,
      selectedMetrics,
      productColumnData,
      products,
      t,
      parentAccordion,
    ],
  );

  useEffect(() => {
    createMicroAdjustmentsIsPending ||
    editMicroAdjustmentsIsPending ||
    deleteMicroAdjustmentIsPendings
      ? showGlobalLoader(true)
      : showGlobalLoader(false);
  }, [
    showGlobalLoader,
    createMicroAdjustmentsIsPending,
    editMicroAdjustmentsIsPending,
    deleteMicroAdjustmentIsPendings,
  ]);

  const handleSave = async (
    field_id: number,
    updatedValues: any,
    rowType: UserDataTypes,
  ) => {
    const _updated_values = deepClone(updatedValues);
    delete _updated_values.metric; //TODO: find a way to get rid of this key entirly

    const newAdjustments: CreateMicroAdjustment[] = [];
    const updatedAdjustments: EditMicroAdjustment[] = [];
    const deletedAdjustments: number[] = [];

    Object.keys(_updated_values).forEach((product_guid) => {
      const cleanedProduct_guid = product_guid.replace("Product ", "");
      let flattenedAdjustment = getFlattenedAdjustments(cleanedProduct_guid);
      //beacuse flattened adjustments array may contain two records with the same accordion name
      const adjustment = flattenedAdjustment.filter(
        (adj) => adj.accordion === accordionDetails.accordion,
      );
      let fields: SKUField[] = [];

      adjustment.forEach((adj) => {
        fields = [...fields, ...(adj.fields as SKUField[])];
      });

      let field = fields.find((f) => f.field_id === field_id);

      if (field) {
        if (!field.adjustment_id) {
          if (_updated_values[product_guid] !== AppConstant.emptyCell) {
            const newAdjustment = {
              field_id,
              product_guid: cleanedProduct_guid,
              action: rowType,
              adjusted_value: Number(_updated_values[product_guid]),
            };

            newAdjustments.push(newAdjustment);
          }
        } else {
          if (_updated_values[product_guid] !== AppConstant.emptyCell) {
            const updatedAdjustment = {
              id: field.adjustment_id,
              action: rowType,
              adjusted_value: Number(_updated_values[product_guid]),
            };

            updatedAdjustments.push(updatedAdjustment);
          } else {
            deletedAdjustments.push(field.adjustment_id);
          }
        }
      }
    });

    if (newAdjustments.length) {
      try {
        await createMicroAdjustments({
          scenarioId: getInitialScenarioId(),
          data: newAdjustments,
        });
      } catch (e) {
        console.log(e);
      }
    }

    if (deletedAdjustments.length) {
      try {
        await deleteMicroAdjustments({
          scenarioId: getInitialScenarioId(),
          data: deletedAdjustments,
        });
      } catch (e) {
        console.log(e);
      }
    }

    if (updatedAdjustments.length) {
      try {
        await editMicroAdjustments({
          scenarioId: getInitialScenarioId(),
          data: updatedAdjustments,
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleClearAdjustmentsForRow = async (
    adjustmentsIds: number[] | null,
  ) => {
    if (adjustmentsIds) {
      try {
        await deleteMicroAdjustments({
          scenarioId: getInitialScenarioId(),
          data: adjustmentsIds,
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      showSnackbar(t("errorMessages.errorUpdating"), "error");
    }
  };

  const handleRemoveItem = async (removedItems: any[]) => {
    let removedAdjustments: SKUField[] = [];

    removedItems.forEach((item) => {
      const removedItem = accordionDetails.fields?.find(
        (field) => field.field === item,
      );

      products.forEach((product) => {
        const flattenedAdjustments = getFlattenedAdjustments(product.guid);
        flattenedAdjustments.forEach((adj) => {
          adj.fields?.forEach((field) => {
            if (field.field_id === removedItem?.field_id && field.adjustment_id)
              removedAdjustments.push(field);
          });
        });
      });
    });

    if (removedAdjustments.length) {
      try {
        await deleteMicroAdjustments({
          scenarioId: getInitialScenarioId(),
          data: removedAdjustments.map((adj) => adj.adjustment_id as number),
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  switch (accordionDetails.accordion) {
    case ProductConfigurationsMicro.SERVING_SIZE:
    case ProductConfigurationsMicro.MANUFACTURING:
    case ProductConfigurationsMicro.LOGISTICS:
    case ProductConfigurationsMicro.BODY_AND_LID_PACKAGING:
    case ProductConfigurationsMicro.BODY_AND_LID_EMISSION_FACTORS:
      return (
        <>
          <MicroAdjustmentsTable
            columns={columns}
            data={tableData}
            onValueUpdate={handleSave}
            dataStructureKey={accordionDetails.accordion}
            metrics={selectedMetrics}
            setIsEditing={setIsEditing}
            handleClearAdjustmentsForRow={handleClearAdjustmentsForRow}
            isSubAccordionTable={!!isSubAccordion}
          ></MicroAdjustmentsTable>
        </>
      );

    default:
      if (
        accordionDetails.sub_accordions &&
        accordionDetails.sub_accordions.length
      )
        return (
          <>
            {accordionDetails.sub_accordions.map(
              (sub_accordion: SKUAdjustmentAccordionDetails): ReactNode =>
                products && (
                  <Box key={sub_accordion.accordion}>
                    <MicroCustomAccordion
                      title={sub_accordion.accordion}
                      details={RenderAccordions(
                        sub_accordion,
                        productColumnData,
                        products,
                        isOtherPackagingEmissionFactors,
                        accordionDetails.accordion,
                        true,
                      )}
                      accordion={sub_accordion}
                    />
                  </Box>
                ),
            )}
          </>
        );

      return (
        <Box sx={{ display: "flex", flexDirection: "column" }} mt={2}>
          <Box sx={{ maxWidth: "15em" }}>
            <SelectDropdown
              listItems={getFieldsList() || []}
              onSave={setSelected}
              savedSelectedItems={selected || []}
              title={t("ingredientsSection.buttonTitle")}
              onRemove={handleRemoveItem}
              selectAll={true}
              translate={true}
            ></SelectDropdown>
          </Box>

          {products?.length &&
          tableData?.length &&
          columns?.length &&
          selected?.length ? (
            <Box mt={1}>
              <MicroAdjustmentsTable
                columns={columns}
                data={tableData}
                onValueUpdate={handleSave}
                dataStructureKey={accordionDetails.accordion}
                metrics={selectedMetrics}
                setIsEditing={setIsEditing}
                handleClearAdjustmentsForRow={handleClearAdjustmentsForRow}
                isSubAccordionTable={!!isSubAccordion}
              ></MicroAdjustmentsTable>
            </Box>
          ) : (
            <Box
              sx={{
                border: "1px dashed #cbcbcb",
                padding: "40px",
                textAlign: "center",
                maxWidth: "95vw",
                marginTop: 2,
              }}
            >
              <Typography>{t("adjustmentsSection.noAdjustments")}</Typography>
            </Box>
          )}
        </Box>
      );
  }
};

export default RenderAccordions;
