import { Box, Tooltip, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { MRT_Row, MRT_RowData, MRT_TableInstance } from "material-react-table";
import { AppConstant } from "../../../../../constants";

export type MicroAdjustmentsTableRowActionsProps = {
  row: MRT_Row<MRT_RowData>;
  table: MRT_TableInstance<MRT_RowData>;
  setIsEditing: ((value: boolean) => void) | undefined;
  handleClearAdjustmentsForRow: Function;
};

const MicroAdjustmentsTableRowActions = ({
  row,
  table,
  setIsEditing,
  handleClearAdjustmentsForRow,
}: MicroAdjustmentsTableRowActionsProps) => {
  const originalRowsEntries = Object.entries(row.original);
  const adjustmentsPresent = originalRowsEntries.some(
    ([key, value]) =>
      key.startsWith("Product") && value !== AppConstant.emptyCell,
  );

  const getAdustmentsIds = (): string[] | null => {
    const ids = JSON.parse(row.original.adjustmentsIds);

    if (ids && ids.length) {
      return ids;
    } else {
      return null;
    }
  };

  return (
    <>
      {row ? (
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                table.setEditingRow(row);
                if (setIsEditing) {
                  setIsEditing(true);
                }
              }}
              data-testid="edit-btn"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Clear current adjustments">
            <span>
              <IconButton
                disabled={!adjustmentsPresent}
                onClick={() => handleClearAdjustmentsForRow(getAdustmentsIds())}
                data-testid="clear-btn"
              >
                <DeleteSweepIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      ) : null}
    </>
  );
};

export default MicroAdjustmentsTableRowActions;
