import { Box, Button, Typography } from "@mui/material";
import { ToolConfigurationRoutesConfig } from "../../navigation/config";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SearchBar, useGlobalLoader } from "../../../../components/common";
import { useCallback, useEffect, useMemo, useState } from "react";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import UserManagementTable, {
  Pagination,
  paginationOptions,
} from "./UserManagementTable/UserManagementTable";
import { debounce } from "lodash";
import { useModal } from "../../../../components/common/Modal";
import { UpsertUserModal } from "./UpsertUserModal/UpsertUserModal";
import Sort from "../../../../components/common/Sort/Sort";
import { SortConfig, SortValues } from "../../../../utils/SortConfig";
import {
  CountryViewModel,
  MemberDetails,
  MemberPermissions,
  PermissionItem,
} from "../../../../orval/generated/models";
import { useSnackbar } from "../../../../components/common/Notification/showSnackbar";
import { deepClone } from "utils";
import AddMultipleUsersModal from "./AddMultipleUsersModal/AddMultipleUsersModal";
import ThreeDotMenu, {
  ThreeDotMenuItem,
} from "../../../../components/common/ThreeDotMenu/ThreeDotMenu";
import { withCustomAxios } from "../../../../orval/mutator/custom-instance";
import getFormattedDateForDownloads from "../../../../utils/getFormattedDateForDownloads";
import downloadBlob from "../../../../utils/downloadBlob/downloadBlob";
import { MRT_RowSelectionState } from "material-react-table";
import EditMultipleUsersModal from "./EditMultipleUsersModal/EditMultipleUsersModal";
import { flattenAccess } from "./UserManagementHelperFunctions";
import {
  useGetAllAccordionsAccordionsGet,
  useGetAllFunctionalAreasUserManagementFunctionalAreasGet,
  useGetAllMembersMembersGet,
  useGetMemberCountriesMembersCountriesGet,
} from "orval/generated/endpoint";

const defaultSortValue = SortValues.DATE_UPDATED_DESCENDING;
const paginationInitialState = {
  pageIndex: 0,
  pageSize: paginationOptions[0],
};

type AccessKey = keyof (PermissionItem | CountryViewModel);

const UserManagementPage = () => {
  const { t } = useTranslation(["toolConfiguration", "common"]);
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState("");
  const { showGlobalLoader } = useGlobalLoader();
  const { openModal } = useModal();
  const [pagination, setPagination] = useState<Pagination>(
    paginationInitialState,
  );
  const [sortValue, setSortValue] = useState<SortValues>(defaultSortValue);
  const showSnackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [sortedData, setSortedData] = useState<MemberDetails[]>([]);
  const [functionalAccessFilters, setFunctionalAccessFilters] = useState<
    string[]
  >([]);
  const [countryAccessFilters, setCountryAccessFilters] = useState<string[]>(
    [],
  );
  const [adjustmentAccessFilters, setAdjustmentAccessFilters] = useState<
    string[]
  >([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);

  const handleDownloadAllUserData = async () => {
    showGlobalLoader(true);
    try {
      const response = await withCustomAxios({
        url: `/members/download`,
        method: "GET",
        responseType: "blob",
      });

      const fileName = `PEF-Users-${getFormattedDateForDownloads()}.xlsx`;

      downloadBlob(response as unknown as BlobPart, fileName);

      showSnackbar(
        t(
          "toolConfiguration:userManagementPage.threeDotsMenu.downloadUserDataSuccess",
        ),
        "success",
      );
    } catch (error) {
      console.warn(error);
      showSnackbar(
        t(
          "toolConfiguration:userManagementPage.threeDotsMenu.downloadUserDataError",
        ),
        "error",
      );
    } finally {
      showGlobalLoader(false);
    }
  };

  const {
    data,
    isLoading: dataIsPending,
    isError: errorFetchingMembers,
  } = useGetAllMembersMembersGet();

  const { data: countryData, isPending: countriesIsPending } =
    useGetMemberCountriesMembersCountriesGet();

  const {
    data: functionalAccessData,
    isPending: functionalAccessDataIsPending,
  } = useGetAllFunctionalAreasUserManagementFunctionalAreasGet();

  const {
    data: adjustmentAccessData,
    isPending: adjustmentAccessDataIsPending,
  } = useGetAllAccordionsAccordionsGet();

  const debouncedHandleSearch = useMemo(
    () =>
      debounce((value: string) => {
        setSearchString(value);
      }, 300),
    [],
  );

  const flattenedFunctionalAccess = useMemo(
    () => flattenAccess(functionalAccessData || []),
    [functionalAccessData],
  );
  const flattenedAdjustmentAccess = useMemo(
    () => flattenAccess(adjustmentAccessData || []),
    [adjustmentAccessData],
  );

  const handleFunctionalAccessChange = useCallback(
    (selectedItems: string[]) => {
      setFunctionalAccessFilters(
        selectedItems.map(
          (item) =>
            (flattenedFunctionalAccess as PermissionItem[]).find(
              (access: PermissionItem) => access.name === item,
            )?.name as string,
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flattenedFunctionalAccess],
  );

  const handleAdjustmentAccessChange = useCallback(
    (selectedItems: string[]) => {
      setAdjustmentAccessFilters(
        selectedItems.map(
          (item) =>
            (flattenedAdjustmentAccess as PermissionItem[]).find(
              (access: PermissionItem) => access.name === item,
            )?.name as string,
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flattenedAdjustmentAccess],
  );

  const handleCountryAccessChange = useCallback(
    (selectedItems: string[]) => {
      setCountryAccessFilters(selectedItems);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleOpenUpsertUserModal = (
    isEditing: boolean,
    userInformation?: MemberDetails,
  ) => {
    if (countryData && functionalAccessData && adjustmentAccessData) {
      openModal(
        <UpsertUserModal
          isEditing={isEditing}
          userInformation={userInformation}
          countryData={countryData}
          functionalAccessData={functionalAccessData}
          adjustmentAccessData={adjustmentAccessData}
          flattenedFunctionalAccess={flattenedFunctionalAccess}
          flattenedAdjustmentAccess={flattenedAdjustmentAccess}
        ></UpsertUserModal>,
      );
    } else {
      showSnackbar(
        "toolConfiguration:userManagementPage.editMultipleUsersModalError",
        "error",
      );
    }
  };

  const getFilteredItems = useCallback(
    (
      members: MemberDetails[],
      filters: string[],
      accessType: keyof MemberPermissions,
      accessKey: AccessKey,
    ) => {
      return members.filter((member) =>
        member.permissions[accessType].some((access) =>
          filters.includes(access[accessKey]),
        ),
      );
    },
    [],
  );

  const handleOpenEditMultipleUsersModal = () => {
    if (countryData && functionalAccessData && adjustmentAccessData) {
      openModal(
        <EditMultipleUsersModal
          selectedUsers={selectedUsers}
          countryData={countryData}
          functionalAccessData={functionalAccessData}
          adjustmentAccessData={adjustmentAccessData}
          flattenedFunctionalAccess={flattenedFunctionalAccess}
          flattenedAdjustmentAccess={flattenedAdjustmentAccess}
          setRowSelection={setRowSelection}
        ></EditMultipleUsersModal>,
      );
    } else {
      showSnackbar(
        "toolConfiguration:userManagementPage.editMultipleUsersModalError",
        "error",
      );
    }
  };

  useEffect(() => {
    if (data) {
      if (data.length) {
        let sortedItems: MemberDetails[] = [];

        //search
        const words = searchString
          .replace(",", " ")
          .trim()
          .toLocaleLowerCase()
          .split(" ");
        const members: MemberDetails[] = deepClone(data);

        if (words.length) {
          members.forEach((member) => {
            words.forEach((word) => {
              if (
                `${member.first_name}${member.last_name}${member.email}`
                  .toLocaleLowerCase()
                  .includes(word)
              )
                if (!sortedItems.find((m) => m.id === member.id)) {
                  sortedItems.push(member);
                }
            });
          });
        } else {
          sortedItems = members;
        }

        //sorting
        switch (sortValue) {
          case SortValues.NAME_DESCENDING:
            sortedItems = sortedItems.sort((a, b) =>
              `${b.first_name}${b.last_name}`.localeCompare(
                `${a.first_name}${a.last_name}`,
              ),
            );
            break;
          case SortValues.NAME_ASCENDING:
            sortedItems = sortedItems.sort((a, b) =>
              `${a.first_name}${a.last_name}`.localeCompare(
                `${b.first_name}${b.last_name}`,
              ),
            );
            break;
          case SortValues.DATE_UPDATED_ASCENDING:
            sortedItems = sortedItems.sort((a, b) => {
              const dateA = new Date(a.updated_on);
              const dateB = new Date(b.updated_on);
              return dateA.getTime() - dateB.getTime();
            });
            break;
          default:
            sortedItems = sortedItems.sort((a, b) => {
              const dateA = new Date(a.updated_on);
              const dateB = new Date(b.updated_on);
              return dateB.getTime() - dateA.getTime();
            });
            break;
        }

        //filters
        if (functionalAccessFilters.length) {
          sortedItems = getFilteredItems(
            sortedItems,
            functionalAccessFilters,
            "functional_access",
            "name" as AccessKey,
          );
        }

        if (adjustmentAccessFilters.length) {
          sortedItems = getFilteredItems(
            sortedItems,
            adjustmentAccessFilters,
            "adjustment_access",
            "name" as AccessKey,
          );
        }

        if (countryAccessFilters.length) {
          sortedItems = getFilteredItems(
            sortedItems,
            countryAccessFilters,
            "countries",
            "display_name" as AccessKey,
          );
        }

        setSortedData(sortedItems);
        setPagination((prev) => ({ ...prev, pageIndex: 0 }));
      }
    }
  }, [
    adjustmentAccessFilters,
    countryAccessFilters,
    data,
    functionalAccessFilters,
    getFilteredItems,
    pagination.pageSize,
    searchString,
    sortValue,
  ]);

  useEffect(() => {
    showGlobalLoader(false);
    if (isLoading) {
      showGlobalLoader(true);
    }
  }, [isLoading, showGlobalLoader]);

  useEffect(() => {
    if (errorFetchingMembers) {
      showSnackbar(
        "toolConfiguration:userManagementPage.membersFetchError",
        "error",
      );
    }
  }, [errorFetchingMembers, showSnackbar]);

  useEffect(() => {
    countriesIsPending ||
    functionalAccessDataIsPending ||
    adjustmentAccessDataIsPending ||
    dataIsPending
      ? setIsLoading(true)
      : setIsLoading(false);
  }, [
    countriesIsPending,
    functionalAccessDataIsPending,
    adjustmentAccessDataIsPending,
    dataIsPending,
  ]);

  const handleOpenAddMultipleUsersModal = () => {
    openModal(<AddMultipleUsersModal />);
  };

  const userManagementMenuItems: ThreeDotMenuItem[] = [
    {
      label: t(
        "toolConfiguration:userManagementPage.threeDotsMenu.addMultipleUsers",
      ),
      onClick: handleOpenAddMultipleUsersModal,
    },
    {
      label: t(
        "toolConfiguration:userManagementPage.threeDotsMenu.editMultipleUsers",
      ),
      onClick: handleOpenEditMultipleUsersModal,
      disabled: Array.isArray(selectedUsers) && selectedUsers.length === 0,
    },
    {
      label: t(
        "toolConfiguration:userManagementPage.threeDotsMenu.downloadUserData",
      ),
      onClick: handleDownloadAllUserData,
    },
  ];

  return (
    <Box>
      <Box>
        <Button
          variant="text"
          onClick={() =>
            navigate(`${ToolConfigurationRoutesConfig.toolConfigurationPage}?`)
          }
          startIcon={<ArrowBackIosSharpIcon />}
          sx={{ minWidth: "215px", marginRight: "auto" }}
        >
          {t("backButtonText")}
        </Button>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box minWidth={"350px"}>
            <Typography className={"header-H2"} fontWeight="500">
              {t("userManagementPage.title")}
            </Typography>
          </Box>

          <Box display={"flex"}>
            <Box>
              <Button
                variant="contained"
                size="medium"
                onClick={() => {
                  handleOpenUpsertUserModal(false);
                }}
                startIcon={<AddIcon />}
                sx={{ minWidth: "120px" }}
              >
                {t("userManagementPage.newUserButton")}
              </Button>
            </Box>
            <Box>
              <ThreeDotMenu menuItems={userManagementMenuItems}></ThreeDotMenu>
            </Box>
          </Box>
        </Box>
        <Box mt={4} display={"flex"} gap={2}>
          <SearchBar
            handleSearch={debouncedHandleSearch}
            placeHolder={t("userManagementPage.searchPlaceholder")}
          />
          <Sort<SortValues>
            sortValue={sortValue}
            setSortValue={setSortValue}
            values={SortConfig}
          />
        </Box>
      </Box>
      <Box>
        {data &&
          countryData &&
          functionalAccessData &&
          adjustmentAccessData && (
            <UserManagementTable
              data={sortedData}
              pagination={pagination}
              isLoading={isLoading}
              functionalAccessFilters={functionalAccessFilters}
              handleFunctionalAccessChange={handleFunctionalAccessChange}
              countryAccessFilters={countryAccessFilters}
              handleCountryAccessChange={handleCountryAccessChange}
              adjustmentAccessFilters={adjustmentAccessFilters}
              handleAdjustmentAccessChange={handleAdjustmentAccessChange}
              countryData={countryData}
              functionalAccessData={functionalAccessData}
              adjustmentAccessData={adjustmentAccessData}
              handleOpenUpsertUserModal={handleOpenUpsertUserModal}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              setSelectedUsers={setSelectedUsers}
            ></UserManagementTable>
          )}
      </Box>
    </Box>
  );
};

export default UserManagementPage;
