import { MicroAdjustmentConfigurations } from "../../../../micro-tool/components/MicroEmissionsChart/MicroAdjustmentConfigurations";

const getStackColor = (key: string | undefined) => {
  switch (key?.toLowerCase()) {
    case MicroAdjustmentConfigurations.INGREDIENTS:
      return { backgroundColor: "#773829", textColor: "#ffffff" };
    case MicroAdjustmentConfigurations.PACKAGING:
      return { backgroundColor: "#A7452C", textColor: "#ffffff" };
    case MicroAdjustmentConfigurations.MANUFACTURING:
      return { backgroundColor: "#E45C2B", textColor: "#ffffff" };
    case MicroAdjustmentConfigurations.LOGISTICS:
      return { backgroundColor: "#FDAB8D", textColor: "#000000" };
    case MicroAdjustmentConfigurations.CDE:
      return { backgroundColor: "#FEDACC", textColor: "#000000" };

    default:
      return { backgroundColor: "", textColor: "#000000" };
  }
};
export default getStackColor;
