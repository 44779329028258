import {
  MacroResultsByCategory,
  MacroResultsByPackType,
} from "../../../../../orval/generated/models";

function getSortedCategoryKeys(
  result: MacroResultsByPackType | MacroResultsByCategory,
): string[] {
  return Object.keys(result).sort((a, b) => {
    return a.localeCompare(b);
  });
}

export default getSortedCategoryKeys;
