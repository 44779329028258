import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { ElementType } from "react";
import { useTranslation } from "react-i18next";

export interface Tablist {
  label: string;
  icon?: ElementType;
}

export interface TabProps {
  tabList: Readonly<Tablist[]>;
  value: number;
  setValue: Function;
  removeTranslate?: boolean;
  disabled?: boolean;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function CustomTabs({
  setValue,
  value,
  tabList,
  removeTranslate,
  disabled,
}: TabProps) {
  const { t } = useTranslation();
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Tabs value={value} onChange={handleChange}>
      {tabList.map((tab, index) => {
        const label = removeTranslate ? tab.label : t(tab.label);
        return (
          <Tab
            label={
              tab.icon ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <tab.icon data-testid={`custom-tab-icon-${index}`} />
                  <span>{label}</span>
                </div>
              ) : (
                label
              )
            }
            style={{ textTransform: "inherit" }}
            key={tab.label}
            disabled={disabled}
            {...a11yProps(index)}
          />
        );
      })}
    </Tabs>
  );
}
