import {
  ProductConfigurationsMicro,
  ProductsEditableColumnConfigItem,
  rowValidationType,
  TableData,
  TableRow,
} from "../types";
import { ProductColumnData } from "../../../types";
import {
  Product,
  SKUField,
  SKUFieldAction,
  UserDataTypes,
} from "../../../../../orval/generated/models";
import i18next from "i18next";
import { formatNumber } from "../../../../../utils/formatNumber/formatNumber";

function getProductsEditableTableData(
  dataStructureKey: string,
  metrics: ProductsEditableColumnConfigItem[],
  productColumnData: ProductColumnData[] | undefined,
  products: Product[],
  rowValidationConfig: rowValidationType[],
  useTranslation?: boolean,
  parentAccordion?: string,
): TableData {
  const data: TableData = [];

  metrics.forEach((config: ProductsEditableColumnConfigItem) => {
    const dataObj: TableRow = {
      metric: useTranslation === false ? config.label : i18next.t(config.label),
      dataStructureKey: dataStructureKey,
      subRows: [
        {
          id: "",
          metric: "",
          validationType: "",
          validationIsNonZero: false,
          field_key: config.field_key,
        },
      ],
    };

    let adjustedAction: SKUFieldAction =
      dataStructureKey === ProductConfigurationsMicro.SERVING_SIZE
        ? UserDataTypes.new_value
        : UserDataTypes.percentage;
    let adjustmentsIds: number[] = [];

    productColumnData?.forEach((productColumn: ProductColumnData) => {
      const productData: Product | undefined = products?.find(
        (product: Product) => product.guid === productColumn.guid,
      );

      let flattenedAdjustment = productData?.adjustments
        ? [...productData.adjustments]
        : [];
      productData?.adjustments?.forEach((adj) => {
        if (adj.sub_accordions?.length) {
          flattenedAdjustment = [...flattenedAdjustment, ...adj.sub_accordions];
        }
      });

      const adjustments = flattenedAdjustment.filter(
        (adj) => adj.accordion === dataStructureKey,
      );
      let fields: SKUField[] = [];

      adjustments.forEach((adj) => {
        fields = [...fields, ...(adj.fields as SKUField[])];
      });

      let adjustment = fields.find((f) => f.field_id === config.field_id);

      dataObj[`Product ${productColumn.guid}`] = formatNumber(
        adjustment?.value,
        dataStructureKey,
        parentAccordion,
      );

      if (adjustment?.adjustment_id) {
        adjustedAction = adjustment.action as SKUFieldAction;
        adjustmentsIds.push(adjustment?.adjustment_id);
      }
      dataObj.type = adjustedAction as string;
      dataObj.adjustmentsIds = JSON.stringify(adjustmentsIds);

      dataObj["subRows"][0][`Product ${productColumn.guid}`] = formatNumber(
        adjustment?.adjusted_value,
        dataStructureKey,
        parentAccordion,
      );
      dataObj["subRows"][0]["id"] = "adjusted_value";
      dataObj["subRows"][0]["adjustmentsIds"] = JSON.stringify(adjustmentsIds);

      dataObj["subRows"][0]["metric"] =
        useTranslation === false ? config.label : i18next.t(config.label);
      dataObj["subRows"][0].type = adjustedAction as string;

      const validationType: rowValidationType = rowValidationConfig.find(
        (row: rowValidationType) => {
          return row.field_key === config.field_key;
        },
      )!;

      dataObj["subRows"][0]["validationType"] = validationType?.type;
      dataObj["subRows"][0]["validationIsNonZero"] = validationType?.isNonZero;
      dataObj["subRows"][0]["dataStructureKey"] = dataStructureKey;
    });

    data.push(dataObj);
  });
  return data;
}

export default getProductsEditableTableData;
