import { Box, Grid } from "@mui/material";
import { NoData, SearchBar } from "../../../../components/common";
import SelectDropdown from "../../../../components/common/SelectDropdown/SelectDropdown";
import Sort from "../../../../components/common/Sort/Sort";
import { SortConfig, SortValues } from "../../../../utils/SortConfig";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import MacroAdjustmentRow from "../MacroAdjustmentRow/MacroAdjustmentRow";
import { ViewMacroScenarioViewModel } from "orval/generated/models";

type MacroAdjustmentsPageTableProps = {
  scenario: ViewMacroScenarioViewModel;
};

const MacroAdjustmentsPageTable = ({
  scenario,
}: MacroAdjustmentsPageTableProps) => {
  const { t } = useTranslation("macro");
  const [sortValue, setSortValue] = useState<SortValues>(
    SortValues.DATE_UPDATED_DESCENDING,
  );

  // TODO: replace with real adjustments count
  const [adjustmentsCount, setAdjustmentsCount] = useState(0);

  const handleSearch = () => {
    console.log("Search");
  };
  const handleCountriesTypeChange = () => {
    console.log("Countries Type Change");
  };
  const handleYearSelectionChange = () => {
    console.log("Year Selection Change");
  };
  const MockAdjustments = [
    {
      id: 1,
      name: "Adjustment 1",
      description: "Description 1",
      date: "2021-10-10",
    },
    {
      id: 2,
      name: "Adjustment 2",
      description: "Description 2",
      date: "2021-10-10",
    },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item lg={7} sm={12}>
          <SearchBar
            handleSearch={handleSearch}
            disabled={adjustmentsCount < 1}
          />
        </Grid>

        <Grid item lg sm mt={"4px"}>
          {scenario?.inputs?.countries && (
            <SelectDropdown
              listItems={scenario.inputs?.countries?.map(
                (country) => country.display_name,
              )}
              savedSelectedItems={[]} //TODO: to be changed to selected filters
              onSave={handleCountriesTypeChange}
              title={t("adjustmentsPage.countriesDropdownTitle")}
              compact
              showSearchBar={false}
              selectAll={true}
              disabled={adjustmentsCount < 1}
            ></SelectDropdown>
          )}
        </Grid>

        <Grid item lg sm>
          <Sort<SortValues>
            sortValue={sortValue}
            setSortValue={setSortValue}
            values={SortConfig}
            disabled={adjustmentsCount < 1}
          />
        </Grid>
      </Grid>
      {adjustmentsCount > 0 ? (
        <Box mt={2}>
          {MockAdjustments.map((adjustment) => (
            <Box key={adjustment.id}>
              <MacroAdjustmentRow></MacroAdjustmentRow>
            </Box>
          ))}
        </Box>
      ) : (
        <Box mt={2}>
          <NoData
            label={t("adjustmentsPage.noAdjustmentsLabel1")}
            label2={t("adjustmentsPage.noAdjustmentsLabel2")}
          ></NoData>
        </Box>
      )}
    </Box>
  );
};
export default MacroAdjustmentsPageTable;
