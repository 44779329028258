import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { getObjectKeys } from "../../../../../utils";
import { TransformMekkoData } from "../index";
import { MekkoChartData } from "../index";
import { MekkoTableUnderlyingData } from "../../MekkoTable";
import { Typography } from "@mui/material";
import { TableResultsFilterDataType } from "../../ScenarioResults/types";
import { MekkoFilters } from "./MekkoFilters";
import { MekkoChartSection } from "../MekkoChartSection";
import {
  ViewMacroScenarioViewModel,
  MekkoResult,
  MekkoBaseGlideScenario,
  MekkoBaseAbsolutePerLitre,
} from "../../../../../orval/generated/models";
import getUnitsToDisplay from "../../HelperFunctions/getUnitsToDisplay";

type MekkoSectionProps = {
  disabled: boolean;
  scenario: ViewMacroScenarioViewModel | null;
};

export function MekkoAccordion({ disabled, scenario }: MekkoSectionProps) {
  const { t } = useTranslation("macro");
  const [mekkoData, setMekkoData] = useState<MekkoResult | undefined>();
  const [mekkoYearList, setMekkoYearList] = useState<string[]>([]);
  const [selectedMekkoYear, setSelectedMekkoYear] = useState<string>("");
  const [selectedMekkoCategories, setSelectedMekkoCategories] = useState<
    string[]
  >([]);
  const [chartData, setChartData] = useState<MekkoChartData>({
    dimensions: [],
    data: [],
  });
  const [years, setYears] = useState<{ [Key: string]: string } | undefined>();
  const [units, setUnits] = useState<TableResultsFilterDataType>(
    TableResultsFilterDataType.ABSOLUTE,
  );
  const [subtitle, setSubtitle] = useState<string>("");

  useEffect(() => {
    if (scenario) {
      setMekkoData(scenario?.results?.mekko);
      const years: { [Key: string]: string } = {
        base: `${scenario?.inputs?.years?.base_year} base year`,
        glide: `${scenario?.inputs?.years?.target_year} glidepath`,
      };
      setMekkoYearList(Object.values(years));
      setSelectedMekkoYear(getObjectKeys(years)[0]);
      setYears(years);
    }
  }, [scenario]);

  useEffect(() => {
    if (mekkoData) {
      const chartData: MekkoChartData = TransformMekkoData(
        mekkoData,
        selectedMekkoYear as keyof MekkoBaseGlideScenario,
        units as keyof MekkoBaseAbsolutePerLitre,
      );
      setChartData(chartData);
    }

    const unitsToDisplay = getUnitsToDisplay(units);
    setSubtitle(t(`${unitsToDisplay}`));
  }, [selectedMekkoYear, mekkoData, units, selectedMekkoCategories, t]);

  function handleSetMekkoYear(year: string) {
    if (!years) {
      return;
    }
    const selectedYear = Object.entries(years).find(
      ([, value]) => value === year,
    );
    if (selectedYear) {
      setSelectedMekkoYear(selectedYear[0]);
    }
  }

  return (
    <Box>
      <Box my={2} display={"flex"} flexDirection={"column"}>
        <Box
          sx={{
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MekkoFilters
            years={mekkoYearList}
            categories={[]}
            units={units}
            handleSetMekkoYear={handleSetMekkoYear}
            setSelectedMekkoCategories={setSelectedMekkoCategories}
            setSelectedUnits={setUnits}
          />
        </Box>
      </Box>
      <Box display="flex" gap={2} flexDirection="column">
        <Box
          sx={{
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
          }}
          mb={2}
        >
          <Typography className="tableHeader">
            {t(
              "dataVisualisationTabSection.emissionsHeatMap.titles.heatMapChartTitle",
            )}
          </Typography>
          <Typography className="tableSubHeader">{subtitle}</Typography>
        </Box>
        <MekkoChartSection chartData={chartData} />
      </Box>
      <Box my={2} display="flex" gap={2} flexDirection="column">
        <Box
          sx={{
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
          }}
          my={2}
        >
          <Typography className="tableHeader">
            {t(
              "dataVisualisationTabSection.emissionsHeatMap.titles.emissionTableTitle",
            )}
          </Typography>
        </Box>
        <MekkoTableUnderlyingData
          mekkoData={mekkoData}
          scenario={scenario}
          selectedYear={selectedMekkoYear}
        />
      </Box>
    </Box>
  );
}
