import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import pwcLogo from "../../../assets/images/PwC_Outline_Logo_Black.png";
import "./AuthLayout.scss";
import { AppConstant, EnvironmentVariable } from "../../../constants";
import { Box, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { AuthSessionStorageKeys } from "../navigation/config";
import {
  getIDAMAccessToken,
  handleAddAuthObjectToSessionStorage,
  isTokenExpired,
} from "../utils/verifyPwCAccess";
import { getBaseURL } from "../../../utils/HTTPUtils/http-utils";
import AuthMessage from "../components/AuthMessage";
import { HomeRoutesConfig } from "features/home/navigation/config";
import { useGlobalLoader } from "components/common";

export function AuthLayout() {
  const { t } = useTranslation("login");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [accessToken, setAccessToken] = useState(
    sessionStorage.getItem(AuthSessionStorageKeys.accessToken),
  );
  const [isLoading, setIsLoading] = useState(false);
  const { idamConfig } = EnvironmentVariable;
  const location = useLocation();
  const isLogoutPage = location.pathname.includes("logout");
  const { showGlobalLoader } = useGlobalLoader();
  const code = searchParams.get("code"); // null when logged out

  const initateLogin = () => {
    const redirectURL = `${getBaseURL(idamConfig.appURL)}/auth`;
    const idamLoginURL = `${getBaseURL(idamConfig.loginURL)}client_id=${getBaseURL(idamConfig.clientId)}&redirect_uri=${redirectURL}&response_type=${idamConfig.responseType}&scope=${idamConfig.scope}`;
    window.location.replace(idamLoginURL);
  };

  const storeAuthInfoAfterLogin = useCallback(async () => {
    if (!code) return;

    setIsLoading(true);
    try {
      const authObject = await getIDAMAccessToken(code);
      handleAddAuthObjectToSessionStorage(authObject);
      setAccessToken(authObject?.access_token);
    } finally {
      setIsLoading(false);
    }
  }, [code]);

  useEffect(() => {
    storeAuthInfoAfterLogin();
  }, [storeAuthInfoAfterLogin]);

  // If we already have a valid access token then navigate to home page
  useEffect(() => {
    if (accessToken) {
      setIsLoading(false);
      !isTokenExpired(accessToken) && navigate(HomeRoutesConfig.homePage);
    }
  }, [accessToken, navigate]);

  useEffect(() => {
    isLoading ? showGlobalLoader(true) : showGlobalLoader(false);
  }, [showGlobalLoader, isLoading]);

  return (
    <Box>
      {!code && (
        <Grid container component="main" sx={{ minHeight: "100vh" }}>
          <Grid item md={8} xs={12}>
            <Box>
              <img
                src={pwcLogo as string}
                alt={t("PwCLogo")}
                className="landing-logo"
                loading="lazy"
              />
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                sx={{ height: "100vh", marginLeft: "8vw" }}
              >
                <Box>
                  <Typography mb={3} variant="h1" sx={{ fontWeight: "500" }}>
                    {AppConstant.appName}
                  </Typography>
                  {isLogoutPage ? (
                    <AuthMessage
                      message={t("logoutMessage")}
                      buttonText={t("loginBtn")}
                      onButtonClick={initateLogin}
                      isLoading={isLoading}
                    />
                  ) : (
                    <AuthMessage
                      message={t("description")}
                      buttonText={t("loginBtn")}
                      onButtonClick={initateLogin}
                      isLoading={isLoading}
                      disableButton={!!accessToken}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} xs={0} className="landing-image"></Grid>
        </Grid>
      )}
    </Box>
  );
}
