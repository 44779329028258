import { Box, Button, FormGroup, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Product,
  SKUMinimalViewModel,
} from "../../../../orval/generated/models";
import { FixedSizeList } from "react-window";
import { ProductRow } from "../ProductRow/ProductRow";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export type ProductSelectionModalProductListPickerProps = {
  filteredSkus: SKUMinimalViewModel[];
  selectedProducts: Product[];
  productLimit: number;
  onChangeSelectProduct: (item: Product, checked: boolean) => void;
  setSelectedProducts: Dispatch<SetStateAction<Product[]>>;
};

export function ProductSelectionModalProductListPicker({
  filteredSkus,
  selectedProducts,
  productLimit,
  onChangeSelectProduct,
  setSelectedProducts,
}: ProductSelectionModalProductListPickerProps) {
  const { spacing } = useTheme();
  const { t } = useTranslation(["common", "micro"]);

  const [allProductsAdded, setAllProductsAdded] = useState(false);

  useEffect(() => {
    setAllProductsAdded(
      filteredSkus.every((product) =>
        selectedProducts.includes(product as unknown as Product),
      ),
    );
  }, [filteredSkus, selectedProducts]);

  function handleAddAll() {
    setSelectedProducts((prev) => {
      const newProducts = filteredSkus.filter(
        (product) => !prev.includes(product as unknown as Product),
      );
      return [...prev, ...(newProducts as unknown as Product[])];
    });
  }

  const boxRemHeight = 16;
  const fontSize = parseFloat(
    getComputedStyle(document.documentElement).fontSize,
  );
  const verticalSpacing = parseFloat(spacing(7));
  const fixedListPixelHeight = boxRemHeight * fontSize - verticalSpacing;

  return (
    <>
      <Box
        mt={1}
        p={1}
        sx={{
          flexWrap: "nowrap",
          overflowY: "hidden",
          overflowX: "hidden",
          maxHeight: `${boxRemHeight}rem`,
          height: "20em",
        }}
        data-testid="product-list-picker"
      >
        {filteredSkus.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            my={2}
          >
            <Typography variant="body2">
              {t("common:labels.noResults")}
            </Typography>
          </Box>
        ) : (
          <>
            <FormGroup>
              <FixedSizeList
                height={fixedListPixelHeight}
                itemCount={filteredSkus.length}
                itemSize={50}
                width="100%"
              >
                {({ index, style }) => (
                  <ProductRow
                    index={index}
                    style={style}
                    filteredSkus={filteredSkus}
                    selectedProducts={selectedProducts}
                    productLimit={productLimit}
                    onChangeSelectProduct={onChangeSelectProduct}
                  />
                )}
              </FixedSizeList>
              <Box justifyContent={"flex-end"} display={"flex"} mt={1}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleAddAll}
                  disabled={
                    allProductsAdded || filteredSkus.length > productLimit
                  }
                  data-testid="add-all-products-button"
                >
                  {t("micro:productsSection.selectProductModal.addAll")}
                </Button>
              </Box>
            </FormGroup>
          </>
        )}
      </Box>
    </>
  );
}
