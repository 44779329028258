import { Box, Button, Typography } from "@mui/material";
import "./TermsOfUse.scss";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import RemarkGFM from "remark-gfm";
import { useEffect } from "react";
import { useSnackbar } from "../../../components/common/Notification/showSnackbar";
import { AxiosError } from "axios";
import { useGlobalLoader } from "../../../components/common";
import {
  useGetTermsAndConditionsLegalTermsOfUseGet,
  useUpdateUserTermsAndConditionsStatusAsyncLegalTermsOfUseStatusPut,
} from "../../../orval/generated/endpoint";

export interface TermsOfUseProps {
  onClose: () => void;
  setIsTcsAccepted: (isAccepted: boolean) => void;
}

export default function TermsOfUse(props: TermsOfUseProps) {
  const { t } = useTranslation(["home", "common"]);
  const showSnackbar = useSnackbar();
  const { showGlobalLoader } = useGlobalLoader();

  const {
    data: termsData,
    isPending: termsDataIsPending,
    error: termsError,
  } = useGetTermsAndConditionsLegalTermsOfUseGet();

  useEffect(() => {
    termsError && showSnackbar(termsError as AxiosError<unknown, any>, "error");
  }, [termsError]);

  const {
    mutateAsync: updateUserTermsAndConditionsStatus,
    isPending: updateUserTermsAndConditionsStatusIsPending,
  } = useUpdateUserTermsAndConditionsStatusAsyncLegalTermsOfUseStatusPut({
    mutation: {
      onSuccess: () => {
        showSnackbar("home:termsOfUse.success", "success");
      },
      onError: () => {
        showSnackbar("home:termsOfUse.error", "error");
      },
      onSettled: () => {
        showGlobalLoader(false);
      },
    },
  });

  const handleAccept = async () => {
    try {
      await updateUserTermsAndConditionsStatus();
      props.setIsTcsAccepted(true);
      props.onClose();
    } catch (error) {
      showSnackbar("home:termsOfUse.error", "error");
    }
  };

  useEffect(() => {
    const isLoading =
      termsDataIsPending || updateUserTermsAndConditionsStatusIsPending;
    showGlobalLoader(isLoading);
  }, [
    termsDataIsPending,
    updateUserTermsAndConditionsStatusIsPending,
    showGlobalLoader,
  ]);

  return (
    <div className="modal">
      <Typography className="header-H1" sx={{ marginBottom: 4 }}>
        {t("home:termsOfUse.title")}
      </Typography>
      <Box className="markdown-content policy-page">
        <ReactMarkdown
          remarkPlugins={[RemarkGFM]}
          components={{
            h1(props) {
              return (
                <Typography
                  className="header-H1"
                  sx={{ marginBottom: 1, marginTop: 3 }}
                >
                  {props.children}
                </Typography>
              );
            },
            h2(props) {
              return (
                <Typography
                  className="header-H2"
                  sx={{ marginBottom: 1, marginTop: 3 }}
                >
                  {props.children}
                </Typography>
              );
            },
            h3(props) {
              return (
                <Typography
                  className="header-H3"
                  sx={{ marginBottom: 1, marginTop: 2 }}
                >
                  {props.children}
                </Typography>
              );
            },
            p(props) {
              return <Typography>{props.children}</Typography>;
            },
            ol(props) {
              return <ol>{props.children}</ol>;
            },
            li(props) {
              return <li className="markdown-content-li">{props.children}</li>;
            },
            strong(props) {
              return (
                <span className="markdown-content-strong">
                  {props.children}
                </span>
              );
            },
          }}
        >
          {termsData && termsData.content}
        </ReactMarkdown>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={handleAccept}
            disabled={!termsData}
            sx={{ marginTop: 4 }}
          >
            {t("common:labels.accept")}
          </Button>
        </Box>
      </Box>
    </div>
  );
}
