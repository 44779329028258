import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ToolConfigurationRoutesConfig } from "../../navigation/config";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useReducer,
} from "react";
import {
  MetadataActiveAlert,
  AlertStatusEnum,
  AlertRequest,
  CountryViewModel,
  AlertDetailsViewModel,
} from "../../../../orval/generated/models";
import BannerAlert from "../../../../components/common/BannerAlert/BannerAlert";
import "./BannerAlertConfigurationPage.scss";
import { useGlobalLoader } from "../../../../components/common";
import { useModal } from "../../../../components/common/Modal";
import { useMetadata } from "./MetadataContext";
import EnableBannerAlertModal from "./Modals/EnableBannerAlertModal";
import { typeOfChange, AlignmentEnum, AlignmentEnumType } from "./types";
import "dayjs/locale/en-gb";
import BannerReducerHelper from "./BannerReducerHelper";
import { useSnackbar } from "../../../../components/common/Notification/showSnackbar";
import SelectDropdown from "../../../../components/common/SelectDropdown/SelectDropdown";
import {
  getGetAlertDetailsForAdminPanelAlertsGetQueryKey,
  useCreateAlertAlertsPost,
  useGetAlertDetailsForAdminPanelAlertsGet,
  useGetMemberCountriesMembersCountriesGet,
  useResetAlertAlertsAlertIdResetPost,
  useUpdateAlertStatusAlertsAlertIdPut,
} from "../../../../orval/generated/endpoint";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import ConfirmationModal from "../../../../components/common/ConfirmationModal/ConfirmationModal";

function BannerAlertConfigurationPage() {
  const { t } = useTranslation(["toolConfiguration", "common"]);
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { setActiveAlert } = useMetadata();
  const { showGlobalLoader } = useGlobalLoader();
  const bannerId = 1;
  const showSnackbar = useSnackbar();
  const queryClient = useQueryClient();
  const {
    data: alertDetails,
    isPending: alertDetailsIsPending,
    error: alertDetailsIsError,
  } = useGetAlertDetailsForAdminPanelAlertsGet();

  const { data: countriesForDropdown, error: countriesFetchError } =
    useGetMemberCountriesMembersCountriesGet();

  const [state, dispatch] = useReducer(BannerReducerHelper, {
    selectedBannerType: "",
    selectedCountries: [],
    startDate: null,
    endDate: null,
    previewBanner: {
      id: 1,
      message: t("alertConfigurationPage.messagePlaceholder"),
      type: AlertStatusEnum.error,
    },
    selectedCountriesDisplayNames: [],
    alignment: AlignmentEnum.bannerOff,
    formChangesMade: false,
    isAlertAvailableToTurnOn: false,
    hasFetchedAlertDetails: false,
    allFieldsDisabled: false,
    alertRequest: null,
    initialAlertRequest: {
      message: "",
      type: AlertStatusEnum[""],
      countries: [],
      start_date: null,
      end_date: null,
    },
  });

  const setIsAlertAvailableToTurnOn = (value: boolean) => {
    dispatch({ type: "SET_IS_ALERT_AVAILABLE_TO_TURN_ON", payload: value });
  };

  const handlePreviewBannerChange = (
    type: typeOfChange,
    value: string | AlertStatusEnum,
  ) => {
    if (type === "message") {
      dispatch({
        type: "SET_PREVIEW_BANNER",
        payload: {
          ...state.previewBanner,
          message: value,
        } as MetadataActiveAlert,
      });
    } else if (type === "type") {
      dispatch({
        type: "SET_PREVIEW_BANNER",
        payload: { ...state.previewBanner, type: value } as MetadataActiveAlert,
      });
    }
  };

  const handleBannerTypeChange = (event: { target: { value: string } }) => {
    let value = event.target.value.toLowerCase();
    dispatch({ type: "SET_BANNER_TYPE", payload: value });
    handlePreviewBannerChange("type", value);
  };

  const handleCountriesTypeChange = (_newSelectedCountries: string[]) => {
    const _itemsToRemove = state.selectedCountriesDisplayNames.filter(
      (item) => !_newSelectedCountries.includes(item),
    );
    const _itemsToAdd = _newSelectedCountries.filter(
      (item) => !state.selectedCountriesDisplayNames.includes(item),
    );
    const updatedDisplayNames = state.selectedCountriesDisplayNames.filter(
      (item) => !_itemsToRemove.includes(item),
    );
    updatedDisplayNames.push(..._itemsToAdd);
    const updatedSelectedCountries = updatedDisplayNames.map((displayName) => {
      const country = countriesForDropdown?.find(
        (c) => c.display_name === displayName,
      );
      return country ? { ...country } : null;
    });
    dispatch({
      type: "SET_SELECTED_COUNTRIES_DISPLAY_NAMES",
      payload: updatedDisplayNames,
    });
    dispatch({
      type: "SET_SELECTED_COUNTRIES",
      payload: updatedSelectedCountries.filter(Boolean) as CountryViewModel[],
    });
  };

  const handleMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
    handlePreviewBannerChange("message", event.target.value);
  };

  const handleAlignment = (
    event: MouseEvent<HTMLElement>,
    newAlignment: AlignmentEnumType,
  ) => {
    dispatch({ type: "SET_ALIGNMENT", payload: newAlignment });
  };

  const handleStartDateChange = (newValue: Dayjs | null) => {
    dispatch({ type: "SET_START_DATE", payload: newValue });
  };

  const handleEndDateChange = (newValue: Dayjs | null) => {
    dispatch({ type: "SET_END_DATE", payload: newValue });
  };

  const { mutateAsync: createAlert, isPending: createAlertIsPending } =
    useCreateAlertAlertsPost({
      mutation: {
        onSuccess: () => {
          dispatch({ type: "SET_FORM_CHANGES_MADE", payload: false });
          dispatch({
            type: "SET_IS_ALERT_AVAILABLE_TO_TURN_ON",
            payload: true,
          });
          showSnackbar(t("alertConfigurationPage.alertCreated"), "success");
        },
        onError: (error: AxiosError) => {
          console.log(error);
          showSnackbar(error, "error");
        },
      },
    });

  const handleCreateAlert = useCallback(() => {
    const alertRequestInvalid =
      state.alertRequest === null ||
      state.alertRequest?.message === "" ||
      state.alertRequest?.countries.length === 0 ||
      state.alertRequest?.type === "" ||
      state.alertRequest.start_date === "Invalid Date" ||
      state.alertRequest.end_date === "Invalid Date";

    if (alertRequestInvalid) {
      showSnackbar(
        t("alertConfigurationPage.alertConfigurationError"),
        "error",
      );
      return;
    }
    console.log("state.alertRequest", state.alertRequest);

    if (state.alertRequest !== null) {
      createAlert({ data: state.alertRequest });
    }
  }, [showSnackbar, t, state.alertRequest, createAlert]);

  const {
    mutateAsync: updateAlertStatus,
    isPending: updateAlertStatusIsPending,
  } = useUpdateAlertStatusAlertsAlertIdPut({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getGetAlertDetailsForAdminPanelAlertsGetQueryKey(),
        });
        closeModal();
      },
      onError: (error: AxiosError) => {
        showSnackbar(error, "error");
      },
    },
  });

  const handleTurnBannerOn = useCallback(() => {
    updateAlertStatus({
      alertId: bannerId.toString(),
      data: { enabled: true },
    }).then(() => {
      showSnackbar(
        t("alertConfigurationPage.bannerTurnedOnSuccessfully"),
        "success",
      );
      setActiveAlert(state?.alertRequest as MetadataActiveAlert);
      dispatch({ type: "SET_IS_ALERT_AVAILABLE_TO_TURN_ON", payload: false });
      dispatch({ type: "SET_ALL_FIELDS_DISABLED", payload: true });
    });
  }, [updateAlertStatus, t]);

  const handleTurnBannerOff = useCallback(() => {
    updateAlertStatus({
      alertId: bannerId.toString(),
      data: { enabled: false },
    }).then(() => {
      showSnackbar(
        t("alertConfigurationPage.bannerTurnedOffSuccessfully"),
        "success",
      );
      dispatch({ type: "SET_ALL_FIELDS_DISABLED", payload: false });
      dispatch({ type: "SET_IS_ALERT_AVAILABLE_TO_TURN_ON", payload: true });
    });
  }, [updateAlertStatus, t]);

  const { mutateAsync: resetAlert, isPending: resetAlertIsPending } =
    useResetAlertAlertsAlertIdResetPost({
      mutation: {
        onSuccess: () => {
          showSnackbar(t("alertConfigurationPage.alertReset"), "success");
          queryClient.invalidateQueries({
            queryKey: getGetAlertDetailsForAdminPanelAlertsGetQueryKey(),
          });
        },
        onError: (error: AxiosError) => {
          showSnackbar(error, "error");
        },
      },
    });

  const handleResetForm = useCallback(() => {
    resetAlert({ alertId: bannerId }).then(() => {
      dispatch({ type: "SET_BANNER_TYPE", payload: "" });
      dispatch({ type: "SET_SELECTED_COUNTRIES_DISPLAY_NAMES", payload: [] });
      dispatch({ type: "SET_SELECTED_COUNTRIES", payload: [] });
      dispatch({
        type: "SET_PREVIEW_BANNER",
        payload: {
          id: 1,
          message: t("alertConfigurationPage.messagePlaceholder"),
          type: "error",
        },
      });
      dispatch({ type: "SET_START_DATE", payload: null });
      dispatch({ type: "SET_END_DATE", payload: null });
      dispatch({ type: "SET_ALIGNMENT", payload: AlignmentEnum.bannerOff });
      dispatch({ type: "SET_FORM_CHANGES_MADE", payload: false });
      dispatch({ type: "SET_ALL_FIELDS_DISABLED", payload: false });
      dispatch({ type: "SET_IS_ALERT_AVAILABLE_TO_TURN_ON", payload: false });
      dispatch({ type: "SET_ALERT_REQUEST", payload: null });
      closeModal();
    });
  }, [t, closeModal]);

  const setPageData = useCallback(
    (data: AlertDetailsViewModel) => {
      if (countriesForDropdown) {
        dispatch({ type: "SET_START_DATE", payload: dayjs(data.start_date) });
        dispatch({ type: "SET_END_DATE", payload: dayjs(data.end_date) });
        dispatch({
          type: "SET_BANNER_TYPE",
          payload: data.type,
        });
        dispatch({
          type: "SET_PREVIEW_BANNER",
          payload: {
            id: 1,
            message:
              data.message || t("alertConfigurationPage.messagePlaceholder"),
            type: data.type as AlertStatusEnum,
          },
        });
        const selectedCountries = data.countries
          .map((isoCountryCode) => {
            return countriesForDropdown.find(
              (country) => country.country === isoCountryCode,
            );
          })
          .filter((country) => country !== undefined) as CountryViewModel[];
        dispatch({
          type: "SET_SELECTED_COUNTRIES",
          payload: selectedCountries,
        });
        const newSelectedCountriesDisplayNames = selectedCountries.map(
          (country) => country.display_name,
        );
        dispatch({
          type: "SET_SELECTED_COUNTRIES_DISPLAY_NAMES",
          payload: newSelectedCountriesDisplayNames,
        });
        dispatch({ type: "SET_HAS_FETCHED_ALERT_DETAILS", payload: true });
        dispatch({ type: "SET_FORM_CHANGES_MADE", payload: false });
        dispatch({ type: "SET_IS_ALERT_AVAILABLE_TO_TURN_ON", payload: false });
        if (data.active) {
          dispatch({ type: "SET_ALIGNMENT", payload: AlignmentEnum.bannerOn });
          dispatch({ type: "SET_ALL_FIELDS_DISABLED", payload: true });
        } else if (!data.active) {
          dispatch({ type: "SET_ALIGNMENT", payload: AlignmentEnum.bannerOff });
          dispatch({ type: "SET_ALL_FIELDS_DISABLED", payload: false });
        }
        dispatch({
          type: "SET_INITIAL_ALERT_REQUEST",
          payload: {
            message: "",
            type: AlertStatusEnum[""],
            countries: [],
            start_date: null,
            end_date: null,
          },
        });
      }
    },
    [countriesForDropdown, t],
  );

  const handleOpenEnableBannerModal = useCallback(() => {
    openModal(
      <EnableBannerAlertModal
        closeModal={closeModal}
        handleTurnBannerOn={handleTurnBannerOn}
        previewBanner={state.previewBanner as MetadataActiveAlert}
        handleAlignment={handleAlignment}
        setIsAlertAvailableToTurnOn={setIsAlertAvailableToTurnOn}
      ></EnableBannerAlertModal>,
    );
  }, [openModal, closeModal, handleTurnBannerOn, state.previewBanner]);

  const handleOpenResetBannerModal = useCallback(() => {
    openModal(
      <ConfirmationModal
        title={t("alertConfigurationPage.resetAlertModal.title")}
        description={t("alertConfigurationPage.resetAlertModal.description")}
        actionTitle={t("common:actions.reset")}
        confirmAction={() => {
          handleResetForm();
          closeModal();
        }}
        cancelAction={closeModal}
      />,
      true,
    );
  }, [openModal, closeModal, handleResetForm]);

  const handleOpenDisableBannerModal = useCallback(() => {
    openModal(
      <ConfirmationModal
        title={t("alertConfigurationPage.disableAlertModal.title")}
        description={t("alertConfigurationPage.disableAlertModal.description")}
        actionTitle={t("common:actions.disable")}
        confirmAction={() => {
          handleTurnBannerOff();
          closeModal();
        }}
        cancelAction={(event?: React.MouseEvent<HTMLElement>) => {
          if (event) {
            handleAlignment(event, "bannerOn");
            setIsAlertAvailableToTurnOn(false);
            closeModal();
          }
        }}
      />,
      true,
    );
  }, [openModal, closeModal, handleTurnBannerOff]);

  useEffect(() => {
    alertDetails
      ? setPageData(alertDetails)
      : setPageData(state.initialAlertRequest as AlertDetailsViewModel);
    alertDetailsIsError &&
      showSnackbar(alertDetailsIsError as AxiosError<unknown, any>, "error");
    countriesFetchError &&
      showSnackbar(countriesFetchError as AxiosError<unknown, any>, "error");
  }, [
    setPageData,
    showSnackbar,
    alertDetails,
    alertDetailsIsError,
    countriesFetchError,
  ]);

  // ? Sets the alertRequest body for creating new alerts
  useEffect(() => {
    if (
      state.previewBanner &&
      state.startDate &&
      state.endDate &&
      state.previewBanner.type !== ""
    ) {
      const alertRequest: AlertRequest = {
        message: state.previewBanner?.message,
        type: state.selectedBannerType as AlertStatusEnum,
        countries: state.selectedCountries.map((country) => country.country),
        start_date: state.startDate?.startOf("day").format("YYYY-MM-DD"),
        end_date: state.endDate?.startOf("day").format("YYYY-MM-DD"),
      };
      dispatch({ type: "SET_ALERT_REQUEST", payload: alertRequest });
      setIsAlertAvailableToTurnOn(true);
    }
  }, [
    state.previewBanner,
    state.startDate,
    state.endDate,
    state.selectedCountries,
  ]);

  // ? Checks for form changes
  useEffect(() => {
    dispatch({
      type: "SET_FORM_CHANGES_MADE",
      payload:
        JSON.stringify(state.alertRequest) !==
        JSON.stringify(state.initialAlertRequest),
    });
  }, [state.alertRequest, state.initialAlertRequest]);

  useEffect(() => {
    createAlertIsPending || alertDetailsIsPending || updateAlertStatusIsPending
      ? showGlobalLoader(true)
      : showGlobalLoader(false);
  }, [createAlertIsPending, alertDetailsIsPending, updateAlertStatusIsPending]);

  return (
    <>
      {state.hasFetchedAlertDetails ? (
        <Box>
          <Button
            variant="text"
            onClick={() =>
              navigate(
                `${ToolConfigurationRoutesConfig.toolConfigurationPage}?`,
              )
            }
            startIcon={<ArrowBackIosSharpIcon />}
          >
            {t("backButtonText")}
          </Button>
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography className={"header-H2"} fontWeight="500">
                {t("alertConfigurationPage.title")}
              </Typography>
            </Box>
            <Box>
              <ToggleButtonGroup
                value={state.alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                data-testid="toggler"
              >
                <ToggleButton
                  value="bannerOn"
                  aria-label={t("alertConfigurationPage.bannerOn")}
                  className="banner-config-toggle"
                  onClick={handleOpenEnableBannerModal}
                  disabled={
                    (!state.isAlertAvailableToTurnOn &&
                      !state.allFieldsDisabled) ||
                    state.alignment === "bannerOn" ||
                    state.formChangesMade
                  }
                  data-testid="toggler-on-btn"
                >
                  {t("alertConfigurationPage.on")}
                </ToggleButton>
                <ToggleButton
                  value="bannerOff"
                  aria-label={t("alertConfigurationPage.bannerOff")}
                  className="banner-config-toggle"
                  onClick={handleOpenDisableBannerModal}
                  disabled={
                    !state.allFieldsDisabled || state.alignment === "bannerOff"
                  }
                  data-testid="toggler-off-btn"
                >
                  {t("alertConfigurationPage.off")}
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">
              {t("alertConfigurationPage.description")}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography className="header-H3">
              {t("alertConfigurationPage.customiseAlert")}
            </Typography>
          </Box>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={6}>
              <FormControl fullWidth size={"small"}>
                <InputLabel id="alert-type">
                  {t("alertConfigurationPage.formHeaders.type")}
                </InputLabel>
                <Select
                  labelId="alert-type-label-id"
                  id="alert-type-simple-select"
                  name="type"
                  value={state.selectedBannerType}
                  label={t("alertConfigurationPage.formHeaders.type")}
                  onChange={handleBannerTypeChange}
                  disabled={state.allFieldsDisabled}
                >
                  {Object.values(AlertStatusEnum)
                    .sort()
                    .map((type) => (
                      <MenuItem key={type} value={type}>
                        {`${type.charAt(0).toUpperCase()}${type.slice(1)}`}
                      </MenuItem>
                    ))
                    .filter((type) => type.key !== "")}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <SelectDropdown
                listItems={
                  countriesForDropdown?.map(
                    (country) => country.display_name,
                  ) ?? []
                }
                onSave={handleCountriesTypeChange}
                savedSelectedItems={state.selectedCountriesDisplayNames}
                title={t("alertConfigurationPage.formHeaders.countries")}
                selectAll={true}
                compact
                disabled={state.allFieldsDisabled}
              ></SelectDropdown>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size={"small"}>
                <TextField
                  id="outlined-multiline-static"
                  name="message"
                  label={"Message"}
                  multiline
                  rows={3}
                  disabled={state.allFieldsDisabled}
                  inputProps={{ maxLength: 250 }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleMessageChange(e)
                  }
                  placeholder={t("alertConfigurationPage.messagePlaceholder")}
                  value={
                    state.previewBanner?.message ===
                      t("alertConfigurationPage.messagePlaceholder") ||
                      state.previewBanner?.message == null
                      ? ""
                      : `${state.previewBanner?.message}`
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography className="header-H4 preview-banner-header">
                {t("alertConfigurationPage.formHeaders.preview")}
              </Typography>
              {state.previewBanner ? (
                <BannerAlert
                  activeAlert={state.previewBanner as MetadataActiveAlert}
                  preview={true}
                />
              ) : (
                <Typography variant="body1">
                  {t("alertConfigurationPage.previewUnavailable")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box mt={2}>
                <Typography className="header-H3">
                  {t("alertConfigurationPage.scheduleAlert")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  label={t("alertConfigurationPage.formHeaders.from")}
                  value={state.startDate}
                  onChange={(newValue) => handleStartDateChange(newValue)}
                  sx={{ width: "100%" }}
                  minDate={dayjs()}
                  maxDate={dayjs(state.endDate)}
                  disabled={state.allFieldsDisabled}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  label={t("alertConfigurationPage.formHeaders.to")}
                  value={state.endDate || null}
                  onChange={(newValue) => handleEndDateChange(newValue)}
                  sx={{ width: "100%" }}
                  minDate={dayjs(state.startDate) || dayjs()}
                  disabled={state.allFieldsDisabled}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Box display="flex" alignItems="center" justifyContent="end" mt={3}>
            <Box mr={2}>
              <Button variant="outlined" onClick={handleOpenResetBannerModal}>
                {t("common:actions.reset")}
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                onClick={handleCreateAlert}
                disabled={
                  state.allFieldsDisabled ||
                  !state.formChangesMade ||
                  state.alertRequest === null ||
                  !state.alertRequest.type
                }
              >
                {t("common:actions.save")}
              </Button>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default BannerAlertConfigurationPage;
