import { YearsBaseYear } from "../../../../orval/generated/models";

export enum TableResultsType {
  BY_PACK_TYPE = "by_pack_type",
  BY_CATEGORY = "by_category",
  BY_PILLAR = "by_pillar",
}

export enum TableResultsFilterDataType {
  ABSOLUTE = "absolute",
  PER_LITRE = "per_litre",
}

export enum TableResultsFilterDataAccessorKey {
  ABSOLUTE = "absolute",
  PER_LITRE = "per_litre",
}

export type TableResultsDataItem = {
  [x: string]: string | number;
  category: string;
  base: number;
  ingredients: number;
  packaging: number;
  manufacturing: number;
  cde: number;
};

export interface CombinedDataItem {
  category: string;
  base?: YearsBaseYear | string | number;
  glide?: string | number;
  scenario?: string | number;
  ingredients: string;
  packaging: string;
  manufacturing: string;
  logistics: string;
  cde: string;
  total: string;
}

export type EmissionData = {
  cde: number;
  logistics: number;
  packaging: number;
  ingredients: number;
  manufacturing: number;
};

export type CategoryData = {
  base: EmissionData;
  glide: EmissionData;
  scenario: EmissionData;
};

export type PillarDeepDiveTableDataObjectBeforeMapping = {
  [key: string]: CategoryData;
};
