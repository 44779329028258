import {
  Autocomplete,
  Box,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import {
  CreateMemberModel,
  CountryViewModel,
  MemberDetails,
  FunctionalAreaItemViewModel,
  AccordionsViewModel,
  PermissionItem,
} from "../../../../../orval/generated/models";
import { useGlobalLoader } from "../../../../../components/common";
import { useSnackbar } from "../../../../../components/common/Notification/showSnackbar";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { useModal } from "../../../../../components/common/Modal";

import SelectDropdown from "../../../../../components/common/SelectDropdown/SelectDropdown";
import {
  getGetAllMembersMembersGetQueryKey,
  useCreateMemberMembersPost,
  useEditMemberByIdMembersMemberIdPatch,
  useGetGumTerritoriesTerritoriesGet,
} from "../../../../../orval/generated/endpoint";
import {
  getAccessFields,
  getAdjustmentAccessItems,
  getFunctionalAccessItems,
  useAccessHandlers,
} from "../UserManagementHelperFunctions";
import { AccessField } from "../UserManagementHelperFunctions";
import { AppConstant } from "constants/AppConstant";

type UserInfoField = {
  name: keyof CreateMemberModel;
  label: string;
  placeholder: string;
};

export interface UpsertUserModalProps {
  isEditing: boolean;
  userInformation?: MemberDetails;
  countryData: CountryViewModel[];
  functionalAccessData: FunctionalAreaItemViewModel[];
  adjustmentAccessData: AccordionsViewModel[];
  flattenedFunctionalAccess: PermissionItem[];
  flattenedAdjustmentAccess: PermissionItem[];
}

export const UpsertUserModal = ({
  isEditing,
  userInformation,
  countryData,
  functionalAccessData,
  adjustmentAccessData,
  flattenedFunctionalAccess,
  flattenedAdjustmentAccess,
}: UpsertUserModalProps) => {
  const { t } = useTranslation("toolConfiguration");
  const { showGlobalLoader } = useGlobalLoader();
  const showSnackbar = useSnackbar();
  const queryClient = useQueryClient();
  const modal = useModal();

  const {
    data: territoryData,
    isPending: territoryDataIsPending,
    isError: errorFetchingTerritoryData,
  } = useGetGumTerritoriesTerritoriesGet();

  const functionalAccessItems = getFunctionalAccessItems(
    functionalAccessData ?? [],
  );
  const adjustmentAccessItems = getAdjustmentAccessItems(
    adjustmentAccessData ?? [],
  );

  const { mutateAsync: createUser, isPending: createUserIsPending } =
    useCreateMemberMembersPost({
      mutation: {
        onSuccess: () => {
          showSnackbar(
            "toolConfiguration:userManagementPage.upsertUser.createUser.successMessage",
            "success",
          );
          queryClient.invalidateQueries({
            queryKey: getGetAllMembersMembersGetQueryKey(),
          });
        },
      },
    });

  const handleCreateUser = async (values: CreateMemberModel) => {
    try {
      await createUser({ data: values });
    } catch (error) {
      showSnackbar(error as AxiosError<unknown, any>, "error");
    } finally {
      modal.closeModal();
      showGlobalLoader(false);
    }
  };

  const { mutateAsync: editUser, isPending: editUserIsPending } =
    useEditMemberByIdMembersMemberIdPatch({
      mutation: {
        onSuccess: () => {
          showSnackbar(
            "toolConfiguration:userManagementPage.upsertUser.editUser.successMessage",
            "success",
          );
          queryClient.invalidateQueries({
            queryKey: getGetAllMembersMembersGetQueryKey(),
          });
        },
      },
    });

  const handleEditUser = async (values: CreateMemberModel) => {
    try {
      await editUser({
        memberId: userInformation?.id ?? 0,
        data: values,
      });
    } catch (error) {
      showSnackbar(error as AxiosError<unknown, any>, "error");
    } finally {
      modal.closeModal();
      showGlobalLoader(false);
    }
  };

  useEffect(() => {
    createUserIsPending || editUserIsPending || territoryDataIsPending
      ? showGlobalLoader(true)
      : showGlobalLoader(false);
  }, [
    editUserIsPending,
    createUserIsPending,
    showGlobalLoader,
    territoryDataIsPending,
  ]);

  useEffect(() => {
    errorFetchingTerritoryData &&
      showSnackbar(
        "toolConfiguration:userManagementPage.upsertUser.territoryFetchError",
        "error",
      );
  }, [errorFetchingTerritoryData, showSnackbar]);

  const userValidationSchema = yup.object({
    first_name: yup
      .string()
      .min(2, t("userManagementPage.upsertUser.userValidation.firstNameMin"))
      .required(
        t("userManagementPage.upsertUser.userValidation.firstNameRequired"),
      ),
    last_name: yup
      .string()
      .min(2, t("userManagementPage.upsertUser.userValidation.lastNameMin"))
      .required(
        t("userManagementPage.upsertUser.userValidation.lastNameRequired"),
      ),
    email: yup
      .string()
      .email(t("userManagementPage.upsertUser.userValidation.emailInvalid"))
      .required(
        t("userManagementPage.upsertUser.userValidation.emailRequired"),
      ),
    organisation: yup
      .string()
      .required(
        t("userManagementPage.upsertUser.userValidation.organisationRequired"),
      ),
    territory: yup
      .string()
      .required(
        t("userManagementPage.upsertUser.userValidation.territoryRequired"),
      ),
    functional_access: yup
      .array()
      .min(
        1,
        t(
          "userManagementPage.upsertUser.userValidation.functionalAccessRequired",
        ),
      )
      .required(
        t(
          "userManagementPage.upsertUser.userValidation.functionalAccessRequired",
        ),
      ),
    countries: yup
      .array()
      .min(1, t("userManagementPage.upsertUser.userValidation.countryRequired"))
      .required(
        t("userManagementPage.upsertUser.userValidation.countryRequired"),
      ),
    adjustment_access: yup
      .array()
      .min(
        1,
        t(
          "userManagementPage.upsertUser.userValidation.adjustmentAccessRequired",
        ),
      )
      .required(
        t(
          "userManagementPage.upsertUser.userValidation.adjustmentAccessRequired",
        ),
      ),
  });

  const formik = useFormik<CreateMemberModel>({
    initialValues: {
      countries:
        userInformation?.permissions?.countries?.map(
          (country) => country.country,
        ) || [],
      functional_access:
        userInformation?.permissions?.functional_access?.map(
          (access) => access.id,
        ) || [],
      adjustment_access:
        (!userInformation?.permissions?.adjustment_access?.map(
          (access) => access.id,
        )?.length
          ? AppConstant.defaultAdjustmentAccess
          : userInformation?.permissions?.adjustment_access?.map(
              (access) => access.id,
            )) || AppConstant.defaultAdjustmentAccess,
      first_name: userInformation?.first_name ?? "",
      last_name: userInformation?.last_name ?? "",
      email: userInformation?.email ?? "",
      organisation: userInformation?.organisation ?? "",
      territory: userInformation?.territory ?? "",
    },
    validationSchema: userValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      values.email = values.email.toLowerCase();
      if (isEditing) {
        handleEditUser(values);
      } else {
        handleCreateUser(values);
      }
    },
  });

  const userInfoFields: UserInfoField[] = [
    {
      name: "first_name",
      label: "First Name",
      placeholder: "userManagementPage.upsertUser.firstNamePlaceholder",
    },
    {
      name: "last_name",
      label: "Last Name",
      placeholder: "userManagementPage.upsertUser.lastNamePlaceholder",
    },
    {
      name: "email",
      label: "Email",
      placeholder: "userManagementPage.upsertUser.emailPlaceholder",
    },
    {
      name: "organisation",
      label: "Organisation",
      placeholder: "userManagementPage.upsertUser.organisationPlaceholder",
    },
  ];

  const {
    handleFunctionalAccessChange,
    handleCountryAccessChange,
    handleAdjustmentAccessChange,
  } = useAccessHandlers(
    formik,
    flattenedFunctionalAccess,
    countryData,
    flattenedAdjustmentAccess,
    adjustmentAccessData,
  );

  const accessFields = useMemo(
    () =>
      getAccessFields(
        formik,
        countryData,
        flattenedAdjustmentAccess,
        flattenedFunctionalAccess,
        adjustmentAccessItems,
        functionalAccessItems,
        handleCountryAccessChange,
        handleAdjustmentAccessChange,
        handleFunctionalAccessChange,
        t,
      ),
    [
      adjustmentAccessItems,
      countryData,
      flattenedAdjustmentAccess,
      flattenedFunctionalAccess,
      formik,
      functionalAccessItems,
      handleAdjustmentAccessChange,
      handleCountryAccessChange,
      handleFunctionalAccessChange,
      t,
    ],
  );

  useEffect(() => {
    if (!isEditing) {
      if (formik.values.email.toLowerCase().includes("@pwc")) {
        if (!formik.touched.countries) {
          formik.setFieldValue(
            "countries",
            countryData?.map((country: CountryViewModel) => country.country) ||
              [],
          );
        }

        if (!formik.touched.functional_access) {
          formik.setFieldValue(
            "functional_access",
            flattenedFunctionalAccess?.map(
              (access: FunctionalAreaItemViewModel) => access.id,
            ) || [],
          );
        }

        if (!formik.touched.adjustment_access) {
          formik.setFieldValue(
            "adjustment_access",
            flattenedAdjustmentAccess?.map(
              (access: AccordionsViewModel) => access.id,
            ) || AppConstant.defaultAdjustmentAccess,
          );
        }
      } else {
        if (!formik.touched.countries) {
          formik.setFieldValue("countries", []);
        }

        if (!formik.touched.functional_access) {
          formik.setFieldValue("functional_access", []);
        }

        if (!formik.touched.adjustment_access) {
          formik.setFieldValue(
            "adjustment_access",
            AppConstant.defaultAdjustmentAccess,
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.email,
    formik.touched.functional_access,
    formik.touched.adjustment_access,
    formik.touched.countries,
    isEditing,
  ]);

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" p={1}>
        <IconButton
          aria-label="delete"
          sx={{ aspectRatio: 1 }}
          onClick={modal.closeModal}
        >
          <CloseSharpIcon />
        </IconButton>
      </Box>
      <Box mb={1}>
        <Typography textAlign="center" variant="h3" fontWeight="bold" mb={4}>
          {isEditing
            ? t("userManagementPage.upsertUser.editUser.title")
            : t("userManagementPage.upsertUser.createUser.title")}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          width={"400px"}
          mx={9}
          mb={3}
        >
          <Box>
            <Typography variant="h6" mb={2}>
              {t("userManagementPage.upsertUser.userInfoSubheading")}
            </Typography>
            {userInfoFields.map((field: UserInfoField) => (
              <TextField
                fullWidth
                name={field.name}
                label={field.label}
                placeholder={t(field.placeholder)}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[field.name] &&
                  Boolean(formik.errors[field.name])
                }
                disabled={field.name === "email" && isEditing}
                helperText={
                  formik.touched[field.name] && formik.errors[field.name]
                }
                required
                key={`${field.name}`}
                sx={{ marginBottom: 2, height: "5em" }}
              />
            ))}
            {territoryData && (
              <Autocomplete
                disablePortal
                disableClearable
                options={[{ country_code: "", name: "" }, ...territoryData]}
                filterOptions={(options, state) =>
                  state.inputValue.trim()
                    ? options.filter(
                        (option) =>
                          option.name !== "" &&
                          option.name
                            .toLocaleLowerCase()
                            .includes(
                              state.inputValue.trim().toLocaleLowerCase(),
                            ),
                      )
                    : options.filter((option) => option.name !== "")
                }
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.country_code === value.country_code
                }
                fullWidth
                value={
                  territoryData.find(
                    (country) =>
                      country.country_code === formik.values.territory,
                  ) || { country_code: "", name: "" }
                }
                onChange={(_e, value) =>
                  formik.setFieldValue("territory", value?.country_code)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Territory"
                    name="territory"
                    onBlur={formik.handleBlur}
                    required
                    error={
                      formik.touched.territory &&
                      Boolean(formik.errors.territory)
                    }
                    helperText={
                      formik.touched.territory &&
                      Boolean(formik.errors.territory) &&
                      formik.errors.territory
                    }
                  />
                )}
              />
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px #CBCBCB solid",
              marginTop: 2,
            }}
          ></Box>
          <Box>
            <Typography variant="h6" mb={2}>
              {t("userManagementPage.upsertUser.permissionsSubheading")}
            </Typography>
            {accessFields.map((field: AccessField) => (
              <>
                <FormControl
                  required
                  fullWidth
                  sx={{
                    marginBottom: field.title === "Adjustment Access" ? -2 : 2,
                    height: "5em",
                  }}
                  key={field.title}
                >
                  <SelectDropdown
                    listItems={field.listItems}
                    onSave={field.onSave}
                    savedSelectedItems={field.savedSelectedItems}
                    title={field.title}
                    requireSave={false}
                    showSearchBar
                    selectAll
                    formikLabel={field.formikLabel}
                    helperText={field.helperText}
                    onOpen={field.onOpen}
                    disabledItems={field.disabledItems}
                  />
                </FormControl>
                {field.title === "Adjustment Access" && (
                  <Box mb={2}>
                    <Typography variant={"body2"}>
                      {t("userManagementPage.servingSizeSelectedByDefault")}
                    </Typography>
                  </Box>
                )}
              </>
            ))}
          </Box>
          <Stack direction="row" spacing={2} margin="auto" marginTop={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => modal.closeModal()}
            >
              {t("common:actions.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!formik.dirty || !formik.isValid}
            >
              {t("common:actions.save")}
            </Button>
          </Stack>
        </Box>
      </form>
    </Box>
  );
};
