import { round } from "lodash";
import {
  MekkoBaseAbsolutePerLitre,
  MekkoBaseGlideScenario,
  MekkoResult,
  MekkoResultByPillar,
} from "../../../../orval/generated/models";

type ChartDimension = {
  id: string;
  value: string;
};

type ChartData = { [key: string]: string | number };

export type MekkoChartData = {
  dimensions: ChartDimension[];
  data: ChartData[];
};

const otherCategoryLimit: number = 5;

const pillars: (keyof MekkoResultByPillar)[] = [
  "ingredients",
  "packaging",
  "manufacturing",
  "logistics",
  "cde",
] as (keyof MekkoResultByPillar)[];

export function TransformMekkoData(
  rawData: MekkoResult,
  selectedYear: keyof MekkoBaseGlideScenario,
  selectedUnit: keyof MekkoBaseAbsolutePerLitre,
): MekkoChartData {
  const datasets: ChartData[] = [];
  let dimensions: string[] = [];

  pillars.forEach((pillar) => {
    let pillarDimensions: string[] = [];
    const data: { [key: string]: string | number } = {
      pillar: pillar,
    };
    const pillarDataKeys: { [key: string]: number } = {};
    const pillarData = rawData.by_pillar[pillar];

    if (!pillarData) {
      console.error(`pillarData is null or undefined for pillar: ${pillar}`);
      return;
    }

    const total = Object.values(pillarData).reduce(
      (acc: number, value: MekkoBaseGlideScenario) => {
        const subPillarValue: number = value[selectedYear]?.[selectedUnit] ?? 0;
        return acc + subPillarValue;
      },
      0,
    );

    let otherPercentage = 0;
    let percentageSum = 0;
    let higherPercentage = { name: "", value: 0 };

    Object.entries(pillarData).forEach(([key, value]) => {
      const keyValue: number | undefined =
        value[selectedYear]?.[selectedUnit] ?? 0;
      let percentageValue = (keyValue / total) * 100;

      if (percentageValue <= 0) {
        return;
      }
      if (percentageValue < otherCategoryLimit) {
        otherPercentage += percentageValue;
      } else {
        data[key] = percentageValue;
        pillarDataKeys[key] = percentageValue;

        percentageSum += round(percentageValue, 1);

        if (data[key] > higherPercentage.value) {
          higherPercentage = { name: key, value: data[key] as number };
        }
      }
    });
    if (otherPercentage > 0) {
      data[`other_${pillar}`] = otherPercentage;
      pillarDataKeys[`other_${pillar}`] = otherPercentage;

      percentageSum += round(otherPercentage, 1);
    }

    if (100 - percentageSum !== 0) {
      data[higherPercentage.name] =
        higherPercentage.value + (100 - percentageSum);

      pillarDataKeys[higherPercentage.name] =
        higherPercentage.value + (100 - percentageSum);
    }

    const entries = Object.entries(pillarDataKeys);
    const sortedEntries = entries.sort((a, b) => b[1] - a[1]);
    pillarDimensions = sortedEntries.map(([key]) => key);

    dimensions = [...dimensions, ...pillarDimensions];

    data["total"] = total;
    datasets.push(data);
  });

  const totalDataInput = datasets.reduce(
    (acc, value) => acc + Number(value.total),
    0,
  );
  const dataSet = datasets.map((item) => {
    const itemTotal: number = (item["total"] as number) ?? 0;
    return {
      weighting: (itemTotal / totalDataInput) * 100,
      ...item,
    };
  });

  return {
    dimensions: dimensions.map(
      (item) => ({ id: item, value: item }) as ChartDimension,
    ),
    data: dataSet,
  };
}

export function extractIds(data: { id: string; value: string }[]): string[] {
  const ids: string[] = [];

  data.forEach((item) => {
    ids.push(item.id);
  });

  return ids;
}
