import { Box, Chip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { CustomIcon, Icons } from "../../../../../../components/common";

export type AccessChipProps = {
  items: string[];
  itemType: string;
  totalItems: number;
  passThroughTestId: string;
};

const AccessChip = ({
  items,
  itemType,
  totalItems,
  passThroughTestId,
}: AccessChipProps) => {
  const allItemsSelected = items.length === totalItems;
  return (
    <Tooltip
      title={
        items.length === 0 ? undefined : items.length > 10 ? (
          items.join(", ")
        ) : (
          <ul style={{ margin: 0, padding: 0 }}>
            {items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )
      }
    >
      <Chip
        variant="outlined"
        icon={
          allItemsSelected ? (
            <CustomIcon
              name={Icons.CONFIRMED}
              fill={"#20522A"}
              width={"2em"}
              height={"2em"}
            />
          ) : undefined
        }
        avatar={
          !allItemsSelected ? (
            <Avatar
              sx={{
                bgcolor: "#FFFFFF",
                fontWeight: "500",
                marginRight: "4px !important",
              }}
            >
              {items.length}
            </Avatar>
          ) : undefined
        }
        label={`${allItemsSelected ? "All" : ""} ${itemType}`}
        sx={{
          backgroundColor: `${allItemsSelected ? "#A7D6AB" : "#C3D3E2"} !important`,
          fontWeight: "500 !important",
          color: `${allItemsSelected ? "#20522A" : "#40576D"} !important`,
          border: "0px",
          "& .MuiChip-label": {
            marginLeft: "-6px !important",
          },
        }}
        data-testid={passThroughTestId}
      />
    </Tooltip>
  );
};
export default AccessChip;
