/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Product Emissions Forecaster
 * OpenAPI spec version: 3.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  AccordionsViewModel,
  AlertDetailsViewModel,
  AlertRequest,
  AlertStatusUpdate,
  BodyCreateIssueIssuesPost,
  BodyPhaseFourOfIngestIngestPhase4Post,
  BodyPhaseOneOfIngestIngestPhase1Post,
  BodyPhaseThreeOfIngestIngestPhase3Post,
  BodyPhaseTwoOfIngestIngestPhase2Post,
  BodyUploadBulkUserMembersUploadPost,
  BulkMemberPermissionsModel,
  CategoryViewModel,
  CountryViewModel,
  CreateMemberModel,
  CreateMicroAdjustment,
  DataExtractRequest,
  EditMemberModel,
  EditMicroAdjustment,
  EditScenarioSkuModel,
  FunctionalAreaItemViewModel,
  GetAlertDetailsForAdminPanelAlertsGet200,
  GetDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGet200,
  GetDataManagementNumericDataDataManagementExportAvailableDataNumericGet200,
  GumCountryViewModel,
  HTTPValidationError,
  LegalPolicyViewModel,
  MemberDetails,
  Metadata,
  PackTypeViewModel,
  Product,
  SKUMinimalViewModel,
  SKUProductPosition,
  TermsAndConditionsStatusViewModel,
  UpdateMacroScenarioViewModel,
  UpdateScenarioDetails,
  VersionHistoryViewModel,
  ViewMacroScenarioViewModel,
  ViewMicroAdjustment,
  ViewMicroScenario,
  YearViewModel,
} from "./models";
import { withCustomAxios } from "../mutator/custom-instance";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Info
 */
export const getInfoInfoGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<unknown>({ url: `/info`, method: "GET" }, options);
};

export const getGetInfoInfoGetQueryKey = () => {
  return [`/info`] as const;
};

export const getGetInfoInfoGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getInfoInfoGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getInfoInfoGet>>, TError, TData>
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInfoInfoGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInfoInfoGet>>
  > = () => getInfoInfoGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInfoInfoGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInfoInfoGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInfoInfoGet>>
>;
export type GetInfoInfoGetQueryError = unknown;

/**
 * @summary Get Info
 */
export const useGetInfoInfoGet = <
  TData = Awaited<ReturnType<typeof getInfoInfoGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getInfoInfoGet>>, TError, TData>
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInfoInfoGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Skus
 */
export const getSkusSkusGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<SKUMinimalViewModel[]>(
    { url: `/skus`, method: "GET" },
    options,
  );
};

export const getGetSkusSkusGetQueryKey = () => {
  return [`/skus`] as const;
};

export const getGetSkusSkusGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getSkusSkusGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSkusSkusGet>>, TError, TData>
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSkusSkusGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSkusSkusGet>>
  > = () => getSkusSkusGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSkusSkusGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSkusSkusGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSkusSkusGet>>
>;
export type GetSkusSkusGetQueryError = unknown;

/**
 * @summary Get Skus
 */
export const useGetSkusSkusGet = <
  TData = Awaited<ReturnType<typeof getSkusSkusGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSkusSkusGet>>, TError, TData>
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSkusSkusGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Delete Scenario By Id
 */
export const deleteScenarioByIdScenariosScenarioIdDelete = (
  scenarioId: number,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<void>(
    { url: `/scenarios/${scenarioId}`, method: "DELETE" },
    options,
  );
};

export const getDeleteScenarioByIdScenariosScenarioIdDeleteMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteScenarioByIdScenariosScenarioIdDelete>>,
    TError,
    { scenarioId: number },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteScenarioByIdScenariosScenarioIdDelete>>,
  TError,
  { scenarioId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteScenarioByIdScenariosScenarioIdDelete>>,
    { scenarioId: number }
  > = (props) => {
    const { scenarioId } = props ?? {};

    return deleteScenarioByIdScenariosScenarioIdDelete(
      scenarioId,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteScenarioByIdScenariosScenarioIdDeleteMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof deleteScenarioByIdScenariosScenarioIdDelete>>
  >;

export type DeleteScenarioByIdScenariosScenarioIdDeleteMutationError =
  HTTPValidationError;

/**
 * @summary Delete Scenario By Id
 */
export const useDeleteScenarioByIdScenariosScenarioIdDelete = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteScenarioByIdScenariosScenarioIdDelete>>,
    TError,
    { scenarioId: number },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteScenarioByIdScenariosScenarioIdDelete>>,
  TError,
  { scenarioId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteScenarioByIdScenariosScenarioIdDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Years
 */
export const getYearsYearsGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<YearViewModel[]>(
    { url: `/years`, method: "GET" },
    options,
  );
};

export const getGetYearsYearsGetQueryKey = () => {
  return [`/years`] as const;
};

export const getGetYearsYearsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getYearsYearsGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getYearsYearsGet>>, TError, TData>
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetYearsYearsGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getYearsYearsGet>>
  > = () => getYearsYearsGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getYearsYearsGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetYearsYearsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getYearsYearsGet>>
>;
export type GetYearsYearsGetQueryError = unknown;

/**
 * @summary Get Years
 */
export const useGetYearsYearsGet = <
  TData = Awaited<ReturnType<typeof getYearsYearsGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getYearsYearsGet>>, TError, TData>
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetYearsYearsGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Countries
 */
export const getCountriesCountriesGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<CountryViewModel[]>(
    { url: `/countries`, method: "GET" },
    options,
  );
};

export const getGetCountriesCountriesGetQueryKey = () => {
  return [`/countries`] as const;
};

export const getGetCountriesCountriesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getCountriesCountriesGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCountriesCountriesGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCountriesCountriesGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCountriesCountriesGet>>
  > = () => getCountriesCountriesGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCountriesCountriesGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCountriesCountriesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCountriesCountriesGet>>
>;
export type GetCountriesCountriesGetQueryError = unknown;

/**
 * @summary Get Countries
 */
export const useGetCountriesCountriesGet = <
  TData = Awaited<ReturnType<typeof getCountriesCountriesGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCountriesCountriesGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCountriesCountriesGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Gum Territories
 */
export const getGumTerritoriesTerritoriesGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<GumCountryViewModel[]>(
    { url: `/territories`, method: "GET" },
    options,
  );
};

export const getGetGumTerritoriesTerritoriesGetQueryKey = () => {
  return [`/territories`] as const;
};

export const getGetGumTerritoriesTerritoriesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getGumTerritoriesTerritoriesGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getGumTerritoriesTerritoriesGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetGumTerritoriesTerritoriesGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGumTerritoriesTerritoriesGet>>
  > = () => getGumTerritoriesTerritoriesGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getGumTerritoriesTerritoriesGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGumTerritoriesTerritoriesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGumTerritoriesTerritoriesGet>>
>;
export type GetGumTerritoriesTerritoriesGetQueryError = unknown;

/**
 * @summary Get Gum Territories
 */
export const useGetGumTerritoriesTerritoriesGet = <
  TData = Awaited<ReturnType<typeof getGumTerritoriesTerritoriesGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getGumTerritoriesTerritoriesGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGumTerritoriesTerritoriesGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Backup Country Access For External Users
 */
export const backupCountryAccessForExternalUsersIngestBackupCountryAccessForExternalUsersPost =
  (options?: SecondParameter<typeof withCustomAxios>) => {
    return withCustomAxios<void>(
      {
        url: `/ingest/backup-country-access-for-external-users`,
        method: "POST",
      },
      options,
    );
  };

export const getBackupCountryAccessForExternalUsersIngestBackupCountryAccessForExternalUsersPostMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof backupCountryAccessForExternalUsersIngestBackupCountryAccessForExternalUsersPost
        >
      >,
      TError,
      void,
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof backupCountryAccessForExternalUsersIngestBackupCountryAccessForExternalUsersPost
      >
    >,
    TError,
    void,
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof backupCountryAccessForExternalUsersIngestBackupCountryAccessForExternalUsersPost
        >
      >,
      void
    > = () => {
      return backupCountryAccessForExternalUsersIngestBackupCountryAccessForExternalUsersPost(
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type BackupCountryAccessForExternalUsersIngestBackupCountryAccessForExternalUsersPostMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof backupCountryAccessForExternalUsersIngestBackupCountryAccessForExternalUsersPost
      >
    >
  >;

export type BackupCountryAccessForExternalUsersIngestBackupCountryAccessForExternalUsersPostMutationError =
  unknown;

/**
 * @summary Backup Country Access For External Users
 */
export const useBackupCountryAccessForExternalUsersIngestBackupCountryAccessForExternalUsersPost =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof backupCountryAccessForExternalUsersIngestBackupCountryAccessForExternalUsersPost
        >
      >,
      TError,
      void,
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof backupCountryAccessForExternalUsersIngestBackupCountryAccessForExternalUsersPost
      >
    >,
    TError,
    void,
    TContext
  > => {
    const mutationOptions =
      getBackupCountryAccessForExternalUsersIngestBackupCountryAccessForExternalUsersPostMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary Phase One Of Ingest
 */
export const phaseOneOfIngestIngestPhase1Post = (
  bodyPhaseOneOfIngestIngestPhase1Post: BodyPhaseOneOfIngestIngestPhase1Post,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  const formData = new FormData();
  bodyPhaseOneOfIngestIngestPhase1Post.files.forEach((value) =>
    formData.append("files", value),
  );

  return withCustomAxios<void>(
    {
      url: `/ingest/phase/1`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};

export const getPhaseOneOfIngestIngestPhase1PostMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof phaseOneOfIngestIngestPhase1Post>>,
    TError,
    { data: BodyPhaseOneOfIngestIngestPhase1Post },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof phaseOneOfIngestIngestPhase1Post>>,
  TError,
  { data: BodyPhaseOneOfIngestIngestPhase1Post },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof phaseOneOfIngestIngestPhase1Post>>,
    { data: BodyPhaseOneOfIngestIngestPhase1Post }
  > = (props) => {
    const { data } = props ?? {};

    return phaseOneOfIngestIngestPhase1Post(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PhaseOneOfIngestIngestPhase1PostMutationResult = NonNullable<
  Awaited<ReturnType<typeof phaseOneOfIngestIngestPhase1Post>>
>;
export type PhaseOneOfIngestIngestPhase1PostMutationBody =
  BodyPhaseOneOfIngestIngestPhase1Post;
export type PhaseOneOfIngestIngestPhase1PostMutationError = HTTPValidationError;

/**
 * @summary Phase One Of Ingest
 */
export const usePhaseOneOfIngestIngestPhase1Post = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof phaseOneOfIngestIngestPhase1Post>>,
    TError,
    { data: BodyPhaseOneOfIngestIngestPhase1Post },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof phaseOneOfIngestIngestPhase1Post>>,
  TError,
  { data: BodyPhaseOneOfIngestIngestPhase1Post },
  TContext
> => {
  const mutationOptions =
    getPhaseOneOfIngestIngestPhase1PostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Phase Two Of Ingest
 */
export const phaseTwoOfIngestIngestPhase2Post = (
  bodyPhaseTwoOfIngestIngestPhase2Post: BodyPhaseTwoOfIngestIngestPhase2Post,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  const formData = new FormData();
  bodyPhaseTwoOfIngestIngestPhase2Post.files.forEach((value) =>
    formData.append("files", value),
  );

  return withCustomAxios<void>(
    {
      url: `/ingest/phase/2`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};

export const getPhaseTwoOfIngestIngestPhase2PostMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof phaseTwoOfIngestIngestPhase2Post>>,
    TError,
    { data: BodyPhaseTwoOfIngestIngestPhase2Post },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof phaseTwoOfIngestIngestPhase2Post>>,
  TError,
  { data: BodyPhaseTwoOfIngestIngestPhase2Post },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof phaseTwoOfIngestIngestPhase2Post>>,
    { data: BodyPhaseTwoOfIngestIngestPhase2Post }
  > = (props) => {
    const { data } = props ?? {};

    return phaseTwoOfIngestIngestPhase2Post(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PhaseTwoOfIngestIngestPhase2PostMutationResult = NonNullable<
  Awaited<ReturnType<typeof phaseTwoOfIngestIngestPhase2Post>>
>;
export type PhaseTwoOfIngestIngestPhase2PostMutationBody =
  BodyPhaseTwoOfIngestIngestPhase2Post;
export type PhaseTwoOfIngestIngestPhase2PostMutationError = HTTPValidationError;

/**
 * @summary Phase Two Of Ingest
 */
export const usePhaseTwoOfIngestIngestPhase2Post = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof phaseTwoOfIngestIngestPhase2Post>>,
    TError,
    { data: BodyPhaseTwoOfIngestIngestPhase2Post },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof phaseTwoOfIngestIngestPhase2Post>>,
  TError,
  { data: BodyPhaseTwoOfIngestIngestPhase2Post },
  TContext
> => {
  const mutationOptions =
    getPhaseTwoOfIngestIngestPhase2PostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Phase Three Of Ingest
 */
export const phaseThreeOfIngestIngestPhase3Post = (
  bodyPhaseThreeOfIngestIngestPhase3Post: BodyPhaseThreeOfIngestIngestPhase3Post,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  const formData = new FormData();
  bodyPhaseThreeOfIngestIngestPhase3Post.files.forEach((value) =>
    formData.append("files", value),
  );

  return withCustomAxios<void>(
    {
      url: `/ingest/phase/3`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};

export const getPhaseThreeOfIngestIngestPhase3PostMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof phaseThreeOfIngestIngestPhase3Post>>,
    TError,
    { data: BodyPhaseThreeOfIngestIngestPhase3Post },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof phaseThreeOfIngestIngestPhase3Post>>,
  TError,
  { data: BodyPhaseThreeOfIngestIngestPhase3Post },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof phaseThreeOfIngestIngestPhase3Post>>,
    { data: BodyPhaseThreeOfIngestIngestPhase3Post }
  > = (props) => {
    const { data } = props ?? {};

    return phaseThreeOfIngestIngestPhase3Post(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PhaseThreeOfIngestIngestPhase3PostMutationResult = NonNullable<
  Awaited<ReturnType<typeof phaseThreeOfIngestIngestPhase3Post>>
>;
export type PhaseThreeOfIngestIngestPhase3PostMutationBody =
  BodyPhaseThreeOfIngestIngestPhase3Post;
export type PhaseThreeOfIngestIngestPhase3PostMutationError =
  HTTPValidationError;

/**
 * @summary Phase Three Of Ingest
 */
export const usePhaseThreeOfIngestIngestPhase3Post = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof phaseThreeOfIngestIngestPhase3Post>>,
    TError,
    { data: BodyPhaseThreeOfIngestIngestPhase3Post },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof phaseThreeOfIngestIngestPhase3Post>>,
  TError,
  { data: BodyPhaseThreeOfIngestIngestPhase3Post },
  TContext
> => {
  const mutationOptions =
    getPhaseThreeOfIngestIngestPhase3PostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Phase Four Of Ingest
 */
export const phaseFourOfIngestIngestPhase4Post = (
  bodyPhaseFourOfIngestIngestPhase4Post: BodyPhaseFourOfIngestIngestPhase4Post,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  const formData = new FormData();
  bodyPhaseFourOfIngestIngestPhase4Post.files.forEach((value) =>
    formData.append("files", value),
  );

  return withCustomAxios<void>(
    {
      url: `/ingest/phase/4`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};

export const getPhaseFourOfIngestIngestPhase4PostMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof phaseFourOfIngestIngestPhase4Post>>,
    TError,
    { data: BodyPhaseFourOfIngestIngestPhase4Post },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof phaseFourOfIngestIngestPhase4Post>>,
  TError,
  { data: BodyPhaseFourOfIngestIngestPhase4Post },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof phaseFourOfIngestIngestPhase4Post>>,
    { data: BodyPhaseFourOfIngestIngestPhase4Post }
  > = (props) => {
    const { data } = props ?? {};

    return phaseFourOfIngestIngestPhase4Post(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PhaseFourOfIngestIngestPhase4PostMutationResult = NonNullable<
  Awaited<ReturnType<typeof phaseFourOfIngestIngestPhase4Post>>
>;
export type PhaseFourOfIngestIngestPhase4PostMutationBody =
  BodyPhaseFourOfIngestIngestPhase4Post;
export type PhaseFourOfIngestIngestPhase4PostMutationError =
  HTTPValidationError;

/**
 * @summary Phase Four Of Ingest
 */
export const usePhaseFourOfIngestIngestPhase4Post = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof phaseFourOfIngestIngestPhase4Post>>,
    TError,
    { data: BodyPhaseFourOfIngestIngestPhase4Post },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof phaseFourOfIngestIngestPhase4Post>>,
  TError,
  { data: BodyPhaseFourOfIngestIngestPhase4Post },
  TContext
> => {
  const mutationOptions =
    getPhaseFourOfIngestIngestPhase4PostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Restore Country Access For External Users
 */
export const restoreCountryAccessForExternalUsersIngestRestoreCountryAccessForExternalUsersPost =
  (options?: SecondParameter<typeof withCustomAxios>) => {
    return withCustomAxios<void>(
      {
        url: `/ingest/restore-country-access-for-external-users`,
        method: "POST",
      },
      options,
    );
  };

export const getRestoreCountryAccessForExternalUsersIngestRestoreCountryAccessForExternalUsersPostMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof restoreCountryAccessForExternalUsersIngestRestoreCountryAccessForExternalUsersPost
        >
      >,
      TError,
      void,
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof restoreCountryAccessForExternalUsersIngestRestoreCountryAccessForExternalUsersPost
      >
    >,
    TError,
    void,
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof restoreCountryAccessForExternalUsersIngestRestoreCountryAccessForExternalUsersPost
        >
      >,
      void
    > = () => {
      return restoreCountryAccessForExternalUsersIngestRestoreCountryAccessForExternalUsersPost(
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type RestoreCountryAccessForExternalUsersIngestRestoreCountryAccessForExternalUsersPostMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof restoreCountryAccessForExternalUsersIngestRestoreCountryAccessForExternalUsersPost
      >
    >
  >;

export type RestoreCountryAccessForExternalUsersIngestRestoreCountryAccessForExternalUsersPostMutationError =
  unknown;

/**
 * @summary Restore Country Access For External Users
 */
export const useRestoreCountryAccessForExternalUsersIngestRestoreCountryAccessForExternalUsersPost =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof restoreCountryAccessForExternalUsersIngestRestoreCountryAccessForExternalUsersPost
        >
      >,
      TError,
      void,
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof restoreCountryAccessForExternalUsersIngestRestoreCountryAccessForExternalUsersPost
      >
    >,
    TError,
    void,
    TContext
  > => {
    const mutationOptions =
      getRestoreCountryAccessForExternalUsersIngestRestoreCountryAccessForExternalUsersPostMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary Grant All Country Access To Pwc Users
 */
export const grantAllCountryAccessToPwcUsersIngestGrantAllCountryAccessToPwcUsersPost =
  (options?: SecondParameter<typeof withCustomAxios>) => {
    return withCustomAxios<void>(
      { url: `/ingest/grant_all_country_access_to_pwc_users`, method: "POST" },
      options,
    );
  };

export const getGrantAllCountryAccessToPwcUsersIngestGrantAllCountryAccessToPwcUsersPostMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof grantAllCountryAccessToPwcUsersIngestGrantAllCountryAccessToPwcUsersPost
        >
      >,
      TError,
      void,
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof grantAllCountryAccessToPwcUsersIngestGrantAllCountryAccessToPwcUsersPost
      >
    >,
    TError,
    void,
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof grantAllCountryAccessToPwcUsersIngestGrantAllCountryAccessToPwcUsersPost
        >
      >,
      void
    > = () => {
      return grantAllCountryAccessToPwcUsersIngestGrantAllCountryAccessToPwcUsersPost(
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type GrantAllCountryAccessToPwcUsersIngestGrantAllCountryAccessToPwcUsersPostMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof grantAllCountryAccessToPwcUsersIngestGrantAllCountryAccessToPwcUsersPost
      >
    >
  >;

export type GrantAllCountryAccessToPwcUsersIngestGrantAllCountryAccessToPwcUsersPostMutationError =
  unknown;

/**
 * @summary Grant All Country Access To Pwc Users
 */
export const useGrantAllCountryAccessToPwcUsersIngestGrantAllCountryAccessToPwcUsersPost =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof grantAllCountryAccessToPwcUsersIngestGrantAllCountryAccessToPwcUsersPost
        >
      >,
      TError,
      void,
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof grantAllCountryAccessToPwcUsersIngestGrantAllCountryAccessToPwcUsersPost
      >
    >,
    TError,
    void,
    TContext
  > => {
    const mutationOptions =
      getGrantAllCountryAccessToPwcUsersIngestGrantAllCountryAccessToPwcUsersPostMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary Populate Fields
 */
export const populateFieldsIngestPopulateFieldsPost = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<void>(
    { url: `/ingest/populate_fields`, method: "POST" },
    options,
  );
};

export const getPopulateFieldsIngestPopulateFieldsPostMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof populateFieldsIngestPopulateFieldsPost>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof populateFieldsIngestPopulateFieldsPost>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof populateFieldsIngestPopulateFieldsPost>>,
    void
  > = () => {
    return populateFieldsIngestPopulateFieldsPost(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PopulateFieldsIngestPopulateFieldsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof populateFieldsIngestPopulateFieldsPost>>
>;

export type PopulateFieldsIngestPopulateFieldsPostMutationError = unknown;

/**
 * @summary Populate Fields
 */
export const usePopulateFieldsIngestPopulateFieldsPost = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof populateFieldsIngestPopulateFieldsPost>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof populateFieldsIngestPopulateFieldsPost>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getPopulateFieldsIngestPopulateFieldsPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Populate Base Data
 */
export const populateBaseDataIngestPopulateBaseDataPost = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<string>(
    { url: `/ingest/populate_base_data`, method: "POST" },
    options,
  );
};

export const getPopulateBaseDataIngestPopulateBaseDataPostMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof populateBaseDataIngestPopulateBaseDataPost>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof populateBaseDataIngestPopulateBaseDataPost>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof populateBaseDataIngestPopulateBaseDataPost>>,
    void
  > = () => {
    return populateBaseDataIngestPopulateBaseDataPost(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PopulateBaseDataIngestPopulateBaseDataPostMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof populateBaseDataIngestPopulateBaseDataPost>>
  >;

export type PopulateBaseDataIngestPopulateBaseDataPostMutationError = unknown;

/**
 * @summary Populate Base Data
 */
export const usePopulateBaseDataIngestPopulateBaseDataPost = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof populateBaseDataIngestPopulateBaseDataPost>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof populateBaseDataIngestPopulateBaseDataPost>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getPopulateBaseDataIngestPopulateBaseDataPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Base Data Is Populated
 */
export const baseDataIsPopulatedIngestPopulateBaseDataCheckPost = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<unknown>(
    { url: `/ingest/populate_base_data/check`, method: "POST" },
    options,
  );
};

export const getBaseDataIsPopulatedIngestPopulateBaseDataCheckPostMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof baseDataIsPopulatedIngestPopulateBaseDataCheckPost>
      >,
      TError,
      void,
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof baseDataIsPopulatedIngestPopulateBaseDataCheckPost>
    >,
    TError,
    void,
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof baseDataIsPopulatedIngestPopulateBaseDataCheckPost>
      >,
      void
    > = () => {
      return baseDataIsPopulatedIngestPopulateBaseDataCheckPost(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
  };

export type BaseDataIsPopulatedIngestPopulateBaseDataCheckPostMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof baseDataIsPopulatedIngestPopulateBaseDataCheckPost>
    >
  >;

export type BaseDataIsPopulatedIngestPopulateBaseDataCheckPostMutationError =
  unknown;

/**
 * @summary Base Data Is Populated
 */
export const useBaseDataIsPopulatedIngestPopulateBaseDataCheckPost = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof baseDataIsPopulatedIngestPopulateBaseDataCheckPost>
    >,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof baseDataIsPopulatedIngestPopulateBaseDataCheckPost>
  >,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getBaseDataIsPopulatedIngestPopulateBaseDataCheckPostMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};

/**
 * @summary Clear For Ingest
 */
export const clearForIngestIngestClearPost = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<void>(
    { url: `/ingest/clear`, method: "POST" },
    options,
  );
};

export const getClearForIngestIngestClearPostMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof clearForIngestIngestClearPost>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof clearForIngestIngestClearPost>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof clearForIngestIngestClearPost>>,
    void
  > = () => {
    return clearForIngestIngestClearPost(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ClearForIngestIngestClearPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof clearForIngestIngestClearPost>>
>;

export type ClearForIngestIngestClearPostMutationError = unknown;

/**
 * @summary Clear For Ingest
 */
export const useClearForIngestIngestClearPost = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof clearForIngestIngestClearPost>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof clearForIngestIngestClearPost>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getClearForIngestIngestClearPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Ingest Calculations
 */
export const ingestCalculationsIngestCalculationsPost = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<string>(
    { url: `/ingest/calculations`, method: "POST" },
    options,
  );
};

export const getIngestCalculationsIngestCalculationsPostMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ingestCalculationsIngestCalculationsPost>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof ingestCalculationsIngestCalculationsPost>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ingestCalculationsIngestCalculationsPost>>,
    void
  > = () => {
    return ingestCalculationsIngestCalculationsPost(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IngestCalculationsIngestCalculationsPostMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof ingestCalculationsIngestCalculationsPost>>
  >;

export type IngestCalculationsIngestCalculationsPostMutationError = unknown;

/**
 * @summary Ingest Calculations
 */
export const useIngestCalculationsIngestCalculationsPost = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ingestCalculationsIngestCalculationsPost>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof ingestCalculationsIngestCalculationsPost>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getIngestCalculationsIngestCalculationsPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Calculations Have Completed
 */
export const calculationsHaveCompletedIngestCalculationsCheckPost = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<unknown>(
    { url: `/ingest/calculations/check`, method: "POST" },
    options,
  );
};

export const getCalculationsHaveCompletedIngestCalculationsCheckPostMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof calculationsHaveCompletedIngestCalculationsCheckPost>
      >,
      TError,
      void,
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof calculationsHaveCompletedIngestCalculationsCheckPost>
    >,
    TError,
    void,
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof calculationsHaveCompletedIngestCalculationsCheckPost>
      >,
      void
    > = () => {
      return calculationsHaveCompletedIngestCalculationsCheckPost(
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type CalculationsHaveCompletedIngestCalculationsCheckPostMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof calculationsHaveCompletedIngestCalculationsCheckPost>
    >
  >;

export type CalculationsHaveCompletedIngestCalculationsCheckPostMutationError =
  unknown;

/**
 * @summary Calculations Have Completed
 */
export const useCalculationsHaveCompletedIngestCalculationsCheckPost = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof calculationsHaveCompletedIngestCalculationsCheckPost>
    >,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof calculationsHaveCompletedIngestCalculationsCheckPost>
  >,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getCalculationsHaveCompletedIngestCalculationsCheckPostMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};

/**
 * @summary Get App Info
 */
export const getAppInfoMetadataGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<Metadata>(
    { url: `/metadata`, method: "GET" },
    options,
  );
};

export const getGetAppInfoMetadataGetQueryKey = () => {
  return [`/metadata`] as const;
};

export const getGetAppInfoMetadataGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getAppInfoMetadataGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAppInfoMetadataGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAppInfoMetadataGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAppInfoMetadataGet>>
  > = () => getAppInfoMetadataGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAppInfoMetadataGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAppInfoMetadataGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAppInfoMetadataGet>>
>;
export type GetAppInfoMetadataGetQueryError = unknown;

/**
 * @summary Get App Info
 */
export const useGetAppInfoMetadataGet = <
  TData = Awaited<ReturnType<typeof getAppInfoMetadataGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAppInfoMetadataGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAppInfoMetadataGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Privacy Policy
 */
export const getPrivacyPolicyLegalPrivacyStatementGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<LegalPolicyViewModel>(
    { url: `/legal/privacy-statement`, method: "GET" },
    options,
  );
};

export const getGetPrivacyPolicyLegalPrivacyStatementGetQueryKey = () => {
  return [`/legal/privacy-statement`] as const;
};

export const getGetPrivacyPolicyLegalPrivacyStatementGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getPrivacyPolicyLegalPrivacyStatementGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPrivacyPolicyLegalPrivacyStatementGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPrivacyPolicyLegalPrivacyStatementGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPrivacyPolicyLegalPrivacyStatementGet>>
  > = () => getPrivacyPolicyLegalPrivacyStatementGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPrivacyPolicyLegalPrivacyStatementGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPrivacyPolicyLegalPrivacyStatementGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPrivacyPolicyLegalPrivacyStatementGet>>
>;
export type GetPrivacyPolicyLegalPrivacyStatementGetQueryError = unknown;

/**
 * @summary Get Privacy Policy
 */
export const useGetPrivacyPolicyLegalPrivacyStatementGet = <
  TData = Awaited<ReturnType<typeof getPrivacyPolicyLegalPrivacyStatementGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPrivacyPolicyLegalPrivacyStatementGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetPrivacyPolicyLegalPrivacyStatementGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Cookies Policy
 */
export const getCookiesPolicyLegalCookieStatementGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<LegalPolicyViewModel>(
    { url: `/legal/cookie-statement`, method: "GET" },
    options,
  );
};

export const getGetCookiesPolicyLegalCookieStatementGetQueryKey = () => {
  return [`/legal/cookie-statement`] as const;
};

export const getGetCookiesPolicyLegalCookieStatementGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getCookiesPolicyLegalCookieStatementGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCookiesPolicyLegalCookieStatementGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCookiesPolicyLegalCookieStatementGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCookiesPolicyLegalCookieStatementGet>>
  > = () => getCookiesPolicyLegalCookieStatementGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCookiesPolicyLegalCookieStatementGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCookiesPolicyLegalCookieStatementGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCookiesPolicyLegalCookieStatementGet>>
>;
export type GetCookiesPolicyLegalCookieStatementGetQueryError = unknown;

/**
 * @summary Get Cookies Policy
 */
export const useGetCookiesPolicyLegalCookieStatementGet = <
  TData = Awaited<ReturnType<typeof getCookiesPolicyLegalCookieStatementGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCookiesPolicyLegalCookieStatementGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetCookiesPolicyLegalCookieStatementGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Terms And Conditions
 */
export const getTermsAndConditionsLegalTermsOfUseGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<LegalPolicyViewModel>(
    { url: `/legal/terms-of-use`, method: "GET" },
    options,
  );
};

export const getGetTermsAndConditionsLegalTermsOfUseGetQueryKey = () => {
  return [`/legal/terms-of-use`] as const;
};

export const getGetTermsAndConditionsLegalTermsOfUseGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getTermsAndConditionsLegalTermsOfUseGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getTermsAndConditionsLegalTermsOfUseGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetTermsAndConditionsLegalTermsOfUseGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTermsAndConditionsLegalTermsOfUseGet>>
  > = () => getTermsAndConditionsLegalTermsOfUseGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTermsAndConditionsLegalTermsOfUseGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTermsAndConditionsLegalTermsOfUseGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTermsAndConditionsLegalTermsOfUseGet>>
>;
export type GetTermsAndConditionsLegalTermsOfUseGetQueryError = unknown;

/**
 * @summary Get Terms And Conditions
 */
export const useGetTermsAndConditionsLegalTermsOfUseGet = <
  TData = Awaited<ReturnType<typeof getTermsAndConditionsLegalTermsOfUseGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getTermsAndConditionsLegalTermsOfUseGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetTermsAndConditionsLegalTermsOfUseGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get User Acceptance Status
 */
export const getUserAcceptanceStatusLegalTermsOfUseStatusGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<TermsAndConditionsStatusViewModel>(
    { url: `/legal/terms-of-use/status`, method: "GET" },
    options,
  );
};

export const getGetUserAcceptanceStatusLegalTermsOfUseStatusGetQueryKey =
  () => {
    return [`/legal/terms-of-use/status`] as const;
  };

export const getGetUserAcceptanceStatusLegalTermsOfUseStatusGetQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getUserAcceptanceStatusLegalTermsOfUseStatusGet>
  >,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<typeof getUserAcceptanceStatusLegalTermsOfUseStatusGet>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetUserAcceptanceStatusLegalTermsOfUseStatusGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserAcceptanceStatusLegalTermsOfUseStatusGet>>
  > = () => getUserAcceptanceStatusLegalTermsOfUseStatusGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserAcceptanceStatusLegalTermsOfUseStatusGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserAcceptanceStatusLegalTermsOfUseStatusGetQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getUserAcceptanceStatusLegalTermsOfUseStatusGet>>
  >;
export type GetUserAcceptanceStatusLegalTermsOfUseStatusGetQueryError = unknown;

/**
 * @summary Get User Acceptance Status
 */
export const useGetUserAcceptanceStatusLegalTermsOfUseStatusGet = <
  TData = Awaited<
    ReturnType<typeof getUserAcceptanceStatusLegalTermsOfUseStatusGet>
  >,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<typeof getUserAcceptanceStatusLegalTermsOfUseStatusGet>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetUserAcceptanceStatusLegalTermsOfUseStatusGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update User Terms And Conditions Status Async
 */
export const updateUserTermsAndConditionsStatusAsyncLegalTermsOfUseStatusPut = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<void>(
    { url: `/legal/terms-of-use/status`, method: "PUT" },
    options,
  );
};

export const getUpdateUserTermsAndConditionsStatusAsyncLegalTermsOfUseStatusPutMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof updateUserTermsAndConditionsStatusAsyncLegalTermsOfUseStatusPut
        >
      >,
      TError,
      void,
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof updateUserTermsAndConditionsStatusAsyncLegalTermsOfUseStatusPut
      >
    >,
    TError,
    void,
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof updateUserTermsAndConditionsStatusAsyncLegalTermsOfUseStatusPut
        >
      >,
      void
    > = () => {
      return updateUserTermsAndConditionsStatusAsyncLegalTermsOfUseStatusPut(
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type UpdateUserTermsAndConditionsStatusAsyncLegalTermsOfUseStatusPutMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof updateUserTermsAndConditionsStatusAsyncLegalTermsOfUseStatusPut
      >
    >
  >;

export type UpdateUserTermsAndConditionsStatusAsyncLegalTermsOfUseStatusPutMutationError =
  unknown;

/**
 * @summary Update User Terms And Conditions Status Async
 */
export const useUpdateUserTermsAndConditionsStatusAsyncLegalTermsOfUseStatusPut =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof updateUserTermsAndConditionsStatusAsyncLegalTermsOfUseStatusPut
        >
      >,
      TError,
      void,
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof updateUserTermsAndConditionsStatusAsyncLegalTermsOfUseStatusPut
      >
    >,
    TError,
    void,
    TContext
  > => {
    const mutationOptions =
      getUpdateUserTermsAndConditionsStatusAsyncLegalTermsOfUseStatusPutMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary Get Alert Details For Admin Panel
 */
export const getAlertDetailsForAdminPanelAlertsGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<GetAlertDetailsForAdminPanelAlertsGet200>(
    { url: `/alerts`, method: "GET" },
    options,
  );
};

export const getGetAlertDetailsForAdminPanelAlertsGetQueryKey = () => {
  return [`/alerts`] as const;
};

export const getGetAlertDetailsForAdminPanelAlertsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getAlertDetailsForAdminPanelAlertsGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAlertDetailsForAdminPanelAlertsGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAlertDetailsForAdminPanelAlertsGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAlertDetailsForAdminPanelAlertsGet>>
  > = () => getAlertDetailsForAdminPanelAlertsGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAlertDetailsForAdminPanelAlertsGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAlertDetailsForAdminPanelAlertsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAlertDetailsForAdminPanelAlertsGet>>
>;
export type GetAlertDetailsForAdminPanelAlertsGetQueryError = unknown;

/**
 * @summary Get Alert Details For Admin Panel
 */
export const useGetAlertDetailsForAdminPanelAlertsGet = <
  TData = Awaited<ReturnType<typeof getAlertDetailsForAdminPanelAlertsGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAlertDetailsForAdminPanelAlertsGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetAlertDetailsForAdminPanelAlertsGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create Alert
 */
export const createAlertAlertsPost = (
  alertRequest: AlertRequest,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<AlertDetailsViewModel>(
    {
      url: `/alerts`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: alertRequest,
    },
    options,
  );
};

export const getCreateAlertAlertsPostMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAlertAlertsPost>>,
    TError,
    { data: AlertRequest },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createAlertAlertsPost>>,
  TError,
  { data: AlertRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createAlertAlertsPost>>,
    { data: AlertRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createAlertAlertsPost(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateAlertAlertsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createAlertAlertsPost>>
>;
export type CreateAlertAlertsPostMutationBody = AlertRequest;
export type CreateAlertAlertsPostMutationError = HTTPValidationError;

/**
 * @summary Create Alert
 */
export const useCreateAlertAlertsPost = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAlertAlertsPost>>,
    TError,
    { data: AlertRequest },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createAlertAlertsPost>>,
  TError,
  { data: AlertRequest },
  TContext
> => {
  const mutationOptions = getCreateAlertAlertsPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update Alert Status
 */
export const updateAlertStatusAlertsAlertIdPut = (
  alertId: string,
  alertStatusUpdate: AlertStatusUpdate,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<void>(
    {
      url: `/alerts/${alertId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: alertStatusUpdate,
    },
    options,
  );
};

export const getUpdateAlertStatusAlertsAlertIdPutMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAlertStatusAlertsAlertIdPut>>,
    TError,
    { alertId: string; data: AlertStatusUpdate },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAlertStatusAlertsAlertIdPut>>,
  TError,
  { alertId: string; data: AlertStatusUpdate },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAlertStatusAlertsAlertIdPut>>,
    { alertId: string; data: AlertStatusUpdate }
  > = (props) => {
    const { alertId, data } = props ?? {};

    return updateAlertStatusAlertsAlertIdPut(alertId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAlertStatusAlertsAlertIdPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAlertStatusAlertsAlertIdPut>>
>;
export type UpdateAlertStatusAlertsAlertIdPutMutationBody = AlertStatusUpdate;
export type UpdateAlertStatusAlertsAlertIdPutMutationError =
  HTTPValidationError;

/**
 * @summary Update Alert Status
 */
export const useUpdateAlertStatusAlertsAlertIdPut = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAlertStatusAlertsAlertIdPut>>,
    TError,
    { alertId: string; data: AlertStatusUpdate },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAlertStatusAlertsAlertIdPut>>,
  TError,
  { alertId: string; data: AlertStatusUpdate },
  TContext
> => {
  const mutationOptions =
    getUpdateAlertStatusAlertsAlertIdPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Reset Alert
 */
export const resetAlertAlertsAlertIdResetPost = (
  alertId: number,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<AlertDetailsViewModel>(
    { url: `/alerts/${alertId}/reset`, method: "POST" },
    options,
  );
};

export const getResetAlertAlertsAlertIdResetPostMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetAlertAlertsAlertIdResetPost>>,
    TError,
    { alertId: number },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resetAlertAlertsAlertIdResetPost>>,
  TError,
  { alertId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetAlertAlertsAlertIdResetPost>>,
    { alertId: number }
  > = (props) => {
    const { alertId } = props ?? {};

    return resetAlertAlertsAlertIdResetPost(alertId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetAlertAlertsAlertIdResetPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof resetAlertAlertsAlertIdResetPost>>
>;

export type ResetAlertAlertsAlertIdResetPostMutationError = HTTPValidationError;

/**
 * @summary Reset Alert
 */
export const useResetAlertAlertsAlertIdResetPost = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetAlertAlertsAlertIdResetPost>>,
    TError,
    { alertId: number },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof resetAlertAlertsAlertIdResetPost>>,
  TError,
  { alertId: number },
  TContext
> => {
  const mutationOptions =
    getResetAlertAlertsAlertIdResetPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get All Members
 */
export const getAllMembersMembersGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<MemberDetails[]>(
    { url: `/members`, method: "GET" },
    options,
  );
};

export const getGetAllMembersMembersGetQueryKey = () => {
  return [`/members`] as const;
};

export const getGetAllMembersMembersGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllMembersMembersGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAllMembersMembersGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAllMembersMembersGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAllMembersMembersGet>>
  > = () => getAllMembersMembersGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllMembersMembersGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllMembersMembersGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllMembersMembersGet>>
>;
export type GetAllMembersMembersGetQueryError = unknown;

/**
 * @summary Get All Members
 */
export const useGetAllMembersMembersGet = <
  TData = Awaited<ReturnType<typeof getAllMembersMembersGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAllMembersMembersGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllMembersMembersGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create Member
 */
export const createMemberMembersPost = (
  createMemberModel: CreateMemberModel,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<MemberDetails>(
    {
      url: `/members`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createMemberModel,
    },
    options,
  );
};

export const getCreateMemberMembersPostMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createMemberMembersPost>>,
    TError,
    { data: CreateMemberModel },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createMemberMembersPost>>,
  TError,
  { data: CreateMemberModel },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createMemberMembersPost>>,
    { data: CreateMemberModel }
  > = (props) => {
    const { data } = props ?? {};

    return createMemberMembersPost(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMemberMembersPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createMemberMembersPost>>
>;
export type CreateMemberMembersPostMutationBody = CreateMemberModel;
export type CreateMemberMembersPostMutationError = HTTPValidationError;

/**
 * @summary Create Member
 */
export const useCreateMemberMembersPost = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createMemberMembersPost>>,
    TError,
    { data: CreateMemberModel },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createMemberMembersPost>>,
  TError,
  { data: CreateMemberModel },
  TContext
> => {
  const mutationOptions = getCreateMemberMembersPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Edit Member By Id
 */
export const editMemberByIdMembersMemberIdPatch = (
  memberId: number,
  editMemberModel: EditMemberModel,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<MemberDetails>(
    {
      url: `/members/${memberId}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: editMemberModel,
    },
    options,
  );
};

export const getEditMemberByIdMembersMemberIdPatchMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editMemberByIdMembersMemberIdPatch>>,
    TError,
    { memberId: number; data: EditMemberModel },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof editMemberByIdMembersMemberIdPatch>>,
  TError,
  { memberId: number; data: EditMemberModel },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof editMemberByIdMembersMemberIdPatch>>,
    { memberId: number; data: EditMemberModel }
  > = (props) => {
    const { memberId, data } = props ?? {};

    return editMemberByIdMembersMemberIdPatch(memberId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EditMemberByIdMembersMemberIdPatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof editMemberByIdMembersMemberIdPatch>>
>;
export type EditMemberByIdMembersMemberIdPatchMutationBody = EditMemberModel;
export type EditMemberByIdMembersMemberIdPatchMutationError =
  HTTPValidationError;

/**
 * @summary Edit Member By Id
 */
export const useEditMemberByIdMembersMemberIdPatch = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editMemberByIdMembersMemberIdPatch>>,
    TError,
    { memberId: number; data: EditMemberModel },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof editMemberByIdMembersMemberIdPatch>>,
  TError,
  { memberId: number; data: EditMemberModel },
  TContext
> => {
  const mutationOptions =
    getEditMemberByIdMembersMemberIdPatchMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete Member By Id
 */
export const deleteMemberByIdMembersMemberIdDelete = (
  memberId: number,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<void>(
    { url: `/members/${memberId}}`, method: "DELETE" },
    options,
  );
};

export const getDeleteMemberByIdMembersMemberIdDeleteMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMemberByIdMembersMemberIdDelete>>,
    TError,
    { memberId: number },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteMemberByIdMembersMemberIdDelete>>,
  TError,
  { memberId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteMemberByIdMembersMemberIdDelete>>,
    { memberId: number }
  > = (props) => {
    const { memberId } = props ?? {};

    return deleteMemberByIdMembersMemberIdDelete(memberId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMemberByIdMembersMemberIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteMemberByIdMembersMemberIdDelete>>
>;

export type DeleteMemberByIdMembersMemberIdDeleteMutationError =
  HTTPValidationError;

/**
 * @summary Delete Member By Id
 */
export const useDeleteMemberByIdMembersMemberIdDelete = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMemberByIdMembersMemberIdDelete>>,
    TError,
    { memberId: number },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteMemberByIdMembersMemberIdDelete>>,
  TError,
  { memberId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteMemberByIdMembersMemberIdDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Member Countries
 */
export const getMemberCountriesMembersCountriesGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<CountryViewModel[]>(
    { url: `/members/countries`, method: "GET" },
    options,
  );
};

export const getGetMemberCountriesMembersCountriesGetQueryKey = () => {
  return [`/members/countries`] as const;
};

export const getGetMemberCountriesMembersCountriesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getMemberCountriesMembersCountriesGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getMemberCountriesMembersCountriesGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetMemberCountriesMembersCountriesGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMemberCountriesMembersCountriesGet>>
  > = () => getMemberCountriesMembersCountriesGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMemberCountriesMembersCountriesGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMemberCountriesMembersCountriesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMemberCountriesMembersCountriesGet>>
>;
export type GetMemberCountriesMembersCountriesGetQueryError = unknown;

/**
 * @summary Get Member Countries
 */
export const useGetMemberCountriesMembersCountriesGet = <
  TData = Awaited<ReturnType<typeof getMemberCountriesMembersCountriesGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getMemberCountriesMembersCountriesGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetMemberCountriesMembersCountriesGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Member Info
 */
export const getMemberInfoMembersMemberInfoGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<MemberDetails>(
    { url: `/members/member-info`, method: "GET" },
    options,
  );
};

export const getGetMemberInfoMembersMemberInfoGetQueryKey = () => {
  return [`/members/member-info`] as const;
};

export const getGetMemberInfoMembersMemberInfoGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getMemberInfoMembersMemberInfoGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getMemberInfoMembersMemberInfoGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetMemberInfoMembersMemberInfoGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMemberInfoMembersMemberInfoGet>>
  > = () => getMemberInfoMembersMemberInfoGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMemberInfoMembersMemberInfoGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMemberInfoMembersMemberInfoGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMemberInfoMembersMemberInfoGet>>
>;
export type GetMemberInfoMembersMemberInfoGetQueryError = unknown;

/**
 * @summary Get Member Info
 */
export const useGetMemberInfoMembersMemberInfoGet = <
  TData = Awaited<ReturnType<typeof getMemberInfoMembersMemberInfoGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getMemberInfoMembersMemberInfoGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetMemberInfoMembersMemberInfoGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Download All Active Members
 */
export const downloadAllActiveMembersMembersDownloadGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<unknown>(
    { url: `/members/download`, method: "GET" },
    options,
  );
};

export const getDownloadAllActiveMembersMembersDownloadGetQueryKey = () => {
  return [`/members/download`] as const;
};

export const getDownloadAllActiveMembersMembersDownloadGetQueryOptions = <
  TData = Awaited<
    ReturnType<typeof downloadAllActiveMembersMembersDownloadGet>
  >,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof downloadAllActiveMembersMembersDownloadGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDownloadAllActiveMembersMembersDownloadGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadAllActiveMembersMembersDownloadGet>>
  > = () => downloadAllActiveMembersMembersDownloadGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadAllActiveMembersMembersDownloadGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadAllActiveMembersMembersDownloadGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadAllActiveMembersMembersDownloadGet>>
>;
export type DownloadAllActiveMembersMembersDownloadGetQueryError = unknown;

/**
 * @summary Download All Active Members
 */
export const useDownloadAllActiveMembersMembersDownloadGet = <
  TData = Awaited<
    ReturnType<typeof downloadAllActiveMembersMembersDownloadGet>
  >,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof downloadAllActiveMembersMembersDownloadGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getDownloadAllActiveMembersMembersDownloadGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Upload Bulk User
 */
export const uploadBulkUserMembersUploadPost = (
  bodyUploadBulkUserMembersUploadPost: BodyUploadBulkUserMembersUploadPost,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  const formData = new FormData();
  formData.append("file", bodyUploadBulkUserMembersUploadPost.file);

  return withCustomAxios<unknown>(
    {
      url: `/members/upload`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};

export const getUploadBulkUserMembersUploadPostMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadBulkUserMembersUploadPost>>,
    TError,
    { data: BodyUploadBulkUserMembersUploadPost },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadBulkUserMembersUploadPost>>,
  TError,
  { data: BodyUploadBulkUserMembersUploadPost },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadBulkUserMembersUploadPost>>,
    { data: BodyUploadBulkUserMembersUploadPost }
  > = (props) => {
    const { data } = props ?? {};

    return uploadBulkUserMembersUploadPost(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadBulkUserMembersUploadPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadBulkUserMembersUploadPost>>
>;
export type UploadBulkUserMembersUploadPostMutationBody =
  BodyUploadBulkUserMembersUploadPost;
export type UploadBulkUserMembersUploadPostMutationError = HTTPValidationError;

/**
 * @summary Upload Bulk User
 */
export const useUploadBulkUserMembersUploadPost = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadBulkUserMembersUploadPost>>,
    TError,
    { data: BodyUploadBulkUserMembersUploadPost },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof uploadBulkUserMembersUploadPost>>,
  TError,
  { data: BodyUploadBulkUserMembersUploadPost },
  TContext
> => {
  const mutationOptions =
    getUploadBulkUserMembersUploadPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Edit Bulk Permissions
 */
export const editBulkPermissionsMembersPermissionsPatch = (
  bulkMemberPermissionsModel: BulkMemberPermissionsModel,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<unknown>(
    {
      url: `/members/permissions`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: bulkMemberPermissionsModel,
    },
    options,
  );
};

export const getEditBulkPermissionsMembersPermissionsPatchMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editBulkPermissionsMembersPermissionsPatch>>,
    TError,
    { data: BulkMemberPermissionsModel },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof editBulkPermissionsMembersPermissionsPatch>>,
  TError,
  { data: BulkMemberPermissionsModel },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof editBulkPermissionsMembersPermissionsPatch>>,
    { data: BulkMemberPermissionsModel }
  > = (props) => {
    const { data } = props ?? {};

    return editBulkPermissionsMembersPermissionsPatch(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EditBulkPermissionsMembersPermissionsPatchMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof editBulkPermissionsMembersPermissionsPatch>>
  >;
export type EditBulkPermissionsMembersPermissionsPatchMutationBody =
  BulkMemberPermissionsModel;
export type EditBulkPermissionsMembersPermissionsPatchMutationError =
  HTTPValidationError;

/**
 * @summary Edit Bulk Permissions
 */
export const useEditBulkPermissionsMembersPermissionsPatch = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editBulkPermissionsMembersPermissionsPatch>>,
    TError,
    { data: BulkMemberPermissionsModel },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof editBulkPermissionsMembersPermissionsPatch>>,
  TError,
  { data: BulkMemberPermissionsModel },
  TContext
> => {
  const mutationOptions =
    getEditBulkPermissionsMembersPermissionsPatchMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Version History
 */
export const getVersionHistoryVersionHistoryGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<VersionHistoryViewModel[]>(
    { url: `/version-history`, method: "GET" },
    options,
  );
};

export const getGetVersionHistoryVersionHistoryGetQueryKey = () => {
  return [`/version-history`] as const;
};

export const getGetVersionHistoryVersionHistoryGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getVersionHistoryVersionHistoryGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getVersionHistoryVersionHistoryGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetVersionHistoryVersionHistoryGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getVersionHistoryVersionHistoryGet>>
  > = () => getVersionHistoryVersionHistoryGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getVersionHistoryVersionHistoryGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetVersionHistoryVersionHistoryGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getVersionHistoryVersionHistoryGet>>
>;
export type GetVersionHistoryVersionHistoryGetQueryError = unknown;

/**
 * @summary Get Version History
 */
export const useGetVersionHistoryVersionHistoryGet = <
  TData = Awaited<ReturnType<typeof getVersionHistoryVersionHistoryGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getVersionHistoryVersionHistoryGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetVersionHistoryVersionHistoryGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Download File Blob Storage
 */
export const downloadFileBlobStorageVersionHistoryDownloadDatasetIdGet = (
  datasetId: number,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<unknown>(
    { url: `/version-history/download/${datasetId}`, method: "GET" },
    options,
  );
};

export const getDownloadFileBlobStorageVersionHistoryDownloadDatasetIdGetQueryKey =
  (datasetId: number) => {
    return [`/version-history/download/${datasetId}`] as const;
  };

export const getDownloadFileBlobStorageVersionHistoryDownloadDatasetIdGetQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof downloadFileBlobStorageVersionHistoryDownloadDatasetIdGet
      >
    >,
    TError = HTTPValidationError,
  >(
    datasetId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof downloadFileBlobStorageVersionHistoryDownloadDatasetIdGet
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof withCustomAxios>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getDownloadFileBlobStorageVersionHistoryDownloadDatasetIdGetQueryKey(
        datasetId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof downloadFileBlobStorageVersionHistoryDownloadDatasetIdGet
        >
      >
    > = () =>
      downloadFileBlobStorageVersionHistoryDownloadDatasetIdGet(
        datasetId,
        requestOptions,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!datasetId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof downloadFileBlobStorageVersionHistoryDownloadDatasetIdGet
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type DownloadFileBlobStorageVersionHistoryDownloadDatasetIdGetQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof downloadFileBlobStorageVersionHistoryDownloadDatasetIdGet
      >
    >
  >;
export type DownloadFileBlobStorageVersionHistoryDownloadDatasetIdGetQueryError =
  HTTPValidationError;

/**
 * @summary Download File Blob Storage
 */
export const useDownloadFileBlobStorageVersionHistoryDownloadDatasetIdGet = <
  TData = Awaited<
    ReturnType<typeof downloadFileBlobStorageVersionHistoryDownloadDatasetIdGet>
  >,
  TError = HTTPValidationError,
>(
  datasetId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof downloadFileBlobStorageVersionHistoryDownloadDatasetIdGet
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getDownloadFileBlobStorageVersionHistoryDownloadDatasetIdGetQueryOptions(
      datasetId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Categories
 */
export const getCategoriesCategoriesGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<CategoryViewModel[]>(
    { url: `/categories`, method: "GET" },
    options,
  );
};

export const getGetCategoriesCategoriesGetQueryKey = () => {
  return [`/categories`] as const;
};

export const getGetCategoriesCategoriesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getCategoriesCategoriesGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCategoriesCategoriesGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCategoriesCategoriesGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCategoriesCategoriesGet>>
  > = () => getCategoriesCategoriesGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCategoriesCategoriesGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCategoriesCategoriesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCategoriesCategoriesGet>>
>;
export type GetCategoriesCategoriesGetQueryError = unknown;

/**
 * @summary Get Categories
 */
export const useGetCategoriesCategoriesGet = <
  TData = Awaited<ReturnType<typeof getCategoriesCategoriesGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getCategoriesCategoriesGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCategoriesCategoriesGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Pack Types
 */
export const getPackTypesPackTypesGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<PackTypeViewModel[]>(
    { url: `/pack-types`, method: "GET" },
    options,
  );
};

export const getGetPackTypesPackTypesGetQueryKey = () => {
  return [`/pack-types`] as const;
};

export const getGetPackTypesPackTypesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getPackTypesPackTypesGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPackTypesPackTypesGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPackTypesPackTypesGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPackTypesPackTypesGet>>
  > = () => getPackTypesPackTypesGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPackTypesPackTypesGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPackTypesPackTypesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPackTypesPackTypesGet>>
>;
export type GetPackTypesPackTypesGetQueryError = unknown;

/**
 * @summary Get Pack Types
 */
export const useGetPackTypesPackTypesGet = <
  TData = Awaited<ReturnType<typeof getPackTypesPackTypesGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPackTypesPackTypesGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPackTypesPackTypesGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Data Management Numeric Data
 */
export const getDataManagementNumericDataDataManagementExportAvailableDataNumericGet =
  (options?: SecondParameter<typeof withCustomAxios>) => {
    return withCustomAxios<GetDataManagementNumericDataDataManagementExportAvailableDataNumericGet200>(
      { url: `/data-management/export/available-data/numeric`, method: "GET" },
      options,
    );
  };

export const getGetDataManagementNumericDataDataManagementExportAvailableDataNumericGetQueryKey =
  () => {
    return [`/data-management/export/available-data/numeric`] as const;
  };

export const getGetDataManagementNumericDataDataManagementExportAvailableDataNumericGetQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getDataManagementNumericDataDataManagementExportAvailableDataNumericGet
      >
    >,
    TError = unknown,
  >(options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getDataManagementNumericDataDataManagementExportAvailableDataNumericGet
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetDataManagementNumericDataDataManagementExportAvailableDataNumericGetQueryKey();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getDataManagementNumericDataDataManagementExportAvailableDataNumericGet
        >
      >
    > = () =>
      getDataManagementNumericDataDataManagementExportAvailableDataNumericGet(
        requestOptions,
      );

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getDataManagementNumericDataDataManagementExportAvailableDataNumericGet
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetDataManagementNumericDataDataManagementExportAvailableDataNumericGetQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getDataManagementNumericDataDataManagementExportAvailableDataNumericGet
      >
    >
  >;
export type GetDataManagementNumericDataDataManagementExportAvailableDataNumericGetQueryError =
  unknown;

/**
 * @summary Get Data Management Numeric Data
 */
export const useGetDataManagementNumericDataDataManagementExportAvailableDataNumericGet =
  <
    TData = Awaited<
      ReturnType<
        typeof getDataManagementNumericDataDataManagementExportAvailableDataNumericGet
      >
    >,
    TError = unknown,
  >(options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getDataManagementNumericDataDataManagementExportAvailableDataNumericGet
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getGetDataManagementNumericDataDataManagementExportAvailableDataNumericGetQueryOptions(
        options,
      );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
      queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
  };

/**
 * @summary Get Data Management Configuration Data
 */
export const getDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGet =
  (options?: SecondParameter<typeof withCustomAxios>) => {
    return withCustomAxios<GetDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGet200>(
      {
        url: `/data-management/export/available-data/configuration`,
        method: "GET",
      },
      options,
    );
  };

export const getGetDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGetQueryKey =
  () => {
    return [`/data-management/export/available-data/configuration`] as const;
  };

export const getGetDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGetQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGet
      >
    >,
    TError = unknown,
  >(options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGet
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGetQueryKey();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGet
        >
      >
    > = () =>
      getDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGet(
        requestOptions,
      );

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGet
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGetQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGet
      >
    >
  >;
export type GetDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGetQueryError =
  unknown;

/**
 * @summary Get Data Management Configuration Data
 */
export const useGetDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGet =
  <
    TData = Awaited<
      ReturnType<
        typeof getDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGet
      >
    >,
    TError = unknown,
  >(options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGet
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getGetDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGetQueryOptions(
        options,
      );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
      queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
  };

/**
 * @summary Export Data Extract
 */
export const exportDataExtractDataManagementExportPost = (
  dataExtractRequest: DataExtractRequest,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<void>(
    {
      url: `/data-management/export`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: dataExtractRequest,
    },
    options,
  );
};

export const getExportDataExtractDataManagementExportPostMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exportDataExtractDataManagementExportPost>>,
    TError,
    { data: DataExtractRequest },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof exportDataExtractDataManagementExportPost>>,
  TError,
  { data: DataExtractRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof exportDataExtractDataManagementExportPost>>,
    { data: DataExtractRequest }
  > = (props) => {
    const { data } = props ?? {};

    return exportDataExtractDataManagementExportPost(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportDataExtractDataManagementExportPostMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof exportDataExtractDataManagementExportPost>>
  >;
export type ExportDataExtractDataManagementExportPostMutationBody =
  DataExtractRequest;
export type ExportDataExtractDataManagementExportPostMutationError =
  HTTPValidationError;

/**
 * @summary Export Data Extract
 */
export const useExportDataExtractDataManagementExportPost = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exportDataExtractDataManagementExportPost>>,
    TError,
    { data: DataExtractRequest },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof exportDataExtractDataManagementExportPost>>,
  TError,
  { data: DataExtractRequest },
  TContext
> => {
  const mutationOptions =
    getExportDataExtractDataManagementExportPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get All Functional Areas
 */
export const getAllFunctionalAreasUserManagementFunctionalAreasGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<FunctionalAreaItemViewModel[]>(
    { url: `/user-management/functional-areas`, method: "GET" },
    options,
  );
};

export const getGetAllFunctionalAreasUserManagementFunctionalAreasGetQueryKey =
  () => {
    return [`/user-management/functional-areas`] as const;
  };

export const getGetAllFunctionalAreasUserManagementFunctionalAreasGetQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getAllFunctionalAreasUserManagementFunctionalAreasGet>
    >,
    TError = unknown,
  >(options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getAllFunctionalAreasUserManagementFunctionalAreasGet
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetAllFunctionalAreasUserManagementFunctionalAreasGetQueryKey();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getAllFunctionalAreasUserManagementFunctionalAreasGet>
      >
    > = () =>
      getAllFunctionalAreasUserManagementFunctionalAreasGet(requestOptions);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getAllFunctionalAreasUserManagementFunctionalAreasGet>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetAllFunctionalAreasUserManagementFunctionalAreasGetQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getAllFunctionalAreasUserManagementFunctionalAreasGet>
    >
  >;
export type GetAllFunctionalAreasUserManagementFunctionalAreasGetQueryError =
  unknown;

/**
 * @summary Get All Functional Areas
 */
export const useGetAllFunctionalAreasUserManagementFunctionalAreasGet = <
  TData = Awaited<
    ReturnType<typeof getAllFunctionalAreasUserManagementFunctionalAreasGet>
  >,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<typeof getAllFunctionalAreasUserManagementFunctionalAreasGet>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetAllFunctionalAreasUserManagementFunctionalAreasGetQueryOptions(
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Test Data
 */
export const getTestDataTestGenerateTestDataGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<unknown>(
    { url: `/test/generate-test-data`, method: "GET" },
    options,
  );
};

export const getGetTestDataTestGenerateTestDataGetQueryKey = () => {
  return [`/test/generate-test-data`] as const;
};

export const getGetTestDataTestGenerateTestDataGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getTestDataTestGenerateTestDataGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getTestDataTestGenerateTestDataGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTestDataTestGenerateTestDataGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTestDataTestGenerateTestDataGet>>
  > = () => getTestDataTestGenerateTestDataGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTestDataTestGenerateTestDataGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTestDataTestGenerateTestDataGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTestDataTestGenerateTestDataGet>>
>;
export type GetTestDataTestGenerateTestDataGetQueryError = unknown;

/**
 * @summary Get Test Data
 */
export const useGetTestDataTestGenerateTestDataGet = <
  TData = Awaited<ReturnType<typeof getTestDataTestGenerateTestDataGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getTestDataTestGenerateTestDataGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetTestDataTestGenerateTestDataGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update Micro Scenario Sku
 */
export const updateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatch =
  (
    scenarioId: number,
    productGuid: string,
    editScenarioSkuModel: EditScenarioSkuModel,
    options?: SecondParameter<typeof withCustomAxios>,
  ) => {
    return withCustomAxios<void>(
      {
        url: `/scenarios/micro/${scenarioId}/products/${productGuid}`,
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        data: editScenarioSkuModel,
      },
      options,
    );
  };

export const getUpdateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatchMutationOptions =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof updateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatch
        >
      >,
      TError,
      { scenarioId: number; productGuid: string; data: EditScenarioSkuModel },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof updateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatch
      >
    >,
    TError,
    { scenarioId: number; productGuid: string; data: EditScenarioSkuModel },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof updateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatch
        >
      >,
      { scenarioId: number; productGuid: string; data: EditScenarioSkuModel }
    > = (props) => {
      const { scenarioId, productGuid, data } = props ?? {};

      return updateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatch(
        scenarioId,
        productGuid,
        data,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type UpdateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatchMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof updateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatch
      >
    >
  >;
export type UpdateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatchMutationBody =
  EditScenarioSkuModel;
export type UpdateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatchMutationError =
  HTTPValidationError;

/**
 * @summary Update Micro Scenario Sku
 */
export const useUpdateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatch =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof updateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatch
        >
      >,
      TError,
      { scenarioId: number; productGuid: string; data: EditScenarioSkuModel },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof updateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatch
      >
    >,
    TError,
    { scenarioId: number; productGuid: string; data: EditScenarioSkuModel },
    TContext
  > => {
    const mutationOptions =
      getUpdateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidPatchMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary Delete Micro Scenario Sku
 */
export const deleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDelete =
  (
    scenarioId: number,
    productGuid: string,
    options?: SecondParameter<typeof withCustomAxios>,
  ) => {
    return withCustomAxios<void>(
      {
        url: `/scenarios/micro/${scenarioId}/products/${productGuid}`,
        method: "DELETE",
      },
      options,
    );
  };

export const getDeleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDeleteMutationOptions =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof deleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDelete
        >
      >,
      TError,
      { scenarioId: number; productGuid: string },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof deleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDelete
      >
    >,
    TError,
    { scenarioId: number; productGuid: string },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof deleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDelete
        >
      >,
      { scenarioId: number; productGuid: string }
    > = (props) => {
      const { scenarioId, productGuid } = props ?? {};

      return deleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDelete(
        scenarioId,
        productGuid,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDeleteMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDelete
      >
    >
  >;

export type DeleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDeleteMutationError =
  HTTPValidationError;

/**
 * @summary Delete Micro Scenario Sku
 */
export const useDeleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDelete =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof deleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDelete
        >
      >,
      TError,
      { scenarioId: number; productGuid: string },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof deleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDelete
      >
    >,
    TError,
    { scenarioId: number; productGuid: string },
    TContext
  > => {
    const mutationOptions =
      getDeleteMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDeleteMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary Duplicate Micro Scenario Sku
 */
export const duplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePost =
  (
    scenarioId: number,
    productGuid: string,
    options?: SecondParameter<typeof withCustomAxios>,
  ) => {
    return withCustomAxios<Product>(
      {
        url: `/scenarios/micro/${scenarioId}/products/${productGuid}/duplicate`,
        method: "POST",
      },
      options,
    );
  };

export const getDuplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePostMutationOptions =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof duplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePost
        >
      >,
      TError,
      { scenarioId: number; productGuid: string },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof duplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePost
      >
    >,
    TError,
    { scenarioId: number; productGuid: string },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof duplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePost
        >
      >,
      { scenarioId: number; productGuid: string }
    > = (props) => {
      const { scenarioId, productGuid } = props ?? {};

      return duplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePost(
        scenarioId,
        productGuid,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DuplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePostMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof duplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePost
      >
    >
  >;

export type DuplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePostMutationError =
  HTTPValidationError;

/**
 * @summary Duplicate Micro Scenario Sku
 */
export const useDuplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePost =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof duplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePost
        >
      >,
      TError,
      { scenarioId: number; productGuid: string },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof duplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePost
      >
    >,
    TError,
    { scenarioId: number; productGuid: string },
    TContext
  > => {
    const mutationOptions =
      getDuplicateMicroScenarioSkuScenariosMicroScenarioIdProductsProductGuidDuplicatePostMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary Add Products To Micro Scenario
 */
export const addProductsToMicroScenarioScenariosMicroScenarioIdProductsPost = (
  scenarioId: number,
  addProductsToMicroScenarioScenariosMicroScenarioIdProductsPostBody: number[],
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<Product[]>(
    {
      url: `/scenarios/micro/${scenarioId}/products`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: addProductsToMicroScenarioScenariosMicroScenarioIdProductsPostBody,
    },
    options,
  );
};

export const getAddProductsToMicroScenarioScenariosMicroScenarioIdProductsPostMutationOptions =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof addProductsToMicroScenarioScenariosMicroScenarioIdProductsPost
        >
      >,
      TError,
      { scenarioId: number; data: number[] },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof addProductsToMicroScenarioScenariosMicroScenarioIdProductsPost
      >
    >,
    TError,
    { scenarioId: number; data: number[] },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof addProductsToMicroScenarioScenariosMicroScenarioIdProductsPost
        >
      >,
      { scenarioId: number; data: number[] }
    > = (props) => {
      const { scenarioId, data } = props ?? {};

      return addProductsToMicroScenarioScenariosMicroScenarioIdProductsPost(
        scenarioId,
        data,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type AddProductsToMicroScenarioScenariosMicroScenarioIdProductsPostMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof addProductsToMicroScenarioScenariosMicroScenarioIdProductsPost
      >
    >
  >;
export type AddProductsToMicroScenarioScenariosMicroScenarioIdProductsPostMutationBody =
  number[];
export type AddProductsToMicroScenarioScenariosMicroScenarioIdProductsPostMutationError =
  HTTPValidationError;

/**
 * @summary Add Products To Micro Scenario
 */
export const useAddProductsToMicroScenarioScenariosMicroScenarioIdProductsPost =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof addProductsToMicroScenarioScenariosMicroScenarioIdProductsPost
        >
      >,
      TError,
      { scenarioId: number; data: number[] },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof addProductsToMicroScenarioScenariosMicroScenarioIdProductsPost
      >
    >,
    TError,
    { scenarioId: number; data: number[] },
    TContext
  > => {
    const mutationOptions =
      getAddProductsToMicroScenarioScenariosMicroScenarioIdProductsPostMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary Delete All Products From Micro Scenario
 */
export const deleteAllProductsFromMicroScenarioScenariosMicroScenarioIdProductsDelete =
  (scenarioId: number, options?: SecondParameter<typeof withCustomAxios>) => {
    return withCustomAxios<void>(
      { url: `/scenarios/micro/${scenarioId}/products`, method: "DELETE" },
      options,
    );
  };

export const getDeleteAllProductsFromMicroScenarioScenariosMicroScenarioIdProductsDeleteMutationOptions =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof deleteAllProductsFromMicroScenarioScenariosMicroScenarioIdProductsDelete
        >
      >,
      TError,
      { scenarioId: number },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof deleteAllProductsFromMicroScenarioScenariosMicroScenarioIdProductsDelete
      >
    >,
    TError,
    { scenarioId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof deleteAllProductsFromMicroScenarioScenariosMicroScenarioIdProductsDelete
        >
      >,
      { scenarioId: number }
    > = (props) => {
      const { scenarioId } = props ?? {};

      return deleteAllProductsFromMicroScenarioScenariosMicroScenarioIdProductsDelete(
        scenarioId,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteAllProductsFromMicroScenarioScenariosMicroScenarioIdProductsDeleteMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deleteAllProductsFromMicroScenarioScenariosMicroScenarioIdProductsDelete
      >
    >
  >;

export type DeleteAllProductsFromMicroScenarioScenariosMicroScenarioIdProductsDeleteMutationError =
  HTTPValidationError;

/**
 * @summary Delete All Products From Micro Scenario
 */
export const useDeleteAllProductsFromMicroScenarioScenariosMicroScenarioIdProductsDelete =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof deleteAllProductsFromMicroScenarioScenariosMicroScenarioIdProductsDelete
        >
      >,
      TError,
      { scenarioId: number },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof deleteAllProductsFromMicroScenarioScenariosMicroScenarioIdProductsDelete
      >
    >,
    TError,
    { scenarioId: number },
    TContext
  > => {
    const mutationOptions =
      getDeleteAllProductsFromMicroScenarioScenariosMicroScenarioIdProductsDeleteMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary Reorder Micro Scenario Skus
 */
export const reorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPut =
  (
    scenarioId: number,
    sKUProductPosition: SKUProductPosition[],
    options?: SecondParameter<typeof withCustomAxios>,
  ) => {
    return withCustomAxios<Product[]>(
      {
        url: `/scenarios/micro/${scenarioId}/products/order`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data: sKUProductPosition,
      },
      options,
    );
  };

export const getReorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPutMutationOptions =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof reorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPut
        >
      >,
      TError,
      { scenarioId: number; data: SKUProductPosition[] },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof reorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPut
      >
    >,
    TError,
    { scenarioId: number; data: SKUProductPosition[] },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof reorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPut
        >
      >,
      { scenarioId: number; data: SKUProductPosition[] }
    > = (props) => {
      const { scenarioId, data } = props ?? {};

      return reorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPut(
        scenarioId,
        data,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type ReorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPutMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof reorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPut
      >
    >
  >;
export type ReorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPutMutationBody =
  SKUProductPosition[];
export type ReorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPutMutationError =
  HTTPValidationError;

/**
 * @summary Reorder Micro Scenario Skus
 */
export const useReorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPut =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof reorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPut
        >
      >,
      TError,
      { scenarioId: number; data: SKUProductPosition[] },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof reorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPut
      >
    >,
    TError,
    { scenarioId: number; data: SKUProductPosition[] },
    TContext
  > => {
    const mutationOptions =
      getReorderMicroScenarioSkusScenariosMicroScenarioIdProductsOrderPutMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary Copy Adjustments To All Micro Skus
 */
export const copyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPost =
  (
    scenarioId: number,
    productGuid: string,
    options?: SecondParameter<typeof withCustomAxios>,
  ) => {
    return withCustomAxios<ViewMicroScenario>(
      {
        url: `/scenarios/micro/${scenarioId}/products/${productGuid}/copy-to-all`,
        method: "POST",
      },
      options,
    );
  };

export const getCopyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPostMutationOptions =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof copyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPost
        >
      >,
      TError,
      { scenarioId: number; productGuid: string },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof copyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPost
      >
    >,
    TError,
    { scenarioId: number; productGuid: string },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof copyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPost
        >
      >,
      { scenarioId: number; productGuid: string }
    > = (props) => {
      const { scenarioId, productGuid } = props ?? {};

      return copyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPost(
        scenarioId,
        productGuid,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type CopyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPostMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof copyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPost
      >
    >
  >;

export type CopyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPostMutationError =
  HTTPValidationError;

/**
 * @summary Copy Adjustments To All Micro Skus
 */
export const useCopyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPost =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof copyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPost
        >
      >,
      TError,
      { scenarioId: number; productGuid: string },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof copyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPost
      >
    >,
    TError,
    { scenarioId: number; productGuid: string },
    TContext
  > => {
    const mutationOptions =
      getCopyAdjustmentsToAllMicroSkusScenariosMicroScenarioIdProductsProductGuidCopyToAllPostMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary Get All Micro Scenarios
 */
export const getAllMicroScenariosScenariosMicroGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<ViewMicroScenario[]>(
    { url: `/scenarios/micro`, method: "GET" },
    options,
  );
};

export const getGetAllMicroScenariosScenariosMicroGetQueryKey = () => {
  return [`/scenarios/micro`] as const;
};

export const getGetAllMicroScenariosScenariosMicroGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllMicroScenariosScenariosMicroGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAllMicroScenariosScenariosMicroGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAllMicroScenariosScenariosMicroGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAllMicroScenariosScenariosMicroGet>>
  > = () => getAllMicroScenariosScenariosMicroGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllMicroScenariosScenariosMicroGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllMicroScenariosScenariosMicroGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllMicroScenariosScenariosMicroGet>>
>;
export type GetAllMicroScenariosScenariosMicroGetQueryError = unknown;

/**
 * @summary Get All Micro Scenarios
 */
export const useGetAllMicroScenariosScenariosMicroGet = <
  TData = Awaited<ReturnType<typeof getAllMicroScenariosScenariosMicroGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAllMicroScenariosScenariosMicroGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetAllMicroScenariosScenariosMicroGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create Micro Scenario
 */
export const createMicroScenarioScenariosMicroPost = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<ViewMicroScenario>(
    { url: `/scenarios/micro`, method: "POST" },
    options,
  );
};

export const getCreateMicroScenarioScenariosMicroPostMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createMicroScenarioScenariosMicroPost>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createMicroScenarioScenariosMicroPost>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createMicroScenarioScenariosMicroPost>>,
    void
  > = () => {
    return createMicroScenarioScenariosMicroPost(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMicroScenarioScenariosMicroPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createMicroScenarioScenariosMicroPost>>
>;

export type CreateMicroScenarioScenariosMicroPostMutationError = unknown;

/**
 * @summary Create Micro Scenario
 */
export const useCreateMicroScenarioScenariosMicroPost = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createMicroScenarioScenariosMicroPost>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createMicroScenarioScenariosMicroPost>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getCreateMicroScenarioScenariosMicroPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Micro Scenario By Id
 */
export const getMicroScenarioByIdScenariosMicroScenarioIdGet = (
  scenarioId: number,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<ViewMicroScenario>(
    { url: `/scenarios/micro/${scenarioId}`, method: "GET" },
    options,
  );
};

export const getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey = (
  scenarioId: number,
) => {
  return [`/scenarios/micro/${scenarioId}`] as const;
};

export const getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getMicroScenarioByIdScenariosMicroScenarioIdGet>
  >,
  TError = HTTPValidationError,
>(
  scenarioId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getMicroScenarioByIdScenariosMicroScenarioIdGet>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(scenarioId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMicroScenarioByIdScenariosMicroScenarioIdGet>>
  > = () =>
    getMicroScenarioByIdScenariosMicroScenarioIdGet(scenarioId, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!scenarioId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMicroScenarioByIdScenariosMicroScenarioIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMicroScenarioByIdScenariosMicroScenarioIdGetQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getMicroScenarioByIdScenariosMicroScenarioIdGet>>
  >;
export type GetMicroScenarioByIdScenariosMicroScenarioIdGetQueryError =
  HTTPValidationError;

/**
 * @summary Get Micro Scenario By Id
 */
export const useGetMicroScenarioByIdScenariosMicroScenarioIdGet = <
  TData = Awaited<
    ReturnType<typeof getMicroScenarioByIdScenariosMicroScenarioIdGet>
  >,
  TError = HTTPValidationError,
>(
  scenarioId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getMicroScenarioByIdScenariosMicroScenarioIdGet>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryOptions(
      scenarioId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update Micro Scenario Details
 */
export const updateMicroScenarioDetailsScenariosMicroScenarioIdPatch = (
  scenarioId: number,
  updateScenarioDetails: UpdateScenarioDetails,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<ViewMicroScenario>(
    {
      url: `/scenarios/micro/${scenarioId}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: updateScenarioDetails,
    },
    options,
  );
};

export const getUpdateMicroScenarioDetailsScenariosMicroScenarioIdPatchMutationOptions =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof updateMicroScenarioDetailsScenariosMicroScenarioIdPatch
        >
      >,
      TError,
      { scenarioId: number; data: UpdateScenarioDetails },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof updateMicroScenarioDetailsScenariosMicroScenarioIdPatch>
    >,
    TError,
    { scenarioId: number; data: UpdateScenarioDetails },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof updateMicroScenarioDetailsScenariosMicroScenarioIdPatch
        >
      >,
      { scenarioId: number; data: UpdateScenarioDetails }
    > = (props) => {
      const { scenarioId, data } = props ?? {};

      return updateMicroScenarioDetailsScenariosMicroScenarioIdPatch(
        scenarioId,
        data,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type UpdateMicroScenarioDetailsScenariosMicroScenarioIdPatchMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof updateMicroScenarioDetailsScenariosMicroScenarioIdPatch>
    >
  >;
export type UpdateMicroScenarioDetailsScenariosMicroScenarioIdPatchMutationBody =
  UpdateScenarioDetails;
export type UpdateMicroScenarioDetailsScenariosMicroScenarioIdPatchMutationError =
  HTTPValidationError;

/**
 * @summary Update Micro Scenario Details
 */
export const useUpdateMicroScenarioDetailsScenariosMicroScenarioIdPatch = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof updateMicroScenarioDetailsScenariosMicroScenarioIdPatch>
    >,
    TError,
    { scenarioId: number; data: UpdateScenarioDetails },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof updateMicroScenarioDetailsScenariosMicroScenarioIdPatch>
  >,
  TError,
  { scenarioId: number; data: UpdateScenarioDetails },
  TContext
> => {
  const mutationOptions =
    getUpdateMicroScenarioDetailsScenariosMicroScenarioIdPatchMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};

/**
 * @summary Duplicate Micro Scenario
 */
export const duplicateMicroScenarioScenariosMicroScenarioIdDuplicatePost = (
  scenarioId: number,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<ViewMicroScenario>(
    { url: `/scenarios/micro/${scenarioId}/duplicate`, method: "POST" },
    options,
  );
};

export const getDuplicateMicroScenarioScenariosMicroScenarioIdDuplicatePostMutationOptions =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof duplicateMicroScenarioScenariosMicroScenarioIdDuplicatePost
        >
      >,
      TError,
      { scenarioId: number },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof duplicateMicroScenarioScenariosMicroScenarioIdDuplicatePost
      >
    >,
    TError,
    { scenarioId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof duplicateMicroScenarioScenariosMicroScenarioIdDuplicatePost
        >
      >,
      { scenarioId: number }
    > = (props) => {
      const { scenarioId } = props ?? {};

      return duplicateMicroScenarioScenariosMicroScenarioIdDuplicatePost(
        scenarioId,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DuplicateMicroScenarioScenariosMicroScenarioIdDuplicatePostMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof duplicateMicroScenarioScenariosMicroScenarioIdDuplicatePost
      >
    >
  >;

export type DuplicateMicroScenarioScenariosMicroScenarioIdDuplicatePostMutationError =
  HTTPValidationError;

/**
 * @summary Duplicate Micro Scenario
 */
export const useDuplicateMicroScenarioScenariosMicroScenarioIdDuplicatePost = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof duplicateMicroScenarioScenariosMicroScenarioIdDuplicatePost
      >
    >,
    TError,
    { scenarioId: number },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<
    ReturnType<
      typeof duplicateMicroScenarioScenariosMicroScenarioIdDuplicatePost
    >
  >,
  TError,
  { scenarioId: number },
  TContext
> => {
  const mutationOptions =
    getDuplicateMicroScenarioScenariosMicroScenarioIdDuplicatePostMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};

/**
 * @summary Export Micro Scenario
 */
export const exportMicroScenarioScenariosMicroScenarioIdExportGet = (
  scenarioId: number,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<unknown>(
    { url: `/scenarios/micro/${scenarioId}/export`, method: "GET" },
    options,
  );
};

export const getExportMicroScenarioScenariosMicroScenarioIdExportGetQueryKey = (
  scenarioId: number,
) => {
  return [`/scenarios/micro/${scenarioId}/export`] as const;
};

export const getExportMicroScenarioScenariosMicroScenarioIdExportGetQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof exportMicroScenarioScenariosMicroScenarioIdExportGet>
    >,
    TError = HTTPValidationError,
  >(
    scenarioId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof exportMicroScenarioScenariosMicroScenarioIdExportGet
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof withCustomAxios>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getExportMicroScenarioScenariosMicroScenarioIdExportGetQueryKey(
        scenarioId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof exportMicroScenarioScenariosMicroScenarioIdExportGet>
      >
    > = () =>
      exportMicroScenarioScenariosMicroScenarioIdExportGet(
        scenarioId,
        requestOptions,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!scenarioId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof exportMicroScenarioScenariosMicroScenarioIdExportGet>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type ExportMicroScenarioScenariosMicroScenarioIdExportGetQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof exportMicroScenarioScenariosMicroScenarioIdExportGet>
    >
  >;
export type ExportMicroScenarioScenariosMicroScenarioIdExportGetQueryError =
  HTTPValidationError;

/**
 * @summary Export Micro Scenario
 */
export const useExportMicroScenarioScenariosMicroScenarioIdExportGet = <
  TData = Awaited<
    ReturnType<typeof exportMicroScenarioScenariosMicroScenarioIdExportGet>
  >,
  TError = HTTPValidationError,
>(
  scenarioId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof exportMicroScenarioScenariosMicroScenarioIdExportGet
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getExportMicroScenarioScenariosMicroScenarioIdExportGetQueryOptions(
      scenarioId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create Micro Adjustments
 */
export const createMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPost = (
  scenarioId: number,
  createMicroAdjustment: CreateMicroAdjustment[],
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<ViewMicroAdjustment[]>(
    {
      url: `/scenarios/micro/${scenarioId}/adjustments`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createMicroAdjustment,
    },
    options,
  );
};

export const getCreateMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPostMutationOptions =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof createMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPost
        >
      >,
      TError,
      { scenarioId: number; data: CreateMicroAdjustment[] },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof createMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPost
      >
    >,
    TError,
    { scenarioId: number; data: CreateMicroAdjustment[] },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof createMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPost
        >
      >,
      { scenarioId: number; data: CreateMicroAdjustment[] }
    > = (props) => {
      const { scenarioId, data } = props ?? {};

      return createMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPost(
        scenarioId,
        data,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type CreateMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPostMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof createMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPost
      >
    >
  >;
export type CreateMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPostMutationBody =
  CreateMicroAdjustment[];
export type CreateMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPostMutationError =
  HTTPValidationError;

/**
 * @summary Create Micro Adjustments
 */
export const useCreateMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPost =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof createMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPost
        >
      >,
      TError,
      { scenarioId: number; data: CreateMicroAdjustment[] },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof createMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPost
      >
    >,
    TError,
    { scenarioId: number; data: CreateMicroAdjustment[] },
    TContext
  > => {
    const mutationOptions =
      getCreateMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPostMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary Edit Micro Adjustments
 */
export const editMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatch = (
  scenarioId: number,
  editMicroAdjustment: EditMicroAdjustment[],
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<ViewMicroAdjustment[]>(
    {
      url: `/scenarios/micro/${scenarioId}/adjustments`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: editMicroAdjustment,
    },
    options,
  );
};

export const getEditMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatchMutationOptions =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof editMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatch
        >
      >,
      TError,
      { scenarioId: number; data: EditMicroAdjustment[] },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof editMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatch
      >
    >,
    TError,
    { scenarioId: number; data: EditMicroAdjustment[] },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof editMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatch
        >
      >,
      { scenarioId: number; data: EditMicroAdjustment[] }
    > = (props) => {
      const { scenarioId, data } = props ?? {};

      return editMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatch(
        scenarioId,
        data,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type EditMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatchMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof editMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatch
      >
    >
  >;
export type EditMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatchMutationBody =
  EditMicroAdjustment[];
export type EditMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatchMutationError =
  HTTPValidationError;

/**
 * @summary Edit Micro Adjustments
 */
export const useEditMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatch = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof editMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatch
      >
    >,
    TError,
    { scenarioId: number; data: EditMicroAdjustment[] },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<
    ReturnType<
      typeof editMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatch
    >
  >,
  TError,
  { scenarioId: number; data: EditMicroAdjustment[] },
  TContext
> => {
  const mutationOptions =
    getEditMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsPatchMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};

/**
 * @summary Delete Micro Adjustments
 */
export const deleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete = (
  scenarioId: number,
  deleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDeleteBody: number[],
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<void>(
    {
      url: `/scenarios/micro/${scenarioId}/adjustments`,
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      data: deleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDeleteBody,
    },
    options,
  );
};

export const getDeleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDeleteMutationOptions =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof deleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete
        >
      >,
      TError,
      { scenarioId: number; data: number[] },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof deleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete
      >
    >,
    TError,
    { scenarioId: number; data: number[] },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof deleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete
        >
      >,
      { scenarioId: number; data: number[] }
    > = (props) => {
      const { scenarioId, data } = props ?? {};

      return deleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete(
        scenarioId,
        data,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDeleteMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete
      >
    >
  >;
export type DeleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDeleteMutationBody =
  number[];
export type DeleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDeleteMutationError =
  HTTPValidationError;

/**
 * @summary Delete Micro Adjustments
 */
export const useDeleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof deleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete
        >
      >,
      TError,
      { scenarioId: number; data: number[] },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof deleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDelete
      >
    >,
    TError,
    { scenarioId: number; data: number[] },
    TContext
  > => {
    const mutationOptions =
      getDeleteMicroAdjustmentsScenariosMicroScenarioIdAdjustmentsDeleteMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };

/**
 * @summary Get All Macro Scenarios
 */
export const getAllMacroScenariosScenariosMacroGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<ViewMacroScenarioViewModel[]>(
    { url: `/scenarios/macro`, method: "GET" },
    options,
  );
};

export const getGetAllMacroScenariosScenariosMacroGetQueryKey = () => {
  return [`/scenarios/macro`] as const;
};

export const getGetAllMacroScenariosScenariosMacroGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllMacroScenariosScenariosMacroGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAllMacroScenariosScenariosMacroGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAllMacroScenariosScenariosMacroGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAllMacroScenariosScenariosMacroGet>>
  > = () => getAllMacroScenariosScenariosMacroGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllMacroScenariosScenariosMacroGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllMacroScenariosScenariosMacroGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllMacroScenariosScenariosMacroGet>>
>;
export type GetAllMacroScenariosScenariosMacroGetQueryError = unknown;

/**
 * @summary Get All Macro Scenarios
 */
export const useGetAllMacroScenariosScenariosMacroGet = <
  TData = Awaited<ReturnType<typeof getAllMacroScenariosScenariosMacroGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAllMacroScenariosScenariosMacroGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetAllMacroScenariosScenariosMacroGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create Macro Scenario
 */
export const createMacroScenarioScenariosMacroPost = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<ViewMacroScenarioViewModel>(
    { url: `/scenarios/macro`, method: "POST" },
    options,
  );
};

export const getCreateMacroScenarioScenariosMacroPostMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createMacroScenarioScenariosMacroPost>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createMacroScenarioScenariosMacroPost>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createMacroScenarioScenariosMacroPost>>,
    void
  > = () => {
    return createMacroScenarioScenariosMacroPost(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMacroScenarioScenariosMacroPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createMacroScenarioScenariosMacroPost>>
>;

export type CreateMacroScenarioScenariosMacroPostMutationError = unknown;

/**
 * @summary Create Macro Scenario
 */
export const useCreateMacroScenarioScenariosMacroPost = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createMacroScenarioScenariosMacroPost>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createMacroScenarioScenariosMacroPost>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getCreateMacroScenarioScenariosMacroPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Macro Scenario By Id
 */
export const getMacroScenarioByIdScenariosMacroScenarioIdGet = (
  scenarioId: number,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<ViewMacroScenarioViewModel>(
    { url: `/scenarios/macro/${scenarioId}`, method: "GET" },
    options,
  );
};

export const getGetMacroScenarioByIdScenariosMacroScenarioIdGetQueryKey = (
  scenarioId: number,
) => {
  return [`/scenarios/macro/${scenarioId}`] as const;
};

export const getGetMacroScenarioByIdScenariosMacroScenarioIdGetQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getMacroScenarioByIdScenariosMacroScenarioIdGet>
  >,
  TError = HTTPValidationError,
>(
  scenarioId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getMacroScenarioByIdScenariosMacroScenarioIdGet>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetMacroScenarioByIdScenariosMacroScenarioIdGetQueryKey(scenarioId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMacroScenarioByIdScenariosMacroScenarioIdGet>>
  > = () =>
    getMacroScenarioByIdScenariosMacroScenarioIdGet(scenarioId, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!scenarioId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMacroScenarioByIdScenariosMacroScenarioIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMacroScenarioByIdScenariosMacroScenarioIdGetQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getMacroScenarioByIdScenariosMacroScenarioIdGet>>
  >;
export type GetMacroScenarioByIdScenariosMacroScenarioIdGetQueryError =
  HTTPValidationError;

/**
 * @summary Get Macro Scenario By Id
 */
export const useGetMacroScenarioByIdScenariosMacroScenarioIdGet = <
  TData = Awaited<
    ReturnType<typeof getMacroScenarioByIdScenariosMacroScenarioIdGet>
  >,
  TError = HTTPValidationError,
>(
  scenarioId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getMacroScenarioByIdScenariosMacroScenarioIdGet>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetMacroScenarioByIdScenariosMacroScenarioIdGetQueryOptions(
      scenarioId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update Macro Scenario
 */
export const updateMacroScenarioScenariosMacroScenarioIdPatch = (
  scenarioId: number,
  updateMacroScenarioViewModel: UpdateMacroScenarioViewModel,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<ViewMacroScenarioViewModel>(
    {
      url: `/scenarios/macro/${scenarioId}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: updateMacroScenarioViewModel,
    },
    options,
  );
};

export const getUpdateMacroScenarioScenariosMacroScenarioIdPatchMutationOptions =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof updateMacroScenarioScenariosMacroScenarioIdPatch>
      >,
      TError,
      { scenarioId: number; data: UpdateMacroScenarioViewModel },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof updateMacroScenarioScenariosMacroScenarioIdPatch>
    >,
    TError,
    { scenarioId: number; data: UpdateMacroScenarioViewModel },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof updateMacroScenarioScenariosMacroScenarioIdPatch>
      >,
      { scenarioId: number; data: UpdateMacroScenarioViewModel }
    > = (props) => {
      const { scenarioId, data } = props ?? {};

      return updateMacroScenarioScenariosMacroScenarioIdPatch(
        scenarioId,
        data,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type UpdateMacroScenarioScenariosMacroScenarioIdPatchMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof updateMacroScenarioScenariosMacroScenarioIdPatch>>
  >;
export type UpdateMacroScenarioScenariosMacroScenarioIdPatchMutationBody =
  UpdateMacroScenarioViewModel;
export type UpdateMacroScenarioScenariosMacroScenarioIdPatchMutationError =
  HTTPValidationError;

/**
 * @summary Update Macro Scenario
 */
export const useUpdateMacroScenarioScenariosMacroScenarioIdPatch = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof updateMacroScenarioScenariosMacroScenarioIdPatch>
    >,
    TError,
    { scenarioId: number; data: UpdateMacroScenarioViewModel },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateMacroScenarioScenariosMacroScenarioIdPatch>>,
  TError,
  { scenarioId: number; data: UpdateMacroScenarioViewModel },
  TContext
> => {
  const mutationOptions =
    getUpdateMacroScenarioScenariosMacroScenarioIdPatchMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Duplicate Macro Scenario
 */
export const duplicateMacroScenarioScenariosMacroScenarioIdDuplicatePost = (
  scenarioId: number,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<ViewMacroScenarioViewModel>(
    { url: `/scenarios/macro/${scenarioId}/duplicate`, method: "POST" },
    options,
  );
};

export const getDuplicateMacroScenarioScenariosMacroScenarioIdDuplicatePostMutationOptions =
  <TError = HTTPValidationError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof duplicateMacroScenarioScenariosMacroScenarioIdDuplicatePost
        >
      >,
      TError,
      { scenarioId: number },
      TContext
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof duplicateMacroScenarioScenariosMacroScenarioIdDuplicatePost
      >
    >,
    TError,
    { scenarioId: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof duplicateMacroScenarioScenariosMacroScenarioIdDuplicatePost
        >
      >,
      { scenarioId: number }
    > = (props) => {
      const { scenarioId } = props ?? {};

      return duplicateMacroScenarioScenariosMacroScenarioIdDuplicatePost(
        scenarioId,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DuplicateMacroScenarioScenariosMacroScenarioIdDuplicatePostMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof duplicateMacroScenarioScenariosMacroScenarioIdDuplicatePost
      >
    >
  >;

export type DuplicateMacroScenarioScenariosMacroScenarioIdDuplicatePostMutationError =
  HTTPValidationError;

/**
 * @summary Duplicate Macro Scenario
 */
export const useDuplicateMacroScenarioScenariosMacroScenarioIdDuplicatePost = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof duplicateMacroScenarioScenariosMacroScenarioIdDuplicatePost
      >
    >,
    TError,
    { scenarioId: number },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<
    ReturnType<
      typeof duplicateMacroScenarioScenariosMacroScenarioIdDuplicatePost
    >
  >,
  TError,
  { scenarioId: number },
  TContext
> => {
  const mutationOptions =
    getDuplicateMacroScenarioScenariosMacroScenarioIdDuplicatePostMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};

/**
 * @summary Get All Accordions
 */
export const getAllAccordionsAccordionsGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<AccordionsViewModel[]>(
    { url: `/accordions`, method: "GET" },
    options,
  );
};

export const getGetAllAccordionsAccordionsGetQueryKey = () => {
  return [`/accordions`] as const;
};

export const getGetAllAccordionsAccordionsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllAccordionsAccordionsGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAllAccordionsAccordionsGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAllAccordionsAccordionsGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAllAccordionsAccordionsGet>>
  > = () => getAllAccordionsAccordionsGet(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllAccordionsAccordionsGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllAccordionsAccordionsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllAccordionsAccordionsGet>>
>;
export type GetAllAccordionsAccordionsGetQueryError = unknown;

/**
 * @summary Get All Accordions
 */
export const useGetAllAccordionsAccordionsGet = <
  TData = Awaited<ReturnType<typeof getAllAccordionsAccordionsGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAllAccordionsAccordionsGet>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllAccordionsAccordionsGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Download Multiple Users Upload Template
 */
export const downloadMultipleUsersUploadTemplateFilesUserUploadTemplateGet = (
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  return withCustomAxios<unknown>(
    { url: `/files/user-upload-template`, method: "GET" },
    options,
  );
};

export const getDownloadMultipleUsersUploadTemplateFilesUserUploadTemplateGetQueryKey =
  () => {
    return [`/files/user-upload-template`] as const;
  };

export const getDownloadMultipleUsersUploadTemplateFilesUserUploadTemplateGetQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof downloadMultipleUsersUploadTemplateFilesUserUploadTemplateGet
      >
    >,
    TError = unknown,
  >(options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof downloadMultipleUsersUploadTemplateFilesUserUploadTemplateGet
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getDownloadMultipleUsersUploadTemplateFilesUserUploadTemplateGetQueryKey();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof downloadMultipleUsersUploadTemplateFilesUserUploadTemplateGet
        >
      >
    > = () =>
      downloadMultipleUsersUploadTemplateFilesUserUploadTemplateGet(
        requestOptions,
      );

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof downloadMultipleUsersUploadTemplateFilesUserUploadTemplateGet
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type DownloadMultipleUsersUploadTemplateFilesUserUploadTemplateGetQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof downloadMultipleUsersUploadTemplateFilesUserUploadTemplateGet
      >
    >
  >;
export type DownloadMultipleUsersUploadTemplateFilesUserUploadTemplateGetQueryError =
  unknown;

/**
 * @summary Download Multiple Users Upload Template
 */
export const useDownloadMultipleUsersUploadTemplateFilesUserUploadTemplateGet =
  <
    TData = Awaited<
      ReturnType<
        typeof downloadMultipleUsersUploadTemplateFilesUserUploadTemplateGet
      >
    >,
    TError = unknown,
  >(options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof downloadMultipleUsersUploadTemplateFilesUserUploadTemplateGet
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof withCustomAxios>;
  }): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getDownloadMultipleUsersUploadTemplateFilesUserUploadTemplateGetQueryOptions(
        options,
      );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
      queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
  };

/**
 * @summary Create Issue
 */
export const createIssueIssuesPost = (
  bodyCreateIssueIssuesPost: BodyCreateIssueIssuesPost,
  options?: SecondParameter<typeof withCustomAxios>,
) => {
  const formData = new FormData();
  formData.append("title", bodyCreateIssueIssuesPost.title);
  formData.append("description", bodyCreateIssueIssuesPost.description);
  formData.append("send_copy", bodyCreateIssueIssuesPost.send_copy.toString());
  bodyCreateIssueIssuesPost.attachments.forEach((value) =>
    formData.append("attachments", value),
  );

  return withCustomAxios<unknown>(
    {
      url: `/issues`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};

export const getCreateIssueIssuesPostMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createIssueIssuesPost>>,
    TError,
    { data: BodyCreateIssueIssuesPost },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createIssueIssuesPost>>,
  TError,
  { data: BodyCreateIssueIssuesPost },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createIssueIssuesPost>>,
    { data: BodyCreateIssueIssuesPost }
  > = (props) => {
    const { data } = props ?? {};

    return createIssueIssuesPost(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateIssueIssuesPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createIssueIssuesPost>>
>;
export type CreateIssueIssuesPostMutationBody = BodyCreateIssueIssuesPost;
export type CreateIssueIssuesPostMutationError = HTTPValidationError;

/**
 * @summary Create Issue
 */
export const useCreateIssueIssuesPost = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createIssueIssuesPost>>,
    TError,
    { data: BodyCreateIssueIssuesPost },
    TContext
  >;
  request?: SecondParameter<typeof withCustomAxios>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createIssueIssuesPost>>,
  TError,
  { data: BodyCreateIssueIssuesPost },
  TContext
> => {
  const mutationOptions = getCreateIssueIssuesPostMutationOptions(options);

  return useMutation(mutationOptions);
};
