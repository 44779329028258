import { Outlet } from "react-router-dom";
import { AppConstant } from "../../../constants";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Box from "@mui/material/Box";
import "./RootPage.scss";
import BannerAlert from "../../common/BannerAlert/BannerAlert";
import { useMetadata } from "../../../features/tool-configuration/components/BannerAlertConfigurationPage/MetadataContext";

export function RootPage() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { activeAlert, applicationVersion, masterDatasetVersion } =
    useMetadata();

  return (
    <Box className="root-page">
      <Header />
      <Box className="content">
        <Sidebar />
        {activeAlert ? <BannerAlert activeAlert={activeAlert} /> : null}
        <Box className="outlet">
          <Outlet />
        </Box>
        <Box>
          <Footer
            content={AppConstant.footerContent.content}
            applicationVersion={applicationVersion?.toString()}
            masterDatasetVersion={masterDatasetVersion}
          />
        </Box>
      </Box>
    </Box>
  );
}
