import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import { MacroTableWrapper } from "../../../../components/common";
import { Box } from "@mui/material";
import { RowData } from "./HelperService";

export type MekkoTableProps = {
  columns: MRT_ColumnDef<any>[];
  data: RowData[];
};

type RowDataWithCount = RowData & { count: number };

export const MekkoTable = ({ columns, data }: MekkoTableProps) => {
  function removeDuplicatesAndCount(data: RowData[]): RowDataWithCount[] {
    const countMap: RowDataWithCount[] = [];
    data.forEach((row) => {
      const index = countMap.findIndex((r) => r.base_input === row.base_input);
      if (index !== -1) {
        countMap[index].count += 1;
      } else {
        countMap.push({ ...row, count: 1 });
      }
    });
    return Object.values(countMap);
  }

  const createMergedCellData = (data: RowData[]) => {
    const newData: RowData[] = [];
    data.forEach((row) => {
      const duplicates = newData.filter((r) => r.base_input === row.base_input);
      if (duplicates.length > 0) {
        newData.push({ ...row, base_input: undefined });
      } else {
        newData.push(row);
      }
    });
    return newData;
  };

  const dataWithBaseInputCount: RowDataWithCount[] =
    removeDuplicatesAndCount(data);
  const mergedCellData = createMergedCellData(data);

  const table = useMaterialReactTable({
    columns: columns,
    data: mergedCellData,
    enableTopToolbar: false,
    enableSorting: false,
    enableColumnActions: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnDragging: false,
    muiTableBodyRowProps: { hover: false },
    muiTableHeadCellProps: ({ column }) => {
      return {
        align: "center",
        sx: {
          borderTop:
            column.getIndex() >= 2 ? "0.8px solid #cbcbcb !important" : "",
          borderLeft:
            column.getIndex() === 2
              ? "0.8px solid #cbcbcb !important"
              : "none !important",
          borderRight: "none !important",
        },
      };
    },
    muiTableBodyCellProps: ({ column, row }) => {
      const isLastRow: boolean = row.index === data.length - 1;
      const isNewPillar =
        row.index !== 0 &&
        data[row.index].base_input !== data[row.index - 1].base_input;
      const sx = {
        height: "55px",
        width: "300px",
        textAlign: "center",
        fontWeight: column.getIndex() === 0 ? "bold" : "initial",
        borderTop: isNewPillar ? "2.4px solid #cbcbcb !important" : "",
      };
      const cellData = row.original[column.id];
      if (cellData === undefined) {
        return {
          style: {
            display: "none",
          },
        };
      }
      if (isLastRow) {
        return {
          sx: {
            ...sx,
            fontWeight: "bold",
            borderLeft: "none !important",
            borderRight: "none !important",
            borderBottom: "none !important",
            borderTop: "0.8px solid #d04a02",
          },
        };
      }
      return {
        sx,
        rowSpan: (() => {
          if (column.id !== "base_input") return 1;
          const rowData = dataWithBaseInputCount.find(
            (r) => r.base_input === row.original.base_input,
          );
          return rowData ? rowData.count : 1;
        })(),
      };
    },
  });

  return (
    <Box className="displayHeader">
      <MacroTableWrapper>
        <MaterialReactTable table={table} />
      </MacroTableWrapper>
    </Box>
  );
};
