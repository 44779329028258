import i18next from "i18next";
import { ProductColumnData } from "../../types";
import { AppConstant, BaseInputConfigs } from "../../../../constants";
import { revertTextFormat } from "../../../../utils/formatText";
import {
  EmissionData,
  Product,
  Results,
  SubPillarEmission,
} from "../../../../orval/generated/models";
import { formatNumberOrEmpty } from "../../../../utils/formatNumber/formatNumber";
import { ProductConfigurationsMicro } from "../MicroAdjustmentsTable/types";

type SubRow = {
  metric: string;
  unit: string;
  [key: string]: string | number; // Dynamic product keys
};

type MainRow = {
  metric: string;
  subRows: SubRow[];
  unit: string;
  [key: string]: string | number | SubRow[]; // Dynamic product keys and subRows
};

function getResultsTableData(
  products: ProductColumnData[],
  items: Product[],
  unit: keyof Results,
  isAdjusted: Boolean,
  hideCDE: Boolean,
) {
  const data: MainRow[] = [];

  BaseInputConfigs.forEach((config: { key: string; label: string }) => {
    if (hideCDE && config.key === "cde") {
      return;
    }

    const dataObj: MainRow = {
      unit: unit,
      metric: i18next.t(config.label),
      subRows: [],
    };

    const revertedUnit = revertTextFormat(unit);
    const capitalizedUnit = capitalizeUnit(unit);

    products.forEach((product: ProductColumnData) => {
      const productData = isAdjusted
        ? items.find((item) => item.product_name === product.name)?.results
            ?.adjusted
        : items.find((item) => item.product_name === product.name)?.results
            ?.base;

      if (productData) {
        // ? if the key is total then the value is a number, else it is an object which has a total key and subRows
        const resultsOutputForUnit = productData[revertedUnit as keyof Results];

        const resultsOutputForUnitAndConfigKey =
          resultsOutputForUnit &&
          resultsOutputForUnit[config.key as keyof EmissionData];

        let dataValue;
        if (typeof resultsOutputForUnitAndConfigKey === "number") {
          dataValue = resultsOutputForUnitAndConfigKey;
        } else if (typeof resultsOutputForUnitAndConfigKey === "object") {
          dataValue = resultsOutputForUnitAndConfigKey.total;
        }

        if (
          config.key === "total" &&
          hideCDE &&
          dataValue &&
          resultsOutputForUnit
        ) {
          dataValue -= resultsOutputForUnit["cde"]["total"];
        }
        if (
          typeof dataValue === "number" &&
          Math.round(dataValue) !== dataValue
        ) {
          formatNumberOrEmpty(dataValue, ProductConfigurationsMicro.RESULTS);
        } else {
          dataValue = AppConstant.emptyCell;
        }

        dataObj[`Product ${product.guid}`] = dataValue;
      } else {
        dataObj[`Product ${product.guid}`] = AppConstant.emptyCell;
      }

      const resultsOutputForUnit =
        productData && productData[revertedUnit as keyof Results];

      const resultsOutputForUnitAndConfigKey =
        resultsOutputForUnit &&
        resultsOutputForUnit[config.key as keyof EmissionData];

      if (typeof resultsOutputForUnitAndConfigKey === "object") {
        resultsOutputForUnitAndConfigKey.sub_pillars?.forEach(
          (subPillar: SubPillarEmission) => {
            const subRow = dataObj.subRows?.find(
              (row: SubRow) => row.metric === subPillar.name,
            );

            if (subRow) {
              subRow[`Product ${product.guid}`] = formatNumberOrEmpty(
                subPillar.value,
                ProductConfigurationsMicro.RESULTS,
              );

              dataObj.subRows = dataObj.subRows.map((row: SubRow) => {
                if (row.metric === subPillar.name) {
                  return subRow;
                }
                return row;
              });
            } else {
              dataObj.subRows.push({
                metric: subPillar.name,
                unit: `g CO₂e / ${capitalizedUnit}`,
                [`Product ${product.guid}`]: formatNumberOrEmpty(
                  subPillar.value,
                  ProductConfigurationsMicro.RESULTS,
                ),
              });
            }
          },
        );
      }
    });
    dataObj["unit"] = `g CO₂e / ${capitalizedUnit}`;
    data.push(dataObj);
  });
  return data;
}

function capitalizeUnit(input: string): string {
  const words = input.split(" ");
  const remainingWords = words.slice(1); // create a new array excluding the first word
  const capitalizedWords = remainingWords.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1),
  );
  return capitalizedWords.join(" ");
}

export default getResultsTableData;
