import { Checkbox, Divider, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  optionName: string;
  selectedOptions: string[];
  translationKey: string;
  onChangeSelectOption: (item: string, checked: boolean) => void;
};

export function ExportOption({
  selectedOptions,
  translationKey,
  onChangeSelectOption,
  optionName,
}: Props) {
  const { t } = useTranslation(translationKey);

  return (
    <>
      <FormControlLabel
        labelPlacement="start"
        control={
          <Checkbox
            onChange={(_e, checked) =>
              onChangeSelectOption(optionName, checked)
            }
            checked={selectedOptions.some(
              (selectedItem) => selectedItem === optionName,
            )}
            name={t(`manageExport.${optionName}`)}
          />
        }
        label={t(`manageExport.${optionName}`)}
        sx={{
          justifyContent: "space-between",
          padding: "16px 16px 16px 0",
          margin: 0,
        }}
      />

      <Divider />
    </>
  );
}
