import { EnvironmentVariable } from "../../constants/EnvironmentVariables";
import { TokenTypes } from "./enums";
import * as SessionService from "./session-service";

const { localEnvConfig } = EnvironmentVariable;

export const getAccessToken = (): string | undefined => {
  let accessToken =
    SessionService.getSessionTokenForKey(TokenTypes.ACCESS_TOKEN) ||
    process.env[localEnvConfig.accessToken];
  if (!accessToken) {
    try {
      // IMPORTANT - getItem key must be a string value not imported for orval to parse the function correctly
      accessToken = sessionStorage.getItem("accessToken") || "";
    } catch (error) {
      console.log("FAILED to read sessionStorage " + error);
    }
  }
  return accessToken;
};

export const getBaseURL = (key: string): string => {
  const baseUrl = process.env[key];
  if (!baseUrl) {
    throw new Error("configured without base URL");
  }
  return baseUrl || "";
};
