import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import React, { useEffect, useMemo, useState } from "react";
import { useModal } from "../../../../../components/common/Modal";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  AccordionsViewModel,
  BulkMemberPermissionsModel,
  CountryViewModel,
  FunctionalAreaItemViewModel,
  PermissionItem,
} from "../../../../../orval/generated/models";
import SelectDropdown from "../../../../../components/common/SelectDropdown/SelectDropdown";
import Checkbox from "@mui/material/Checkbox";
import {
  AccessField,
  getAccessFields,
  getAdjustmentAccessItems,
  getFunctionalAccessItems,
  useAccessHandlers,
} from "../UserManagementHelperFunctions";
import { useQueryClient } from "@tanstack/react-query";
import { useGlobalLoader } from "../../../../../components/common";
import { useSnackbar } from "../../../../../components/common/Notification/showSnackbar";
import {
  getGetAllMembersMembersGetQueryKey,
  useEditBulkPermissionsMembersPermissionsPatch,
} from "orval/generated/endpoint";
import { OnChangeFn, RowSelectionState } from "@tanstack/table-core";
import { AppConstant } from "constants/AppConstant";

export type EditMultipleUsersModalProps = {
  selectedUsers: number[];
  countryData: CountryViewModel[];
  functionalAccessData: FunctionalAreaItemViewModel[];
  adjustmentAccessData: AccordionsViewModel[];
  flattenedFunctionalAccess: PermissionItem[];
  flattenedAdjustmentAccess: PermissionItem[];
  setRowSelection: OnChangeFn<RowSelectionState>;
};

const EditMultipleUsersModal = ({
  selectedUsers,
  countryData,
  functionalAccessData,
  adjustmentAccessData,
  flattenedFunctionalAccess,
  flattenedAdjustmentAccess,
  setRowSelection,
}: EditMultipleUsersModalProps) => {
  const { t } = useTranslation(["toolConfiguration", "common"]);
  const [formSubmissionDisabled, setFormSubmissionDisabled] = useState(false);
  const [confirmActionCheckboxDisabled, setConfirmActionCheckboxDisabled] =
    useState(true);
  const queryClient = useQueryClient();
  const showSnackbar = useSnackbar();
  const { closeModal } = useModal();
  const { showGlobalLoader } = useGlobalLoader();

  const functionalAccessItems = useMemo(
    () => getFunctionalAccessItems(functionalAccessData || []),
    [functionalAccessData],
  );

  const adjustmentAccessItems = useMemo(
    () => getAdjustmentAccessItems(adjustmentAccessData || []),
    [adjustmentAccessData],
  );

  const { mutateAsync: bulkEditUsers, isPending: bulkEditUserIsPending } =
    useEditBulkPermissionsMembersPermissionsPatch({
      mutation: {
        onSuccess: () => {
          showSnackbar(
            t(
              "toolConfiguration:userManagementPage.editMultipleUsers.successToast",
            ),
            "success",
          );
          queryClient.invalidateQueries({
            queryKey: getGetAllMembersMembersGetQueryKey(),
          });
        },
        onError: (error: any) => {
          showSnackbar(error, "error");
        },
      },
    });

  const handleFormSubmit = async (patchObject: BulkMemberPermissionsModel) => {
    try {
      await bulkEditUsers({ data: patchObject });
      setRowSelection({});
      closeModal();
    } catch (error: any) {
      console.warn("Error editing multiple users", error);
    } finally {
      showGlobalLoader(false);
    }
  };

  const formik = useFormik<{
    adjustment_access: number[];
    countries: string[];
    functional_access: number[];
    confirmAction: boolean;
  }>({
    initialValues: {
      adjustment_access: AppConstant.defaultAdjustmentAccess,
      countries: [],
      functional_access: [],
      confirmAction: false,
    },
    validationSchema: yup.object({
      adjustment_access: yup.array().of(yup.number()),
      countries: yup.array().of(yup.string()),
      functional_access: yup.array().of(yup.number()),
      confirmAction: yup
        .boolean()
        .oneOf([true], "Confirmation is required")
        .required(),
    }),
    onSubmit: (values) => {
      const patchObject = {
        member_ids: selectedUsers,
        permissions: {
          adjustment_access: values.adjustment_access,
          countries: values.countries,
          functional_access: values.functional_access,
        },
      };
      handleFormSubmit(patchObject);
    },
  });

  const {
    handleFunctionalAccessChange,
    handleCountryAccessChange,
    handleAdjustmentAccessChange,
  } = useAccessHandlers(
    formik,
    flattenedFunctionalAccess,
    countryData,
    flattenedAdjustmentAccess,
    adjustmentAccessData,
  );

  const accessFields = useMemo(
    () =>
      getAccessFields(
        formik,
        countryData,
        flattenedAdjustmentAccess,
        flattenedFunctionalAccess,
        adjustmentAccessItems,
        functionalAccessItems,
        handleCountryAccessChange,
        handleAdjustmentAccessChange,
        handleFunctionalAccessChange,
        t,
      ),
    [
      adjustmentAccessItems,
      countryData,
      flattenedAdjustmentAccess,
      flattenedFunctionalAccess,
      formik,
      functionalAccessItems,
      handleAdjustmentAccessChange,
      handleCountryAccessChange,
      handleFunctionalAccessChange,
      t,
    ],
  );

  useEffect(() => {
    setFormSubmissionDisabled(
      !formik.dirty ||
        !formik.isValid ||
        (!formik.values.confirmAction &&
          !formik.values.adjustment_access.length &&
          !formik.values.countries.length &&
          !formik.values.functional_access.length),
    );
    setConfirmActionCheckboxDisabled(
      // user does not need to change form,
      // because they can submit with Serving Size selected by default
      // to restore Adjustment Access back to default
      formik.values.adjustment_access.length < 1 &&
        !formik.values.countries.length &&
        !formik.values.functional_access.length,
    );
  }, [formik.values, formik.dirty, formik.isValid]);

  useEffect(() => {
    bulkEditUserIsPending ? showGlobalLoader(true) : showGlobalLoader(false);
  }, [bulkEditUserIsPending, showGlobalLoader]);

  return (
    <Box maxWidth={500}>
      <Box marginRight={1} marginTop={1} display="flex" justifyContent="right">
        <IconButton
          aria-label={t("common:actions.cancel")}
          sx={{ aspectRatio: 1, float: "right" }}
          onClick={closeModal}
        >
          <CloseSharpIcon />
        </IconButton>
      </Box>
      <Box paddingX={5} paddingBottom={4}>
        <Typography textAlign="center" variant="h4" marginBottom={3}>
          {t("toolConfiguration:userManagementPage.editMultipleUsers.title")}
        </Typography>
        <Typography textAlign="center" variant="body2" marginBottom={3}>
          {t(
            "toolConfiguration:userManagementPage.editMultipleUsers.description",
          )}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          {accessFields.map((field: AccessField) => (
            <React.Fragment key={field.title}>
              <FormControl
                required
                fullWidth
                sx={{
                  marginBottom: field.title === "Adjustment Access" ? -2 : 2,
                  height: "5em",
                }}
                key={field.title}
              >
                <SelectDropdown
                  listItems={field.listItems}
                  onSave={field.onSave}
                  savedSelectedItems={field.savedSelectedItems}
                  title={field.title}
                  requireSave={false}
                  showSearchBar
                  selectAll
                  formikLabel={field.formikLabel}
                  helperText={field.helperText}
                  onOpen={field.onOpen}
                  disabledItems={field.disabledItems}
                ></SelectDropdown>
              </FormControl>
              {field.title ===
                t("userManagementPage.upsertUser.adjustmentAccessLabel") && (
                <Box mb={3}>
                  <Typography variant={"body2"}>
                    {t("userManagementPage.servingSizeSelectedByDefault")}
                  </Typography>
                </Box>
              )}
            </React.Fragment>
          ))}
          <Box mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.confirmAction}
                  onChange={() =>
                    formik.setFieldValue(
                      "confirmAction",
                      !formik.values.confirmAction,
                    )
                  }
                  name="confirmAction"
                  color="primary"
                  disabled={confirmActionCheckboxDisabled}
                  data-testid="confirm-action-checkbox"
                />
              }
              label={t(
                "toolConfiguration:userManagementPage.editMultipleUsers.confirmActionLabel",
              )}
              componentsProps={{ typography: { variant: "body2" } }}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Button sx={{ mr: 2 }} variant="outlined" onClick={closeModal}>
              {t("common:actions.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={formSubmissionDisabled}
            >
              {t("common:actions.save")}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default EditMultipleUsersModal;
