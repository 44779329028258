import {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./GlobalLoader.scss";

type GlobalLoaderContextProps = {
  showGlobalLoader: (open: boolean, disableAutoHide?: boolean) => void;
};

const GlobalLoaderContext = createContext<GlobalLoaderContextProps | undefined>(
  undefined,
);

export function GlobalLoaderProvider(props: {
  children: React.ReactNode;
  disableAutoHide?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [disableAutoHide, setDisableAutoHide] = useState(
    props.disableAutoHide || false,
  );

  const showGlobalLoader = (open: boolean, disableAutoHide?: boolean) => {
    setOpen(open);
    if (disableAutoHide !== undefined) {
      setDisableAutoHide(disableAutoHide);
    }
  };

  useEffect(() => {
    let messageTimer: NodeJS.Timeout;
    let hideTimer: NodeJS.Timeout;

    if (open) {
      messageTimer = setTimeout(() => {
        setShowMessage(true);
      }, 5000); // 5 seconds

      if (!disableAutoHide) {
        hideTimer = setTimeout(() => {
          showGlobalLoader(false);
        }, 10000); // 10 seconds
      }
    } else {
      setShowMessage(false);
    }

    return () => {
      clearTimeout(messageTimer);
      clearTimeout(hideTimer);
    };
  }, [open, disableAutoHide]);

  return (
    <GlobalLoaderContext.Provider value={{ showGlobalLoader }}>
      <Backdrop
        sx={(theme) => ({ color: theme.palette.primary.main, zIndex: 1301 })}
        open={open}
      >
        <Box className={`globalLoaderClass ${showMessage && "show"}`}>
          <Box>
            <CircularProgress color="inherit" />
          </Box>
          <Box
            className={`message ${showMessage && "show"}`}
            data-testid={"global-loader-message"}
          >
            {showMessage && (
              <>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography className={"header-H5"} sx={{ mt: 2 }}>
                    Just a moment,
                  </Typography>
                </Box>
                <Box>
                  <Typography className={"header-H5"}>
                    we'll take you there soon.
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Backdrop>
      {props.children}
    </GlobalLoaderContext.Provider>
  );
}

export function useGlobalLoader() {
  const context = useContext(GlobalLoaderContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalLoader must be used within a GlobalLoaderProvider",
    );
  }
  return context;
}
