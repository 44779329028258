import { Chart } from "chart.js";
import { Options } from "chartjs-plugin-datalabels/types/options";
import formatLabel from "../../EmissionTimeSection/HelperFunctions/formatLabel";

export const StackAxisLabelsPlugin = {
  id: "stackLabels",
  afterDatasetsDraw(chart: Chart, args: Object, pluginOptions: Options) {
    const { ctx } = chart;
    ctx.fillStyle = "#6A6A6A";
    ctx.textAlign = "center";

    chart?.data?.datasets?.[0]?.data?.forEach((datapoint, index: number) => {
      const stackLabels: string[] = ["base", "glide", "scenario"];
      stackLabels.forEach((stackLabel) => {
        const stackLabelIndex = [...chart.data.datasets].findIndex(
          (x) => x.stack === stackLabel,
        );
        if (stackLabelIndex !== -1) {
          ctx.fillText(
            formatLabel(stackLabel),
            chart.getDatasetMeta(stackLabelIndex).data[index].x,
            chart.chartArea.bottom + 15,
          );
        }
      });
    });
  },
};
