import {
  MacroResultsByCategory,
  MacroResultsByPackType,
} from "../../../../../orval/generated/models";
import getSortedCategoryKeys from "./getSortedCategoryKeys";

function getTimeEvolutionLabels(
  result: MacroResultsByPackType | MacroResultsByCategory,
): string[] {
  if (!result) {
    return [];
  }

  const chartLabels = [...getSortedCategoryKeys(result)];

  return chartLabels;
}

export default getTimeEvolutionLabels;
