import { Box, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import "./BannerAlert.scss";
import { useTranslation } from "react-i18next";
import { CustomIcon, Icons } from "../CustomIcon";
import { AppConstant } from "../../../constants";
import { MetadataActiveAlert } from "../../../orval/generated/models";

export type BannerAlertProps = {
  activeAlert: MetadataActiveAlert;
  preview?: boolean;
  localStorageKey?: string;
};

function BannerAlert({
  activeAlert,
  preview,
  localStorageKey,
}: BannerAlertProps) {
  const [bannerVisible, setBannerVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { t } = useTranslation("common");

  useEffect(() => {
    const dismissedBannerMessage = localStorage.getItem(
      localStorageKey ?? AppConstant.localStorageKeys.dismissedBannerMessage,
    );
    const bannerNotDismissedOrAPreviewBanner =
      preview || dismissedBannerMessage !== activeAlert?.message;
    setBannerVisible(bannerNotDismissedOrAPreviewBanner);
  }, [activeAlert, localStorageKey, preview]);

  const handleDismissBanner = () => {
    setBannerVisible(false);
    localStorage.setItem(
      localStorageKey ?? AppConstant.localStorageKeys.dismissedBannerMessage,
      activeAlert?.message ?? "",
    );
  };

  const renderIcon = () => {
    switch (activeAlert?.type) {
      case "error":
      case "":
        return (
          <CustomIcon
            name={Icons.CIRCLE_DELETE}
            fill={"#612622"}
            width={"2em"}
            height={"2em"}
          />
        );
      case "information":
        return (
          <CustomIcon
            name={Icons.INFORMATION}
            fill={"#40576d"}
            width={"2em"}
            height={"2em"}
          />
        );
      case "success":
        return (
          <CustomIcon
            name={Icons.CONFIRMED}
            fill={"#20522a"}
            width={"2em"}
            height={"2em"}
          />
        );
      case "warning":
        return (
          <CustomIcon
            name={Icons.EXCLAMATION}
            fill={"#786124"}
            width={"2em"}
            height={"2em"}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const outletDiv = document.querySelector("div.outlet");
    const previewBannerTypography = document.querySelector(
      "p.preview-banner-header",
    );
    setAnchorEl(previewBannerTypography || outletDiv);
  }, []);

  return (
    <>
      {anchorEl && activeAlert ? (
        <Box
          className={`${bannerVisible ? "bannerVisible" : "bannerInvisible"} 
            ${activeAlert?.type ? activeAlert.type : "error"}
            ${preview ? "banner-preview" : "banner-general"}`}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          key={activeAlert?.id}
          aria-label={`Alert Banner, type: ${activeAlert?.type}`}
          data-testid="banner-alert-box"
        >
          <Box display="flex" alignItems="center">
            <Box sx={{ paddingRight: "0.5em" }}>{renderIcon()}</Box>
            <Box
              tabIndex={0}
              sx={{ wordWrap: "break-word", wordBreak: "break-word" }}
            >
              <Typography aria-live="polite">{activeAlert?.message}</Typography>
            </Box>
          </Box>
          <Box ml={2}>
            <Button
              variant="outlined"
              className={`banner-button-general ${activeAlert?.type}`}
              onClick={handleDismissBanner}
              disabled={preview}
              data-testid="banner-dismiss-btn"
            >
              {t("actions.dismiss")}
            </Button>
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default BannerAlert;
