import MicroCustomAccordion from "../MicroCustomAccordion/MicroCustomAccordion";
import {
  Product,
  SKUAdjustmentAccordionDetails,
} from "../../../../../orval/generated/models";
import { ProductColumnData } from "../../../types";
import RenderAccordions from "../RenderAccordions/RenderAccordions";
import { ProductConfigurationsMicro } from "../../MicroAdjustmentsTable/types";

export type AdjustmentAccordionProps = {
  productColumnData: ProductColumnData[] | undefined;
  products: Product[] | undefined;
  accordion: SKUAdjustmentAccordionDetails;
};

function MicroAdjustmentAccordion({
  products,
  productColumnData,
  accordion,
}: AdjustmentAccordionProps) {
  const accordionDetails =
    products &&
    RenderAccordions(
      accordion,
      productColumnData,
      products,
      accordion.accordion ===
        ProductConfigurationsMicro.OTHER_PACKAGING_EMISSION_FACTORS,
    );

  return (
    <MicroCustomAccordion
      title={accordion.accordion}
      hasSubAccordions={Boolean(
        accordion.sub_accordions && accordion.sub_accordions.length,
      )}
      details={accordionDetails}
    />
  );
}

export default MicroAdjustmentAccordion;
